<template>
  <el-container class="base-container">
    <el-header class="base-height">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>资产管理</el-breadcrumb-item>
        <el-breadcrumb-item>商品出入库明细</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-container class="main-container" ref="mainSector">
      <el-main>
        <el-row style="margin-bottom: 10px">
          <el-col :span="3" class="input-search">
            <el-date-picker
                v-model="search.recordMonth"
                value-format="YYYY-MM"
                type="month"
                style="width: 100%;"
                @change="loadInventoryBase()">
            </el-date-picker>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-cascader
                placeholder="请选择公司或部门"
                style="width: 100%;"
                v-model="search.organOrDep"
                :props="organprops"
                clearable
                :show-all-levels="false"
                @change="loadInventoryBase($event)"
                :options="OrganTreeData" >
            </el-cascader>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-select v-model="search.goodsType" placeholder="请选择商品分类" @change="loadInventoryBase()" clearable>
              <el-option
                  v-for="item in GoodsType"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-select v-model="search.specsType" placeholder="请选择规格名称" @change="loadInventoryBase()" clearable>
              <el-option
                  v-for="item in SpecsType"
                  :key="item.CODE"
                  :label="item.CODE_NAME"
                  :value="item.CODE">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-input v-model="search.goodsName" placeholder="请输入商品名称" @keyup.enter="loadInventoryBase" @clear="loadInventoryBase" clearable size="default"></el-input>
          </el-col>
          <el-col :span="3">
            <el-button
                type="primary"
                size="default"
                @click="loadInventoryBase">查询</el-button>
            <el-button
                size="default"
                @click="toReset">重置</el-button>
          </el-col>
          <el-col :span="6" style="text-align: right;">
            <el-tooltip effect="dark" content="刷新" placement="top">
              <el-link :underline="false" @click="toRefresh()">
                <el-icon :size="20"><Refresh /></el-icon>
              </el-link>
            </el-tooltip>
          </el-col>
        </el-row>

        <el-table
            :height="tableHeight"
            :data="InventoryBaseList"
            :cell-style="cellStyleMethod">
          <el-table-column
              prop="RECORD_TIME"
              label="日期"
              show-overflow-tooltip
              align="center"
              min-width="80px"
          >
          </el-table-column>
          <el-table-column
              prop="GOODS_TYPE_NAME"
              label="商品分类"
              align="center"
              show-overflow-tooltip
              min-width="80px"
          >
          </el-table-column>
          <el-table-column
              prop="SPECS_NAME"
              label="规格名称"
              align="center"
              show-overflow-tooltip
              min-width="80px"
          >
          </el-table-column>
          <el-table-column
              prop="GOODS_NAME"
              label="商品名称"
              show-overflow-tooltip
              align="center"
              min-width="80px"
          >
          </el-table-column>
          <el-table-column
              prop="UNIT_NAME"
              label="单位"
              show-overflow-tooltip
              align="center"
              min-width="80px"
          >
          </el-table-column>
          <el-table-column
              prop="INITIAL_DATA_NUM"
              label="期初数量"
              show-overflow-tooltip
              align="center"
              min-width="80px"
          >
          </el-table-column>
          <el-table-column
              prop="INITIAL_DATA_UNIT"
              label="期初单价"
              show-overflow-tooltip
              align="center"
              min-width="70px"
          >
          </el-table-column>
          <el-table-column
              prop="INITIAL_DATA_MONEY"
              label="期初金额"
              show-overflow-tooltip
              align="center"
              min-width="70px"
          >
          </el-table-column>
          <el-table-column label="本期入库" align="center">
            <el-table-column
                prop="IN_STORAGE_NUM"
                label="数量"
                show-overflow-tooltip
                align="center"
                min-width="70px"
            >
            </el-table-column>
            <el-table-column
                prop="IN_STORAGE_UNIT"
                label="单价"
                show-overflow-tooltip
                align="center"
                min-width="70px"
            >
            </el-table-column>
            <el-table-column
                prop="IN_STORAGE_MONEY"
                label="金额"
                show-overflow-tooltip
                align="center"
                min-width="70px"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column label="本期出库" align="center">
            <el-table-column
                prop="OUT_STORAGE_NUM"
                label="数量"
                show-overflow-tooltip
                align="center"
                min-width="70px"
            >
            </el-table-column>
            <el-table-column
                prop="OUT_STORAGE_UNIT"
                label="单价"
                show-overflow-tooltip
                align="center"
                min-width="70px"
            >
            </el-table-column>
            <el-table-column
                prop="OUT_STORAGE_MONEY"
                label="金额"
                show-overflow-tooltip
                align="center"
                min-width="70px"
            >
            </el-table-column>
          </el-table-column>
        </el-table>
        <el-pagination
            background
            v-model:current-page="search.page"
            v-model:page-size="search.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            @current-change="loadInventoryBase"
            @size-change="loadInventoryBase"
            :page-sizes="[20, 50, 100, 200]"
            :total="total"
            style="margin-top: 10px;"
        >
        </el-pagination>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: "AssetInventoryBase",
  data() {
    return{
      tableHeight: 0,
      total: 0,
      search:{
        recordMonth:"",
        goodsType:"",
        specsType:"",
        goodsName:"",
        organOrDep:"",
        s_organid:"",
        s_depid:"",
        page: 1,
        pagesize: 20,
      },
      OrganTreeData:[],
      organprops:{
        label:'text',value:'value',children:'children',
        checkStrictly: true,
        emitPath:false,
      },
      InventoryBaseList:[],
      SpecsType:[],
      subjectAllTree:[],
      GoodsType:[]
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.calcTableHeight()
      window.removeEventListener("resize", this.calcTableHeight)
      window.addEventListener("resize", this.calcTableHeight)
      this.search.recordMonth = this.current("month")
      this.search.organOrDep = "company-"+sessionStorage.getItem("organ-organid")
      this.loadTree()
      this.loadInventoryBase()
    })
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calcTableHeight)
  },
  methods: {
    calcTableHeight() {
      let mainHeight = window.getComputedStyle(this.$refs.mainSector.$el).height
      if (mainHeight.indexOf("px") !== -1)
        mainHeight = Number(mainHeight.substring(0, mainHeight.indexOf("px")))
      else
        mainHeight = Number(mainHeight)
      this.tableHeight = mainHeight - 120
    },
    current(d) {
      const nowDate = new Date();
      const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        day: nowDate.getDate()
      };
      if (d === "month")
        return date.year + "-" + (date.month >= 10 ? date.month : "0" + date.month);
      else if (d === "day")
        return date.year + "-" + (date.month >= 10 ? date.month : "0" + date.month) + "-" + (date.day >= 10 ? date.day : "0" + date.day);
    },
    loadTree(){
      this.$axios.post("/api/common/getcodelists", {codes: ["SPECS_TYPE"]})
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.SpecsType = data.result.SPECS_TYPE
            } else {
              this.$alert(response.data.result, "获取代码列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取代码列表出错")
          })
      this.$axios.get("/api/common/getsubjecttree")
          .then((response) => {
            if (response.data.success) {
              this.subjectAllTree = response.data.result
              var d = []
              this.subjectAllTree.forEach( item =>{
                if(item.label === "资产"){
                  item.children.forEach( i =>{
                    if(i.label === "存货") {
                      d = i.children
                    }
                  })
                }
              })
              this.GoodsType = d
            }
            else {
              this.$alert(response.data.result, "获取会计科目列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取会计科目列表出错")
          })
      this.$axios.get("/api/common/getqueryorgantree")
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.OrganTreeData = data.result
            } else {
              this.$alert(response.data.result, "获取组织列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取组织列表出错")
          })
    },
    loadInventoryBase(d) {
      if(this.search.organOrDep !== "" && this.search.organOrDep !== null){
        const parts = this.search.organOrDep.toString().split('-');
        if(parts[0] === "company"){
          this.search.s_organid = parts[1]
          this.search.s_depid = ""
        }else{
          this.search.s_organid = parts[0]
          this.search.s_depid = parts[1]
        }
      }else{
        this.search.s_organid = ""
        this.search.s_depid = ""
      }
      this.$axios.post("/api/asset/loadInventoryBase", this.search)
          .then((response) => {
            if (response.data.success) {
              this.total = response.data.result.totalrecords
              this.InventoryBaseList = response.data.result.datalist
              if (d === "refresh") {
                this.$message({message: "刷新成功", type: "success"})
              }
            } else {
              this.$alert(response.data.result, "获取商品出入库基数列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取商品出入库基数列表出错")
          })
    },
    toRefresh() {
      this.search.goodsName = ""
      this.loadInventoryBase("refresh")
    },
    toReset() {
      this.search.goodsName = ""
      this.search.organOrDep = "company-"+sessionStorage.getItem("organ-organid")
      this.search.s_organid = ""
      this.search.s_depid = ""
      this.loadInventoryBase()
    },
    cellStyleMethod({ columnIndex }) {
     if (columnIndex === 5 || columnIndex === 6 || columnIndex === 7) {
        return { 'background':'#ebf6f9' };
      }else if (columnIndex === 8 || columnIndex === 9 || columnIndex === 10) {
        return { 'background':'#f0f9eb' };
      }else if (columnIndex === 11 || columnIndex === 12 || columnIndex === 13) {
        return { 'background':'#f9f7eb' };
      }
    },
  }
}
</script>

<style scoped>

</style>
