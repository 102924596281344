<template>
  <el-container class="base-container">
    <el-header class="base-height">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>资产管理</el-breadcrumb-item>
        <el-breadcrumb-item>资产折旧管理</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-container class="main-container" ref="mainSector">
      <el-main>
        <el-row>
          <el-col :span="3" class="input-search">
            <el-button type="success" @click="toAdd()">
              <el-icon><Plus /></el-icon><span class="icon-right">新增</span>
            </el-button>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-date-picker
                v-model="search.recordMonth"
                type="month"
                placeholder="选择年月"
                style="width: 100%"
                value-format="YYYY-MM"
                format="YYYY年MM月"
                @change="loadAssetDepreciation()"
            />
          </el-col>
          <el-col :span="4" class="input-search">
            <el-cascader
                placeholder="请选择公司或部门"
                style="width: 100%;"
                v-model="search.organOrDep"
                :props="organprops"
                clearable
                :show-all-levels="false"
                @change="loadAssetDepreciation($event)"
                :options="OrganTreeData" >
            </el-cascader>
          </el-col>
          <el-col :span="4" class="input-search">
            <el-select v-model="search.usingDepartment" placeholder="请选择使用部门" @change="loadAssetDepreciation()" clearable>
              <el-option
                  v-for="item in usingDepartmentData"
                  :key="item.CODE"
                  :label="item.CODE_NAME"
                  :value="item.CODE">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-input v-model="search.assetName" placeholder="请输入资产名称" @keyup.enter="loadAssetDepreciation" @clear="loadAssetDepreciation" clearable size="default"></el-input>
          </el-col>
          <el-col :span="3">
            <el-button
                type="primary"
                size="default"
                @click="loadAssetDepreciation">查询</el-button>
            <el-button
                size="default"
                @click="toReset">重置</el-button>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-button type="success" @click="showProvision()">
              <el-icon><Finished /></el-icon><span class="icon-right">折旧计提</span>
            </el-button>
          </el-col>
          <el-col :span="1" style="text-align: right;">
            <el-tooltip effect="dark" content="刷新" placement="top">
              <el-link :underline="false" @click="toRefresh()">
                <el-icon :size="20"><Refresh /></el-icon>
              </el-link>
            </el-tooltip>
          </el-col>
        </el-row>

        <el-table
            :height="tableHeight"
            :data="assetDepreciationList">
          <el-table-column
              prop="ASSET_TYPE_NAME"
              label="资产类型"
              align="center"
              show-overflow-tooltip
              min-width="100px"
          >
          </el-table-column>
          <el-table-column
              prop="OBTAIN_YEAR_MONTH"
              label="取得年月"
              align="center"
              show-overflow-tooltip
              min-width="120px"
          >
          </el-table-column>
          <el-table-column
              prop="USE_DEPARTMENT_NAME"
              label="使用部门"
              show-overflow-tooltip
              align="center"
              min-width="100px"
          >
          </el-table-column>
          <el-table-column
              prop="ASSET_NAME"
              label="资产名称"
              show-overflow-tooltip
              align="center"
              min-width="100px"
          >
          </el-table-column>

          <el-table-column
              prop="UNIT_NAME"
              label="单位"
              show-overflow-tooltip
              align="center"
              min-width="80px"
          >
          </el-table-column>
          <el-table-column
              prop="NUMBER"
              label="数量"
              show-overflow-tooltip
              align="center"
              min-width="70px"
          >
          </el-table-column>
          <el-table-column
              prop="UNIT_PRICE"
              label="单价"
              show-overflow-tooltip
              align="center"
              min-width="100px"
          >
          </el-table-column>
          <el-table-column
              prop="PROPERTH_ORIGINAL"
              label="资产原值"
              show-overflow-tooltip
              align="center"
              min-width="100px"
          >
          </el-table-column>
          <el-table-column
              prop="LIFE"
              label="使用年限（年）或工作量"
              show-overflow-tooltip
              align="center"
              min-width="100px"
          >
          </el-table-column>
          <el-table-column
              prop="RESIDUAL_RATE"
              label="残值率(%)"
              show-overflow-tooltip
              align="center"
              min-width="100px"
          >
          </el-table-column>
          <el-table-column
              prop="PROPERTH_RESIDUAL"
              label="资产残值"
              show-overflow-tooltip
              align="center"
              min-width="100px"
          >
          </el-table-column>
          <el-table-column
              prop="DEPRECIATION_TYPE_NAME"
              label="折旧方式"
              show-overflow-tooltip
              align="center"
              min-width="100px"
          >
          </el-table-column>
          <el-table-column
              prop="PROVISION_TOTAL"
              label="累计计提折旧额"
              show-overflow-tooltip
              align="center"
              min-width="100px"
          >
          </el-table-column>
          <el-table-column
              prop="PROPERTH_BALANCE"
              label="资产账面余额"
              show-overflow-tooltip
              align="center"
              min-width="100px"
          >
          </el-table-column>
          <el-table-column
              prop="PROVISION_MONTH"
              label="当月折旧金额"
              show-overflow-tooltip
              align="center"
              min-width="100px"
          >
          </el-table-column>
          <el-table-column
              fixed="right"
              label="操作"
              min-width="150px"
              align="center">
            <template #default="scope">
              <el-button class="table-button"
                         size="small"
                         type="primary"
                         v-if='scope.row.ORGAN_ID.toString() === this.organ_organid.toString()'
                         @click="toEdit(scope.row)">编辑</el-button>
              <el-button class="table-button"
                         size="small"
                         type="danger"
                         v-if='scope.row.ORGAN_ID.toString() === this.organ_organid.toString()'
                         @click="toDel(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            background
            v-model:current-page="search.page"
            v-model:page-size="search.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            @current-change="loadAssetDepreciation"
            @size-change="loadAssetDepreciation"
            :page-sizes="[20, 50, 100, 200]"
            :total="total"
            style="margin-top: 10px;"
        >
        </el-pagination>

        <el-dialog
            width="1000px"
            :title="title"
            v-model="dailVisi"
            :close-on-click-modal="false"
            :close-on-press-escape="false">
          <div style="height: calc(100% - 100px); overflow: auto;">
            <el-form
                :inline="true"
                :model="assetDepreciationData"
                :rules="rules"
                ref="assetDepreciationForm"
                label-width="200px"
            >
              <el-form-item label="资产类型:" prop="subjectName" class="form-col">
                <el-select v-model="assetDepreciationData.assetType" placeholder="请选择资产类型" @change="getProperthResidual()">
                  <el-option
                      v-for="item in assetTypeData"
                      :key="item.CODE"
                      :label="item.CODE_NAME"
                      :value="item.CODE">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="取得年月:" prop="obtainYearMonth" class="form-col" >
                <el-date-picker
                    v-model="assetDepreciationData.obtainYearMonth"
                    type="date"
                    placeholder="选择取得年月"
                    style="width: 100%"
                    format="YYYY-MM"
                    value-format="YYYY-MM"
                    @change="getProperthResidual()"
                />
              </el-form-item>
              <el-form-item label="使用部门:" prop="useDepartment" class="form-col">
                <el-select v-model="assetDepreciationData.useDepartment" placeholder="请选择使用部门">
                  <el-option
                      v-for="item in usingDepartmentData"
                      :key="item.CODE"
                      :label="item.CODE_NAME"
                      :value="item.CODE">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="资产名称:" prop="assetName" class="form-col" >
                <el-input v-model="assetDepreciationData.assetName" />
              </el-form-item>
              <el-form-item label="单位:" prop="unit" class="form-col">
                <el-select v-model="assetDepreciationData.unit" placeholder="请选择单位">
                  <el-option
                      v-for="item in UnitData"
                      :key="item.CODE"
                      :label="item.CODE_NAME"
                      :value="item.CODE">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="数量:" prop="num" class="form-col" >
                <el-input v-model="assetDepreciationData.num" @change="getProperthResidual()"/>
              </el-form-item>
              <el-form-item label="单价:" prop="unitPrice" class="form-col" >
                <el-input v-model="assetDepreciationData.unitPrice"
                          :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                          :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')"
                          @change="getProperthResidual()"/>
              </el-form-item>
              <el-form-item label="资产原值:" prop="properthOriginal" class="form-col" >
                <el-input v-model="assetDepreciationData.properthOriginal"
                          :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                          :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')"
                          @change="getProperthResidual()"/>
              </el-form-item>
              <el-form-item label="使用年限（年）或工作量:" prop="life" class="form-col" >
                <el-input v-model="assetDepreciationData.life" @change="getProperthResidual()"/>
              </el-form-item>
              <el-form-item label="残值率:" prop="residualRate" class="form-col" >
                <el-input v-model="assetDepreciationData.residualRate" @change="getProperthResidual()">
                  <template #append>%</template>
                </el-input>
              </el-form-item>
              <el-form-item label="折旧方式:" prop="depreciationType" class="form-col" >
                <el-select v-model="assetDepreciationData.depreciationType" placeholder="请选择折旧方式" @change="getProperthResidual()">
                  <el-option
                      v-for="item in depreciationTypeData"
                      :key="item.CODE"
                      :label="item.CODE_NAME"
                      :value="item.CODE">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="年折旧率:" prop="yearDepreciationRate" class="form-col" >
                <el-input v-model="assetDepreciationData.yearDepreciationRate" disabled>
                  <template #append>%</template>
                </el-input>
              </el-form-item>
              <el-form-item label="资产残值:" prop="properthResidual" class="form-col" >
                <el-input v-model="assetDepreciationData.properthResidual" disabled/>
              </el-form-item>
              <el-form-item label="累计计提折旧额:" prop="provisionTotal" class="form-col" >
                <el-input v-model="assetDepreciationData.provisionTotal" disabled/>
              </el-form-item>
              <el-form-item label="资产账面余额:" prop="properthBalance" class="form-col" >
                <el-input v-model="assetDepreciationData.properthBalance" disabled/>
              </el-form-item>
              <el-form-item label="当月折旧金额:" prop="provisionMonth" class="form-col" >
                <el-input v-model="assetDepreciationData.provisionMonth" />
              </el-form-item>
              <el-form-item label="工作量:"  class="form-col" v-if="this.assetDepreciationData.depreciationType ==='2'">
                <el-input v-model="assetDepreciationData.gzl" @change="getProperthResidual()" />
              </el-form-item>
            </el-form>
            <div class="div-button">
              <el-button type="primary" @click="toSave" style="margin-right: 20px">保 存</el-button>
            </div>
          </div>
        </el-dialog>

        <el-dialog
            width="500px"
            title="请选择计提年月"
            v-model="dailVisiProvision"
            :close-on-click-modal="false"
            :close-on-press-escape="false">
          <el-date-picker
              v-model="ProvisionMonth"
              type="month"
              placeholder="选择年月"
              style="width: 100%"
              value-format="YYYY-MM"
              format="YYYY年MM月"

          />
          <div class="div-button">
            <el-button type="primary" @click="toProvision" style="margin-right: 20px">确认计提</el-button>
          </div>
        </el-dialog>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: "AssetDepreciationMge",
  data() {
    return {
      organ_organid: sessionStorage.getItem("organ-organid"),
      tableHeight: 0,
      search:{
        recordMonth:"",
        usingDepartment:"",
        assetName:"",
        organOrDep:"",
        s_organid:"",
        s_depid:"",
        page: 1,
        pagesize: 20,
      },
      OrganTreeData:[],
      organprops:{
        label:'text',value:'value',children:'children',
        checkStrictly: true,
        emitPath:false,
      },
      total: 0,
      assetDepreciationList:[],
      title:"",
      dailVisi: false,
      assetTypeData:[],
      usingDepartmentData:[],
      UnitData:[],
      depreciationTypeData:[],
      assetDepreciationData:{
        depreciationId:"",
        assetType:"",
        obtainYearMonth:"",
        useDepartment:"",
        assetName:"",
        unit:"",
        num:"",
        unitPrice:"",
        properthOriginal: "",
        life:"",
        residualRate:"",
        properthResidual:"",
        depreciationType:"",
        provisionTotal:"",
        properthBalance:"",
        provisionMonth:"",
        yearDepreciationRate:"",
        gzl:"",
      },
      getMonth: 0,
      dailVisiProvision:false,
      ProvisionMonth:"",
      rules:{
        assetType: [{required: true, message: "请选择资产类型", trigger: "blur"}],
        obtainYearMonth: [{required: true, message: "请选择取得年月", trigger: "blur"}],
        useDepartment: [{required: true, message: "请选择使用部门", trigger: "blur"}],
        assetName: [{required: true, message: "请填写资产名称", trigger: "blur"}],
        unit: [{required: true, message: "请选择单位", trigger: "blur"}],
        depreciationType: [{required: true, message: "请选择折旧方式", trigger: "blur"}],
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.calcTableHeight()
      window.removeEventListener("resize", this.calcTableHeight)
      window.addEventListener("resize", this.calcTableHeight)
      this.search.organOrDep = "company-"+sessionStorage.getItem("organ-organid")
      this.loadCode()
      this.loadAssetDepreciation()
    })
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calcTableHeight)
  },
  methods: {
    calcTableHeight() {
      let mainHeight = window.getComputedStyle(this.$refs.mainSector.$el).height
      if (mainHeight.indexOf("px") !== -1)
        mainHeight = Number(mainHeight.substring(0, mainHeight.indexOf("px")))
      else
        mainHeight = Number(mainHeight)
      this.tableHeight = mainHeight - 120
    },
    current(d) {
      var nowDate = new Date();
      var date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        day: nowDate.getDate()
      };
      if (d === "month")
        return date.year + "-" + (date.month >= 10 ? date.month : "0" + date.month);
      else if (d === "day")
        return date.year + "-" + (date.month >= 10 ? date.month : "0" + date.month) + "-" + (date.day >= 10 ? date.day : "0" + date.day);
    },
    loadCode() {
      this.$axios.post("/api/common/getcodelists", {codes: ["USING_DEPARTMNET", "ASSET_TYPE", "UNIT", "DEPRECIATION_TYPE"]})
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.usingDepartmentData = data.result.USING_DEPARTMNET
              this.assetTypeData = data.result.ASSET_TYPE
              this.UnitData = data.result.UNIT
              this.depreciationTypeData = data.result.DEPRECIATION_TYPE
            } else {
              this.$alert(response.data.result, "获取代码列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取代码列表出错")
          })
      this.$axios.get("/api/common/getqueryorgantree")
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.OrganTreeData = data.result
            } else {
              this.$alert(response.data.result, "获取组织列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取组织列表出错")
          })
    },
    loadAssetDepreciation(d) {
      if(this.search.organOrDep !== "" && this.search.organOrDep !== null){
        const parts = this.search.organOrDep.toString().split('-');
        if(parts[0] === "company"){
          this.search.s_organid = parts[1]
          this.search.s_depid = ""
        }else{
          this.search.s_organid = parts[0]
          this.search.s_depid = parts[1]
        }
      }else{
        this.search.s_organid = ""
        this.search.s_depid = ""
      }
      this.$axios.post("/api/asset/loadassetdepreciation", this.search)
          .then((response) => {
            if (response.data.success) {
              this.total = response.data.result.totalrecords
              this.assetDepreciationList = response.data.result.datalist
              if (d === "refresh") {
                this.$message({message: "刷新成功", type: "success"})
              }
            } else {
              this.$alert(response.data.result, "获取资产折旧列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取资产折旧列表出错")
          })
    },
    toRefresh() {
      this.loadAssetDepreciation("refresh")
    },
    toReset() {
      this.search.recordMonth = ""
      this.search.usingDepartment = ""
      this.search.assetName = ""
      this.loadAssetDepreciation()
    },
    toAdd() {
      this.title = "新增"
      this.dailVisi = true
      this.assetDepreciationData.depreciationId = ""
      this.assetDepreciationData.assetType = ""
      this.assetDepreciationData.obtainYearMonth = ""
      this.assetDepreciationData.useDepartment = ""
      this.assetDepreciationData.assetName = ""
      this.assetDepreciationData.unit = ""
      this.assetDepreciationData.num = ""
      this.assetDepreciationData.unitPrice = ""
      this.assetDepreciationData.properthOriginal = ""
      this.assetDepreciationData.life = ""
      this.assetDepreciationData.residualRate = ""
      this.assetDepreciationData.properthResidual = ""
      this.assetDepreciationData.depreciationType = ""
      this.assetDepreciationData.provisionTotal = ""
      this.assetDepreciationData.properthBalance = ""
      this.assetDepreciationData.provisionMonth = ""
      this.assetDepreciationData.yearDepreciationRate = ""
      this.assetDepreciationData.gzl =""
      if (this.$refs.assetDepreciationForm)
        this.$refs.assetDepreciationForm.clearValidate()
    },
    toSave() {
      this.$refs["assetDepreciationForm"].validate((valid) => {
        if (valid) {
          this.$axios.post("/api/asset/saveAssetDepreciationData", this.assetDepreciationData)
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "资产折旧信息录入成功", type: "success"})
                  this.dailVisi = false
                  this.loadAssetDepreciation()
                } else {
                  this.$alert(response.data.result, "资产折旧信息录入出错")
                }
              })
              .catch((error) => {
                this.$alert(error, "资产折旧信息录入出错")
              })
        }
        else
          return false
      })
    },
    toEdit(data) {
      this.title = "编辑"
      this.dailVisi = true
      this.assetDepreciationData.depreciationId = data.DEPRECIATION_ID
      this.assetDepreciationData.assetType = data.ASSET_TYPE
      this.assetDepreciationData.obtainYearMonth = data.OBTAIN_YEAR_MONTH
      this.assetDepreciationData.useDepartment = data.USE_DEPARTMENT
      this.assetDepreciationData.assetName = data.ASSET_NAME
      this.assetDepreciationData.unit = data.UNIT
      this.assetDepreciationData.num = data.NUMBER
      this.assetDepreciationData.unitPrice = data.UNIT_PRICE
      this.assetDepreciationData.properthOriginal = data.PROPERTH_ORIGINAL
      this.assetDepreciationData.life = data.LIFE
      this.assetDepreciationData.residualRate = data.RESIDUAL_RATE
      this.assetDepreciationData.properthResidual = data.PROPERTH_RESIDUAL
      this.assetDepreciationData.depreciationType = data.DEPRECIATION_TYPE
      this.assetDepreciationData.provisionTotal = data.PROVISION_TOTAL
      this.assetDepreciationData.properthBalance = data.PROPERTH_BALANCE
      this.assetDepreciationData.provisionMonth = data.PROVISION_MONTH
      this.assetDepreciationData.yearDepreciationRate = data.YEAR_DEPRECIATION_RATE
      if(this.assetDepreciationData.depreciationType === "2"){
        this.assetDepreciationData.gzl =data.GZL
      }
      if (this.$refs.assetDepreciationForm)
        this.$refs.assetDepreciationForm.clearValidate()
    },
    toDel(data){
      this.$confirm("确认删除此资产折旧信息吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$axios.post("/api/asset/delassetdepreciationdata", {depreciationId: data.DEPRECIATION_ID})
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "删除成功", type: "success"})
                this.loadAssetDepreciation()
              }
              else {
                this.$alert(response.data.result, "删除出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "删除出错")
            })
      })
    },

    getProperthResidual(){
      /**
       * properthResidual     资产残值
       * yearDepreciationRate 年折旧率
       * residualRate         残值率
       * life                 使用年限
       * provisionMonth       当月折旧金额
       * properthOriginal     资产原值
       * provisionTotal       累计计提折旧额
       * properthBalance      资产账面余额
       * **/
      if(this.assetDepreciationData.num !== "" && this.assetDepreciationData.unitPrice !== ""){
        this.assetDepreciationData.properthOriginal = Number(this.assetDepreciationData.num) * (Number(this.assetDepreciationData.unitPrice))
      }

      if(this.assetDepreciationData.properthOriginal !== "" && this.assetDepreciationData.residualRate !== ""){
        this.assetDepreciationData.properthResidual = Number(this.assetDepreciationData.properthOriginal) * (Number(this.assetDepreciationData.residualRate)/100)
      }

      if(this.assetDepreciationData.assetType !== "" && this.assetDepreciationData.obtainYearMonth !== ""){
        const start = new Date(this.assetDepreciationData.obtainYearMonth);
        const end = new Date(); // 当前日期
        const years = (end.getFullYear() - start.getFullYear()) * 12; // 计算年份差值
        const months = years + (end.getMonth() - start.getMonth()); // 加上月份差值
        if(this.assetDepreciationData.assetType ==="1"){ // 固定资产
          this.getMonth = months
        }else if(this.assetDepreciationData.assetType ==="2" || this.assetDepreciationData.assetType ==="3"){// 无形资产
          this.getMonth = months+1
        }
      }

      if(this.getMonth !== "" && this.assetDepreciationData.properthOriginal !== ""
          && this.assetDepreciationData.properthResidual !== ""
          && this.assetDepreciationData.life !== ""
          && this.assetDepreciationData.residualRate !== ""
          && this.assetDepreciationData.depreciationType !== ""){
        if(this.assetDepreciationData.depreciationType === "1" ){ //年限平均法
          this.assetDepreciationData.yearDepreciationRate = ((1 - (Number(this.assetDepreciationData.residualRate)/100)) / Number(this.assetDepreciationData.life)  * 100).toFixed(4)
          if(this.getMonth ===  0){
            this.assetDepreciationData.provisionMonth = 0
          }else{
            this.assetDepreciationData.provisionMonth = parseInt(Number(this.assetDepreciationData.properthOriginal) * (Number(this.assetDepreciationData.yearDepreciationRate) / 100) / 12)
          }
          this.assetDepreciationData.provisionTotal = Number(this.getMonth * this.assetDepreciationData.provisionMonth)
          this.assetDepreciationData.properthBalance = Number(this.assetDepreciationData.properthOriginal) - Number(this.assetDepreciationData.provisionTotal)
        }
        else if(this.assetDepreciationData.depreciationType === "2"){ //工作量法
          if(this.assetDepreciationData.gzl !== ""){
            this.assetDepreciationData.yearDepreciationRate = ((Number(this.assetDepreciationData.properthOriginal) - Number(this.assetDepreciationData.properthResidual)) / Number(this.assetDepreciationData.life)).toFixed(4)
            if(this.getMonth ===  0){
              this.assetDepreciationData.provisionMonth = 0
            }else{
              this.assetDepreciationData.provisionMonth = parseInt((Number(this.assetDepreciationData.yearDepreciationRate)) * Number(this.assetDepreciationData.gzl))
            }
            this.assetDepreciationData.provisionTotal = Number(this.getMonth * this.assetDepreciationData.provisionMonth)
            this.assetDepreciationData.properthBalance = Number(this.assetDepreciationData.properthOriginal) - Number(this.assetDepreciationData.provisionTotal)
          }
        }
        else if(this.assetDepreciationData.depreciationType === "3"){ //双倍余额递减法
          this.assetDepreciationData.yearDepreciationRate = (2 / Number(this.assetDepreciationData.life)).toFixed(6) *100
          let k = 0 //累计折旧额
          let n = 0
          for (let i = 1; i <= parseInt((this.getMonth +11) / 12); i++){
            k = k + n
            if(Number(this.assetDepreciationData.life) - i < 2){
              n = (Number(this.assetDepreciationData.properthOriginal) - k) / 2 / 12
            }else{
              n = (Number(this.assetDepreciationData.properthOriginal) - k) * 2 / Number(this.assetDepreciationData.life)
            }
          }
          if(this.getMonth ===  0){
            this.assetDepreciationData.provisionMonth = 0
          }else{
            if(Number(this.assetDepreciationData.life) - parseInt((this.getMonth +11) / 12) < 2){
              this.assetDepreciationData.provisionMonth = ((Number(this.assetDepreciationData.properthOriginal) - k - Number(this.assetDepreciationData.properthResidual)) / 2 /12).toFixed(0)
            }else{
              this.assetDepreciationData.provisionMonth = ((Number(this.assetDepreciationData.properthOriginal) - k) * (Number(this.assetDepreciationData.yearDepreciationRate)/100) /12).toFixed(0)
            }
          }
          this.assetDepreciationData.provisionTotal = parseInt(k)
          this.assetDepreciationData.properthBalance = Number(this.assetDepreciationData.properthOriginal) - Number(this.assetDepreciationData.provisionTotal)
        }
        else if(this.assetDepreciationData.depreciationType === "4"){ //年数总和法
          let useYear = Number(this.assetDepreciationData.life) - parseInt((this.getMonth-12) / 12)
          let yearTotal = 0
          for (let i = 0; i <= Number(this.assetDepreciationData.life); i++){
            yearTotal = yearTotal + i
          }
          this.assetDepreciationData.yearDepreciationRate = (useYear / yearTotal * 100).toFixed(4)
          if(this.getMonth ===  0){
            this.assetDepreciationData.provisionMonth = 0
          }else{
            this.assetDepreciationData.provisionMonth = ((Number(this.assetDepreciationData.properthOriginal) - Number(this.assetDepreciationData.properthResidual)) * (Number(this.assetDepreciationData.yearDepreciationRate) /100) /12).toFixed(0)
          }
          this.assetDepreciationData.provisionTotal = Number(this.getMonth * this.assetDepreciationData.provisionMonth)
          this.assetDepreciationData.properthBalance = Number(this.assetDepreciationData.properthOriginal) - Number(this.assetDepreciationData.provisionTotal)
        }
        else if(this.assetDepreciationData.depreciationType === "5"){ //资产摊销
          this.assetDepreciationData.yearDepreciationRate = ""
          if(this.getMonth ===  0){
            this.assetDepreciationData.provisionMonth = 0
          }else{
            this.assetDepreciationData.provisionMonth = (Number(this.assetDepreciationData.properthOriginal) / (Number(this.assetDepreciationData.life)*12)).toFixed(0)
          }
          this.assetDepreciationData.provisionTotal = ""
          this.assetDepreciationData.properthBalance = ""
        }
      }
    },

    showProvision(){
      this.dailVisiProvision =true
    },
    toProvision(){
      if(this.ProvisionMonth !== ""){
        this.$axios.post("/api/asset/depreciationProvision", {"provisionMonth":this.ProvisionMonth})
            .then((response) => {
              if (response.data.success) {
                this.dailVisiProvision =false
                this.$message({message: "折旧计提成功", type: "success"})
              }
              else {
                this.$alert(response.data.result, "折旧计提出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "折旧计提出错")
            })
      }
    },
  }
}
</script>

<style scoped>

</style>
