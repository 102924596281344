<template>
  <el-container class="base-container">
    <el-container class="main-container" ref="mainSector">
      <el-aside width="20%" style="padding: 20px 5px 0 20px;">
        <div style="display: flex;">
          <span>网格机构</span>
          <span style="margin-left: auto; margin-right: 10px">
            <el-tooltip effect="dark" content="展开/收起" placement="top">
              <el-link :underline="false" @click="toggleRowExpansion()">
                <el-icon :size="20">
                  <Sort />
                </el-icon>
              </el-link>
            </el-tooltip>
          </span>
        </div>
        <el-divider style="margin: 15px 0;" />
        <div style="height: calc(100% - 65px); overflow: auto;">
          <el-tree :data="treeData" :props="treeProps" node-key="value" :default-expand-all="expanded" :expand-on-click-node="false" ref="organTree">
            <template #default="{ node, data }">
              <span @click="toShowOrgan(data)">
                <i class="iconfont icon-zuzhiguanli" style="font-size: 18px;" v-if="data.type === 'company'"></i>
                <i class="iconfont icon-tree-department-1" style="font-size: 18px;" v-else></i>
                {{ node.label }}
              </span>
            </template>
          </el-tree>
        </div>
      </el-aside>
      <el-main style="height: 100%; border-left: 10px solid #f5f5f5; overflow:hidden;">
        <div style="display: flex;">
          <el-button type="success" @click="toAdd()">
            <el-icon>
              <Plus />
            </el-icon><span class="icon-right">新建楼层</span>
          </el-button>
          <div style="margin-left: auto;">
            <el-tooltip effect="dark" content="刷新" placement="top">
              <el-link :underline="false" @click="toRefresh()">
                <el-icon :size="20">
                  <Refresh />
                </el-icon>
              </el-link>
            </el-tooltip>
          </div>
        </div>
        <el-divider style="margin: 6px 0;" />
        <div style="height: calc(100% - 5px); overflow: auto">
          <el-table :height="tableHeight" :data="roleData" style="width: 100%" v-loading="loading">
            <template v-slot:empty>
              <el-empty description="暂无数据" :image-size="120"></el-empty>
            </template>
            <el-table-column label="序号" type="index" width="60px" align="center" />
            <el-table-column prop="FLOOR_NAME" label="楼层" show-overflow-tooltip align="center" />
            <el-table-column prop="ORGAN_DEP" label="所属网格" show-overflow-tooltip align="center" />
            <el-table-column prop="CREATE_TIME" label="创建时间" min-width="80px" show-overflow-tooltip align="center" />
            <!-- <el-table-column label="状态" fixed="right" width="80px" align="center">
              <template #default="scope">
                <el-switch v-model="scope.row.STATE" active-value="1" inactive-value="0" active-color="#13ce66" inactive-color="#ff4949" inline-prompt active-text="启" inactive-text="禁"
                           @change="switchState(scope.row)">
                </el-switch>
              </template>
            </el-table-column> -->
            <el-table-column fixed="right" label="操作" align="center" width="180px">
              <template #default="scope">
                <el-button class="table-button" size="small" type="primary" @click="toEdit(scope.row)">编辑</el-button>
                <el-button class="table-button" size="small" type="danger" @click="toDel(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination background v-model:current-page="search.page" v-model:page-size="search.pagesize" layout="total, prev, pager, next, jumper" @current-change="loadRole" :total="total"
                         style="margin-top: 10px;">
          </el-pagination>
        </div>
      </el-main>
      <el-dialog width="700px" :title="roleTitle" v-model="dailVisi" :close-on-click-modal="false" :close-on-press-escape="false">
        <div style="height: calc(100% - 100px); overflow: auto;">
          <el-form :inline="true" :model="roleForm" :rules="rules" ref="roleForm" label-width="130px">
            <el-form-item label="所属网格" prop="role_dep" class="form-row">
              <el-cascader style="width: 100%;" v-model="roleForm.organ_dep" :props="props" clearable @change="toRoleDep($event)" :options="treeData">
              </el-cascader>
            </el-form-item>
            <el-form-item label="楼层名称:" prop="username" class="form-row">
              <el-input v-model="roleForm.rolename" />
            </el-form-item>
          </el-form>
          <div class="div-button">
            <el-button type="primary" @click="toSaveRole">保 存</el-button>
          </div>
        </div>
      </el-dialog>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: "RoleMge",
  data () {
    return {
      expanded: true,
      treeData: [],
      treeProps: {
        label: 'text', value: 'value', children: 'children',
      },
      tableHeight: 0,
      roleData: [],
      total: 0,
      search: {
        organid: "",
        unitid: "",
        building: "",
        department: "",
        organid: "",
        depid: "",
        page: 1,
        pagesize: 17,
      },
      loading: false,
      roleTitle: "",
      dailVisi: false,
      voucher: true,
      expense: true,
      ask_leave: true,
      roleForm: {
        roleid: "",
        organ_dep: "",
        organid: "",
        depid: "",
        rolename: "",
      },
      props: {
        label: 'text', value: 'value', children: 'children',
        emitPath: false,
      },
      rules: {
        organ_dep: [{ required: true, message: "请选择所属组织", trigger: "change" }],
        rolename: [{ required: true, message: "请填写角色名称", trigger: "blur" }],
      },
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.calcTableHeight()
      window.removeEventListener("resize", this.calcTableHeight)
      window.addEventListener("resize", this.calcTableHeight)
      this.loadData()
      this.loadRole()
    })
  },
  beforeUnmount () {
    window.removeEventListener("resize", this.calcTableHeight)
  },
  methods: {
    calcTableHeight () {
      let mainHeight = window.getComputedStyle(this.$refs.mainSector.$el).height
      if (mainHeight.indexOf("px") !== -1)
        mainHeight = Number(mainHeight.substring(0, mainHeight.indexOf("px")))
      else
        mainHeight = Number(mainHeight)
      this.tableHeight = mainHeight - 120
    },
    toRefresh () {
      this.search.organid = ""
      this.search.depid = ""
      this.loadRole("refresh")
    },
    loadData () {
      this.$axios.get("/api/permission/getorgantree1")
        .then((response) => {
          const data = response.data
          if (data.success) {
            this.treeData = data.result
            this.treeData.forEach(item => {
              if (item.type === "company" && item.children.length === 0) {
                item["disabled"] = true
              } else {
                item.children.forEach(i => {
                  if (i.type === "company" && i.children.length === 0) {
                    i["disabled"] = true
                  }
                })
              }
            })
          } else {
            // this.$alert(response.data.result, "获取组织列表出错")
          }
        })
    },
    /** 展开收缩 */
    toggleRowExpansion () {
      const nodes = this.$refs.organTree.store._getAllNodes();
      nodes.forEach(item => {
        item.expanded = !item.expanded
      })
    },
    // 点击树
    toShowOrgan (node) {
      console.log(node);
      if (node.type === "unit") {
        this.search.unitid = node.UNIT_ID
        this.search.building = ""
        this.search.organid = ""
        this.search.department = ""
      }
      if (node.type === "buliding") {
        this.search.building = node.BUILDING_ID
        this.search.unitid = ""
        this.search.organid = ""
        this.search.department = ""
      }
      if (node.type === "company") {
        this.search.organid = node.ORGAN_ID
        this.search.building = ""
        this.search.unitid = ""
        this.search.department = ""
      } 
      if (node.type === "department") {
        this.search.department = node.DEPARTMENT_ID
        this.search.building = ""
        this.search.unitid = ""
        this.search.organid = ""
      }
      this.loadRole()
    },
    // 查询楼层
    loadRole (d) {
      this.loading = true
      this.$axios.post("/api/role/loadroleinfo", this.search)
        .then((response) => {
          if (response.data.success) {
            this.loading = false
            this.total = response.data.result.totalrecords
            this.roleData = response.data.result.datalist
            if (d === "refresh") {
              this.$message({ message: "刷新成功", type: "success" })
            }
          }
          else {
            this.$alert(response.data.result, "获取楼层列表出错")
          }
        })
        .catch((error) => {
          this.$alert(error, "获取楼层列表出错")
        })
    },
    toRoleDep (data) {
      const parts = data.split('-');
      this.roleForm.organid = parts[0]
      this.roleForm.depid = parts[1]
    },
    toVoucher (data) {
      if (data === "1") {
        this.voucher = false
      } else {
        this.voucher = true
      }
    },
    toExpense (data) {
      if (data === "1") {
        this.expense = false
      } else {
        this.expense = true
      }
    },
    toAskLeave (data) {
      if (data === "1") {
        this.ask_leave = false
      } else {
        this.ask_leave = true
      }
    },
    toAdd () {
      this.voucher = true
      this.expense = true
      this.ask_leave = true
      this.roleTitle = "新建"
      this.dailVisi = true
      this.roleForm.roleid = ""
      this.roleForm.organ_dep = ""
      this.roleForm.organid = ""
      this.roleForm.depid = ""
      this.roleForm.rolename = ""
      if (this.$refs.roleForm)
        this.$refs.roleForm.clearValidate()
    },
    toEdit (data) {
      this.toAdd()
      console.log(data);
      this.roleForm.roleid = data.FLOOR_ID
      this.roleForm.organ_dep = data.UNIT_ID
      this.roleForm.organid = data.UNIT_ID
      this.roleForm.depid = data.DEPARTMENT_ID
      this.roleForm.rolename = data.FLOOR_NAME
      this.roleTitle = "编辑"
      this.dailVisi = true
      if (this.$refs.roleForm)
        this.$refs.roleForm.clearValidate()
    },
    toSaveRole () {
      this.$refs["roleForm"].validate((valid) => {
        if (valid) {
          this.$axios.post("/api/role/saveroledata", this.roleForm)
            .then((response) => {
              if (response.data.success) {
                this.$message({ message: "楼层保存成功", type: "success" })
                this.dailVisi = false
                this.loadRole()
              } else {
                this.$alert(response.data.result, "楼层保存出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "楼层保存出错")
            })
        }
        else
          return false
      })
    },
    //删除
    toDel (data) {
      this.$confirm("确认删除此楼层?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$axios.post("/api/role/delrole", { roleid: data.FLOOR_ID })
          .then((response) => {
            if (response.data.success) {
              this.$message({ message: "楼层删除成功", type: "success" })
              this.loadRole()
            }
            else {
              this.$alert(response.data.result, "楼层删除出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "楼层删除出错")
          })
      })
    },
  }
}
</script>

<style scoped>
</style>
