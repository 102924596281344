<template>
  <el-container class="base-container">
    <el-header class="base-height">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>用户角色授权管理</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-container class="main-container">
      <el-aside width="30%" style="padding: 20px;">
        <div style="display: flex;">
          <span>组织机构</span>
          <span style="margin-left: auto; margin-right: 10px">
            <el-tooltip effect="dark" content="展开/收起" placement="top" >
              <el-link :underline="false" @click="toggleRowExpansion()">
                <el-icon :size="20"><Sort /></el-icon>
              </el-link>
            </el-tooltip>
          </span>
        </div>
        <el-divider style="margin: 15px 0;"/>
        <div style="height: calc(100% - 65px); overflow: auto;">
          <el-tree
              :data="roleTreeData"
              :props="props"
              node-key="value"
              :default-expand-all="expanded"
              :expand-on-click-node="false"
              ref="organTree">
            <template #default="{ node, data }">
                <span @click="toShowOrgan(data)" :class="roleClass(data)">
                  <i class="iconfont icon-zuzhiguanli" style="font-size: 18px;" v-if="data.type === 'company'"></i>
                  <i class="iconfont icon-tree-department-1" style="font-size: 18px;" v-else-if="data.type === 'department'"></i>
                  <i class="iconfont icon-jiaoseguanli2" style="font-size: 18px;" v-else></i>
                  {{ node.label }}
                </span>
            </template >
          </el-tree>
        </div>
      </el-aside>
      <el-main style="height: 100%; border-left: 10px solid #f5f5f5; overflow:hidden;">
        <div style="display: flex;">
          <span>权限列表</span>
          <span style="margin-left: 50px;">{{ formTitle }}</span>
        </div>
        <el-divider style="margin: 15px 0;"/>
        <el-row style="margin-bottom: 10px;">
          <el-input v-model="filterName" placeholder="请输入权限名称" prefix-icon="Search" clearable size="default" style="width: 60%;"></el-input>
        </el-row>
        <div style="height: calc(100% - 130px);overflow: auto;">
          <el-tree
              :data="treeData"
              :props="treeProps"
              node-key="PERMISSION_ID"
              :default-checked-keys="checkedKeys"
              :filter-node-method="filterNode"
              default-expand-all
              show-checkbox
              ref="permissionTree">
          </el-tree>
        </div>
        <div class="div-button">
          <el-button type="primary" @click="toSave" :disabled="unedit">保 存</el-button>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: "UserRolePermission",
  watch: {
    filterName (val) {
      this.$refs.permissionTree.filter(val)
    }
  },
  data () {
    return {
      unedit: true,
      formTitle: "",
      treeData: [],
      checkedKeys: [],
      treeProps: {
        children: 'subpermission',
        label: 'PERMISSION_NAME'
      },
      roleTreeData: [],
      props:{
        label:'text',value:'value',children:'children',
        emitPath:false,
      },
      expanded:true,
      assignData: {
        userrole: "",
        permissionids: null
      },
      filterName: "",
    }
  },
  mounted() {
    this.loadData()
    this.loadOrganData()
  },
  methods: {
    //查找树节点
    filterNode(value, data) {
      if (!value) return true;
      return data.PERMISSION_NAME.indexOf(value) !== -1;
    },

    loadData () {
      this.$axios.get("/api/permission/loadallpermission")
          .then((response) => {
            if (response.data.success) {
              this.treeData = response.data.result
              this.treeData.forEach(t =>{
                if(t.PERMISSION_NAME === "数据管理"){
                  t.subpermission.forEach(s =>{
                    if(s.PERMISSION_NAME === "字典管理" && sessionStorage.getItem('organ-role').toString() !== "0"){
                      s.disabled = true
                    }
                  })
                }
              })
            }
            else {
              this.$alert(response.data.result, "获取权限列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取权限列表出错")
          })
    },
    loadOrganData () {
      this.$axios.get("/api/permission/getorgantree" )
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.roleTreeData = data.result
            } else {
              this.$alert(response.data.result, "获取组织列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取组织列表出错")
          })
    },

    /** 展开收缩 */
    toggleRowExpansion(){
      const nodes = this.$refs.organTree.store._getAllNodes();
      nodes.forEach(item=>{
        item.expanded = !item.expanded
      })
    },

    roleClass: function (t) {
      return {user_role: t.type === "role", user_role_actived: this.assignData.userrole === t.USER_ROLE}
    },

    loadUserRolePermission (userrole) {
      this.$axios.post("/api/permission/loaduserrolepermission",{userrole: userrole})
          .then((response) => {
            if (response.data.success) {
              if(response.data.result != null && response.data.result.PERMISSION_ID !== "null"){
                this.checkedKeys = JSON.parse(response.data.result.PERMISSION_ID)
              }else{
                this.checkedKeys = []
              }
              this.$refs.permissionTree.setCheckedKeys(this.checkedKeys)
            }
            else {
              this.$alert(response.data.result, "获取用户角色权限出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取用户角色权限出错")
          })

    },
    toShowOrgan (node) {
      if(node.type === "role"){
        this.formTitle = "角色：" + node.name
        this.loadUserRolePermission(node.USER_ROLE)
        this.assignData.userrole = node.USER_ROLE
        this.unedit = false
      }
    },

    toSave () {
      this.assignData.permissionids = this.$refs.permissionTree.getCheckedKeys()
      if (this.assignData.permissionids.length === 0)
        this.assignData.permissionids = null
      this.$axios.post("/api/permission/saveuserrolepermission", this.assignData)
          .then((response) => {
            if (response.data.success) {
              this.$message({message: "授权保存成功", type: "success"})
            }
            else {
              this.$alert(response.data.result, "授权保存出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "授权保存出错")
          })
    },
  }
}
</script>

<style scoped>
.user_role {
  cursor: pointer;
  margin: 5px;
  padding: 5px;
}
.user_role:hover {
  background-color: #1890ff;
  color: #ffffff;
}

.user_role_actived {
  color: #1890ff;
}
.user_role.user_role_actived:hover {
  background-color: #1890ff;
  color: #ffffff;
}
</style>
