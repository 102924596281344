<!--
用户管理
最后编辑人：张鹏
最后编辑时间：2023-1-6
最后编辑细节：增加重置按钮
-->
<template>
  <el-container class="base-container">
    <el-container class="main-container" ref="mainSector">
      <el-aside width="20%" style="padding: 20px 5px 0 20px;">
        <div style="display: flex;">
          <span>网格机构</span>
          <span style="margin-left: auto; margin-right: 10px">
            <el-tooltip effect="dark" content="展开/收起" placement="top">
              <el-link :underline="false" @click="toggleRowExpansion()">
                <el-icon :size="20">
                  <Sort />
                </el-icon>
              </el-link>
            </el-tooltip>
          </span>
        </div>
        <el-divider style="margin: 15px 0;" />
        <div style="height: calc(100% - 65px); overflow: auto;">
          <el-tree :data="treeData" :props="treeProps" node-key="value" :default-expand-all="expanded" :expand-on-click-node="false" ref="organTree" v-loading="treelding">
            <template #default="{ node, data }">
              <!-- <span @click="toShowUser(data)" v-if="data.type === 'floor'">
                <i class="iconfont icon-tree-department-1" style="font-size: 18px;"></i>
                {{ node.label +`(${data.leng}人)` }}
              </span> -->
              <span @click="toShowUser(data)">
                <i class="iconfont icon-tree-department-1" style="font-size: 18px;"></i>
                {{ node.label }}
              </span>
            </template>
          </el-tree>
        </div>
      </el-aside>
      <el-main style="height: 100%; border-left: 10px solid #f5f5f5; overflow:hidden;">
        <el-row>
          <el-col :span="3">
            <el-button type="success" @click="toAddUser()">
              <el-icon>
                <Plus />
              </el-icon><span class="icon-right">新建住户</span>
            </el-button>
          </el-col>
          <el-col :span="5" class="input-search">
            <el-input v-model="search.property_name" placeholder="请输入户主姓名" @keyup.enter="loadUser" @clear="loadUser" clearable size="default"></el-input>
          </el-col>
          <el-col :span="3">
            <el-button type="primary" size="default" @click="loadUser">查询</el-button>
            <el-button size="default" @click="toReset">重置</el-button>
          </el-col>
          <el-col :span="12" style="text-align: right;">
            <el-tooltip effect="dark" content="刷新" placement="top">
              <el-link :underline="false" @click="toRefresh()">
                <el-icon :size="20">
                  <Refresh />
                </el-icon>
              </el-link>
            </el-tooltip>
          </el-col>
        </el-row>
        <el-divider style="margin: 6px 0;" />
        <div style="padding:5px;">
          <el-table :height="tableHeight" :data="userListData" style="width: 100%" v-loading="loading">
            <template v-slot:empty>
              <el-empty description="暂无数据" :image-size="120"></el-empty>
            </template>
            <el-table-column prop="property_name" label="姓名" show-overflow-tooltip align="center">
            </el-table-column>
            <el-table-column prop="register" label="登记日期" show-overflow-tooltip align="center">
            </el-table-column>
            <el-table-column prop="FLOOR_NAME" label="所属楼层" show-overflow-tooltip align="center" width="400px">
            </el-table-column>
            <el-table-column fixed="right" label="操作" min-width="300px" align="center">
              <template #default="scope">
                <el-button class="table-button" size="small" type="primary" @click="toEdit(scope.row)">编辑</el-button>
                <el-button class="table-button" size="small" type="danger" @click="toDel(scope.row)">删除</el-button>
                <el-button class="table-button" size="small" type="success" @click="toView(scope.row)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination background v-model:current-page="search.page" v-model:page-size="search.pagesize" layout="total, prev, pager, next, jumper" @current-change="loadUser" :total="total"
                         style="margin-top: 10px;">
          </el-pagination>
        </div>

        <el-dialog width="1500px" :title="UserTitle" v-model="dailVisi" :close-on-click-modal="false" :close-on-press-escape="false">
          <div style="height: calc(100% - 100px); overflow: auto;">
            <MmunitInfo :personnelData="personnelData" :treeData="treeData"></MmunitInfo>
            <div class="div-button">
              <el-button type="primary" @click="toSave" v-if="personnelData.mark !== '3'">保 存</el-button>
            </div>
          </div>
        </el-dialog>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import MmunitInfo from "@/components/mmunitlnfo/mmunitlnfo.vue"
export default {
  name: "UserMge",
  components: {
    MmunitInfo
  },
  data () {
    return {
      expanded: false,
      treeData: [],
      treeProps: {
        label: 'text', value: 'value', children: 'children',
      },
      search: {
        page: 1,
        pagesize: 17,
        unitid:"",
        building:"",
        organid:"",
        department:"",
        floorid:"",
        property_name:"",
        disability_number:null
      },
      loading: false,
      treelding:true,
      tableHeight: 0,
      userListData: [],
      total: 0,
      UserTitle: "",
      dailVisi: false,
      props: {
        label: 'text', value: 'value', children: 'children',
        emitPath: false,
      },
      personnelData: {
        FLOOR_ID: "",
        property_name: "",
        mark:"1",
        propertyid:"",
        register: "",
        property: [],
        specialName: "",
        opinion: "",
        familyType: [],
        remarks: "",
        communityInfo: [{
          personnel_name: "",
          mark:"1",
          sex: "",
          nation: "",
          card: "",
          landscape: "",
          residence: "",
          health: "",
          culture: "",
          religious: "",
          phone: "",
          working: "",
          marriage: "",
          marriageTime: "",
          childTime: "",
          childNum: "",
          employment: "",
          workers: "",
          society: "",
          resident: "",
          residentInsurance: "",
          advanced: "",
          disability_level: "",
          disability_type: "",
          disability_number: "",
          enlistment: "",
          retirement: "",
          JoinWar: "",
          military_disability_type: "",
        }],
        // deformInfo: [{
        //   deformit_name: "",
        //   mark:"1",
        //   advanced: "",
        //   disability: "",
        //   disability_level: "",
        //   disability_type: "",
        //   disability_number: "",
        // }],
        // soldInfo: [{
        //   sold_name: "",
        //   mark:"1",
        //   enlistment: "",
        //   retirement: "",
        //   JoinWar: "",
        //   military_disability_type: "",
        // }]
      },
      userrolelist: [],
      organlist: [],
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.calcTableHeight()
      window.removeEventListener("resize", this.calcTableHeight)
      window.addEventListener("resize", this.calcTableHeight)
      this.loadData()
      this.loadUser()
    })
  },
  beforeUnmount () {
    window.removeEventListener("resize", this.calcTableHeight)
  },
  methods: {
    calcTableHeight () {
      let mainHeight = window.getComputedStyle(this.$refs.mainSector.$el).height
      if (mainHeight.indexOf("px") !== -1)
        mainHeight = Number(mainHeight.substring(0, mainHeight.indexOf("px")))
      else
        mainHeight = Number(mainHeight)
      this.tableHeight = mainHeight - 120
    },
    toRefresh () {
      this.search.organid = ""
      this.search.depid = ""
      this.loadUser("refresh")
    },
    /** 展开收缩 */
    toggleRowExpansion () {
      const nodes = this.$refs.organTree.store._getAllNodes();
      nodes.forEach(item => {
        item.expanded = !item.expanded
      })
    },
    loadData () {
      this.$axios.get("/api/common/getorgantree")
        .then((response) => {
          const data = response.data
          if (data.success) {
            this.treeData = data.result
            this.treelding = false
            this.treeData.forEach(item => {
              if (item.type === "company" && item.children.length === 0) {
                item["disabled"] = true
              } else {
                item.children.forEach(i => {
                  if (i.type === "company" && i.children.length === 0) {
                    i["disabled"] = true
                  }
                })
              }
            })
          } else {
            this.$alert(response.data.result, "获取网格列表出错")
          }
        })
        .catch((error) => {
          this.$alert(error, "获取网格列表出错")
        })
    },

    toShowUser (node) {
      if (node.type === "unit") {
        this.search.unitid = node.UNIT_ID
        this.search.building = ""
        this.search.organid = ""
        this.search.department = ""
        this.search.floorid = ""
      }
      if (node.type === "buliding") {
        this.search.building = node.BUILDING_ID
        this.search.unitid = ""
        this.search.organid = ""
        this.search.department = ""
        this.search.floorid = ""
      }
      if (node.type === "organ") {
        this.search.organid = node.ORGAN_ID
        this.search.building = ""
        this.search.unitid = ""
        this.search.department = ""
        this.search.floorid = ""
      } 
      if (node.type === "department") {
        this.search.department = node.DEPARTMENT_ID
        this.search.building = ""
        this.search.unitid = ""
        this.search.floorid = ""
        this.search.organid = ""
      }
      if (node.type === "floor") {
        this.search.floorid = node.FLOOR_ID
        this.search.department = ""
        this.search.building = ""
        this.search.unitid = ""
        this.search.organid = ""
      }
      this.loadUser()
    },
    // 查询
    loadUser (d) {
      if (d === "disability_number") {
        this.search.disability_number = ""
      }
      this.loading = true
      this.$axios.post("/api/user/loaduserinfo", this.search)
        .then((response) => {
          if (response.data.success) {
            this.loading = false
            this.total = response.data.result.totalrecords
            this.userListData = response.data.result.datalist
            if (d === "refresh") {
              this.$message({ message: "刷新成功", type: "success" })
            }
          }
          else {
            this.$alert(response.data.result, "获取用户列表出错")
          }
        })
        .catch((error) => {
          this.$alert(error, "获取用户列表出错")
        })
    },
    toReset () {
      this.search.unitid = ""
      this.search.building = ""
      this.search.organid = ""
      this.search.department = ""
      this.search.floorid = ""
      this.search.property_name = ""
      this.loadUser()
    },
    // 新增
    toAddUser () {
      this.UserTitle = "用户新增"
      this.dailVisi = true
      this.personnelData = {
        FLOOR_ID: "",
        property_name: "",
        mark:"1",
        propertyid:"",
        register: "",
        property: [],
        specialName: "",
        opinion: "",
        familyType: [],
        remarks: "",
        communityInfo: [],
        deformInfo: [],
        soldInfo: []
      }
      if (this.$refs.userForm)
        this.$refs.userForm.clearValidate()
    },
    toEdit (data) {
      this.loadUser()
      this.personnelData = data
      if (!this.personnelData.communityInfo) {
        this.personnelData.communityInfo = []
      }
      this.personnelData.mark = "2"
      this.personnelData.FLOOR_ID = data.FLOOR_ID
      this.personnelData.communityInfo.forEach((item)=>{
        if (item.card.length >= 18) {
          item.birth = item.card.substring(6, 10) + "-" + item.card.substring(10, 12) + "-" + item.card.substring(12, 14)
        }
      })
      this.UserTitle = "用户编辑"
      this.dailVisi = true
    },
    toView(data){
      this.loadUser()
      this.personnelData = data
      if (!this.personnelData.communityInfo) {
        this.personnelData.communityInfo = []
      }
      this.personnelData.mark = "3"
      this.personnelData.FLOOR_ID = data.FLOOR_ID
      this.personnelData.communityInfo.forEach((item)=>{
        if (item.card.length >= 18) {
          item.birth = item.card.substring(6, 10) + "-" + item.card.substring(10, 12) + "-" + item.card.substring(12, 14)
        }
      })
      this.UserTitle = "用户查看"
      this.dailVisi = true
      if (this.$refs.userForm)
        this.$refs.userForm.clearValidate()
    },
    //保存
    toSave () {
      this.$axios.post("/api/common/savePersonneldata", this.personnelData)
        .then((response) => {
          if (response.data.success) {
            this.$message({ message: "住户保存成功", type: "success" })
            this.dailVisi = false
            this.loadUser()
          } else {
            this.$alert(response.data.result, "住户保存出错")
          }
        })
        .catch((error) => {
          this.$alert(error, "住户保存出错")
        })
    },
    //删除
    toDel (data) {
      console.log(data);
      this.$confirm("确认删除此住户?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$axios.post("/api/user/deluser", { propertyid: data.propertyid })
          .then((response) => {
            if (response.data.success) {
              this.$message({ message: "住户删除成功", type: "success" })
              this.loadUser()
            }
            else {
              this.$alert(response.data.result, "住户删除出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "住户删除出错")
          })
      })
    },

  }
}
</script>

<style scoped>
</style>
