/**
 * ajax请求配置
 */
import axios from 'axios'
import apiUrl from './components/apiUrl/api'

// axios默认配置
axios.defaults.timeout = 100000000;   // 超时时间
axios.defaults.baseURL = apiUrl;  // 默认地址
axios.defaults.withCredentials= true;
//整理数据
axios.defaults.transformRequest = function (data) {
//data = Qs.stringify(data);
    data = JSON.stringify(data);
    return data;
};


// 路由请求拦截
// http request 拦截器
axios.interceptors.request.use(
    config => {
        config.headers['Content-Type'] = 'application/json;charset=UTF-8';
        if (localStorage.getItem('sessionId')) {
            config.headers.Authorization  = localStorage.getItem('sessionId');
        }
        return config;
    },
    error => {
        return Promise.reject(error.response);
    });

// 路由响应拦截
// http response 拦截器
axios.interceptors.response.use(
    response => {
        if (response.data.resultCode==="404") {
            console.log("response.data.resultCode是404")
        }else{
            return response;
        }
    },
    error => {
        return Promise.reject(error.response)   // 返回接口返回的错误信息
    });
export default axios;
