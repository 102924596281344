<template>
  <router-view />
</template>

<script>
import "@/assets/icon/iconfont.css";
export default {
  name: 'App'
}
</script>

<style>


</style>
