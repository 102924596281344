<template>
  <el-container class="base-container">
    <el-header class="base-height">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>会计管理</el-breadcrumb-item>
        <el-breadcrumb-item>会计科目余额表</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-container class="main-container" ref="mainSector">
      <el-main style="height: 100%; border-left: 10px solid #f5f5f5; overflow:hidden;">
        <el-row style="margin-bottom: 10px">
          <el-col :span="3" class="input-search">
            <el-date-picker
                v-model="search.recordMonth"
                type="month"
                placeholder="选择年月"
                style="width: 100%"
                value-format="YYYY-MM"
                format="YYYY年MM月"
                @change="loadData()"
            />
          </el-col>
          <el-col :span="4" class="input-search">
            <el-cascader
                placeholder="请选择公司或部门"
                style="width: 100%;"
                v-model="search.organOrDep"
                :props="organprops"
                clearable
                :show-all-levels="false"
                @change="loadData($event)"
                :options="OrganTreeData" >
            </el-cascader>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-select v-model="search.subjectType" placeholder="请选择科目类别" @change="loadData" clearable>
              <el-option
                  v-for="item in subjectTypeData"
                  :key="item.CODE"
                  :label="item.CODE_NAME"
                  :value="item.CODE">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-cascader
                placeholder="请选择会计科目"
                style="width: 100%;"
                v-model="search.subjectSort"
                :props="props"
                clearable
                :show-all-levels="false"
                @change="changeDetail"
                :options="treeData" >
            </el-cascader>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-select v-model="search.subjectDetail" placeholder="请选择科目明细" clearable @change="loadData()" :disabled="disabled">
              <el-option
                  v-for="item in subjectDetailList"
                  :key="item.SUBJECT_DETAIL"
                  :label="item.SUBJECT_DETAIL"
                  :value="item.SUBJECT_DETAIL">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="7">
            <el-button type="primary" @click="toPrint()"  style="margin-left: 10px;">
              <i class="iconfont icon-print" style="font-size: 15px"></i><span style="margin-right: 5px">打印</span>
            </el-button>
            <el-button type="warning" @click="doExport()" :loading="exportTip !== ''" style="margin-left: 10px;">
              <i class="iconfont icon-daochu" style="font-size: 15px"></i><span style="margin-right: 5px">导出</span>
            </el-button>
            <a ref="expLink" target="_blank" style="display: none;"></a>
            <span style="font-size: 12px; margin-left: 20px">{{ exportTip }}</span>
          </el-col>
          <el-col :span="1" style="text-align: right;">
            <el-tooltip effect="dark" content="刷新" placement="top">
              <el-link :underline="false" @click="toRefresh()">
                <el-icon :size="20"><Refresh /></el-icon>
              </el-link>
            </el-tooltip>
          </el-col>
        </el-row>

        <el-table
            :height="tableHeight"
            border
            :span-method="objectSpanMethod"
            :data="subjectBalanceData"
            :cell-style="cellStyleMethod">
          <el-table-column
              prop="SUBJECT_TYPE_NAME"
              label="科目类型"
              show-overflow-tooltip
              align="center"
              min-width="60px"
          >
          </el-table-column>
          <el-table-column
              prop="SUBJECT_NAME"
              label="科目名称"
              align="center"
              show-overflow-tooltip
              min-width="80px"
          >
          </el-table-column>
          <el-table-column
              prop="SUBJECT_DETAIL"
              label="科目明细"
              align="center"
              show-overflow-tooltip
              min-width="80px"
          >
          </el-table-column>
          <el-table-column
              prop="total_init_debtor"
              label="期初借方(元)"
              align="center"
              show-overflow-tooltip
              min-width="60px"
          >
            <template #default="scope">
              {{ formatNumber(scope.row.total_init_debtor) }}
            </template>
          </el-table-column>
          <el-table-column
              prop="total_init_credit"
              label="期初贷方(元)"
              align="center"
              show-overflow-tooltip
              min-width="60px"
          >
            <template #default="scope">
              {{ formatNumber(scope.row.total_init_credit) }}
            </template>
          </el-table-column>
          <el-table-column
              prop="total_debtor"
              label="借方金额(元)"
              align="center"
              show-overflow-tooltip
              min-width="80px"
          >
            <template #default="scope">
              {{ formatNumber(scope.row.total_debtor) }}
            </template>
          </el-table-column>
          <el-table-column
              prop="total_credit"
              label="贷方金额(元)"
              show-overflow-tooltip
              align="center"
              min-width="80px"
          >
            <template #default="scope">
              {{ formatNumber(scope.row.total_credit) }}
            </template>
          </el-table-column>
          <el-table-column
              prop="total_end_debtor"
              label="期末借方(元)"
              show-overflow-tooltip
              align="center"
              min-width="80px"
          >
            <template #default="scope">
              {{ formatNumber(scope.row.total_end_debtor) }}
            </template>
          </el-table-column>
          <el-table-column
              prop="total_end_credit"
              label="期末贷方(元)"
              show-overflow-tooltip
              align="center"
              min-width="80px"
          >
            <template #default="scope">
              {{ formatNumber(scope.row.total_end_credit) }}
            </template>
          </el-table-column>
        </el-table>

        <el-drawer :size="1000"  v-model="drawerVisi" :with-header="false">
          <div style="width:100%; height: calc(100% - 10px); ">
            <iframe :src="pdffile" id="fra" style="width: 100%;height: 100%;"></iframe>
          </div>
        </el-drawer>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import apiURL from "@/components/apiUrl/api";

export default {
  name: "SubjectBalanceReport",
  data () {
    return {
      tableHeight: 0,
      search:{
        recordMonth:"",
        subjectType:"",
        subjectSort:"",
        subjectDetail:"",
        organOrDep:"",
        s_organid:"",
        s_depid:"",
      },
      OrganTreeData:[],
      organprops:{
        label:'text',value:'value',children:'children',
        checkStrictly: true,
        emitPath:false,
      },
      subjectBalanceData:[],
      exportTip: "",
      subjectTypeData: [],
      treeData:[],
      props:{
        label:'label',value:'id',children:'children',
        emitPath:false,
      },
      subjectDetailList:"",
      disabled:true,
      allSubjectDetail:[],
      pdffile:"",
      drawerVisi:false,
    }
  },
  computed:{
    getNowTime() {
      let now = new Date();
      let year = now.getFullYear(); //得到年份
      let month = now.getMonth(); //得到月份
      month = month + 1;
      month = month.toString().padStart(2, "0");
      return `${year}-${month}`;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.calcTableHeight()
      window.removeEventListener("resize", this.calcTableHeight)
      window.addEventListener("resize", this.calcTableHeight)
      this.search.recordMonth = this.getNowTime+"";
      this.search.organOrDep = "company-"+sessionStorage.getItem("organ-organid")
      this.loadData()
      this.loadCode()
    })
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calcTableHeight)
  },
  methods: {
    calcTableHeight() {
      let mainHeight = window.getComputedStyle(this.$refs.mainSector.$el).height
      if (mainHeight.indexOf("px") !== -1)
        mainHeight = Number(mainHeight.substring(0, mainHeight.indexOf("px")))
      else
        mainHeight = Number(mainHeight)
      this.tableHeight = mainHeight - 100
    },
    toRefresh() {
      this.loadData("refresh")
    },
    formatNumber(value) {
      if(value !== "" && value !== null)
        return new Intl.NumberFormat().format(value);
    },
    loadCode() {
      this.$axios.post("/api/common/getcodelists", {codes: [ "SUBJECT_TYPE"]})
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.subjectTypeData = data.result.SUBJECT_TYPE
            } else {
              this.$alert(response.data.result, "获取代码列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取代码列表出错")
          })
      this.$axios.get("/api/common/getsubjecttree")
          .then((response) => {
            if (response.data.success) {
              this.treeData = response.data.result
            }
            else {
              this.$alert(response.data.result, "获取会计科目列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取会计科目列表出错")
          })
      this.$axios.post("/api/common/getallsubjectdetail", this.search)
          .then((response) => {
            if (response.data.success) {
              this.allSubjectDetail = response.data.result
            }
            else {
              this.$alert(response.data.result, "获取会计科目列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取会计科目列表出错")
          })
      this.$axios.get("/api/common/getqueryorgantree")
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.OrganTreeData = data.result
            } else {
              this.$alert(response.data.result, "获取组织列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取组织列表出错")
          })
    },

    loadData(d) {
      if(this.search.organOrDep !== "" && this.search.organOrDep !== null){
        const parts = this.search.organOrDep.toString().split('-');
        if(parts[0] === "company"){
          this.search.s_organid = parts[1]
          this.search.s_depid = ""
        }else{
          this.search.s_organid = parts[0]
          this.search.s_depid = parts[1]
        }
      }else{
        this.search.s_organid = ""
        this.search.s_depid = ""
      }
      this.$axios.post("/api/entry/loadsubjectbalancedata", this.search)
          .then((response) => {
            if (response.data.success) {
              this.subjectBalanceData = response.data.result
              if (d === "refresh") {
                this.$message({message: "刷新成功", type: "success"})
              }
            } else {
              this.$alert(response.data.result, "获取会计科目余额数据出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取会计科目余额数据出错")
          })
    },
    changeDetail(){
      let f=[]
      if(this.search.subjectSort !== "" && this.search.subjectSort !== null ) {
        this.disabled = false
        f = this.allSubjectDetail.filter((item) => {
          return Number(item.SUBJECT_SORT) === Number(this.search.subjectSort)
        })
      }else{
        this.disabled = true
      }
      this.subjectDetailList = f
      this.search.subjectDetail = ""
      this.loadData()
    },
    //导出
    doExport () {
      this.exportTip = "明细资金月报表导出Excel中..."
      this.$axios.post("/api/report/exportdetaildata",{data: this.subjectBalanceData, recordMonth:this.search.recordMonth}, {
        responseType: "blob"
      })
          .then((response) => {
            setTimeout(this.exportTip = "", 3000)
            const err = response.headers["error-content"]
            if (typeof err === "undefined") {
              const blobUrl = window.URL.createObjectURL(new Blob([response.data]))
              let fname = response.headers["content-disposition"].split("filename=")[1]
              fname = decodeURIComponent(fname)
              const a = this.$refs.expLink
              a.href = blobUrl
              a.setAttribute("download", fname)
              a.click()
            }
            else
              this.$alert(decodeURIComponent(err), "明细资金月报表导出出错")
          })
          .catch((error) => {
            this.exportTip = ""
            this.$alert(error, "明细资金月报表导出出错")
          })

    },

    toPrint (type){
      this.drawerVisi = true
      this.pdffile = ""
      if(this.search.organOrDep !== "" && this.search.organOrDep !== null){
        const parts = this.search.organOrDep.toString().split('-');
        if(parts[0] === "company"){
          this.search.s_organid = parts[1]
          this.search.s_depid = ""
        }else{
          this.search.s_organid = parts[0]
          this.search.s_depid = parts[1]
        }
      }else{
        this.search.s_organid = ""
        this.search.s_depid = ""
      }
      if(type === "detail"){
        this.pdffile = apiURL+"/api/report/printmonthtransferdetail?recordMonth="+this.search.recordMonth +"&s_organid="+this.search.s_organid +
            "&s_depid="+this.search.s_depid +"&subjectType="+ this.search.subjectType+
            "&subjectSort="+ this.search.subjectSort+"&subjectDetail="+ this.search.subjectDetail
      }else if(type === "summary"){
        this.pdffile = apiURL+"/api/report/printmonthtransfersummary?recordMonth="+this.search.recordMonth +"&s_organid="+this.search.s_organid +
            "&s_depid="+this.search.s_depid +"&subjectType="+ this.search.subjectType+
            "&subjectSort="+ this.search.subjectSort+"&subjectDetail="+ this.search.subjectDetail
      }
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      console.log(row, column, rowIndex, columnIndex);
      if (row.SUBJECT_TYPE === '合计' || row.SUBJECT_TYPE === '总计') {
        if (columnIndex === 0) {
          return [1, 3];
        } else if (columnIndex === 1 || columnIndex === 2) {
          return [0, 0];
        }
      } else {
        if (columnIndex === 0) {
          let spanArr = this.getSpanArr(this.subjectBalanceData)
          const _row = spanArr[rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col,
          }
        }
        if (columnIndex === 1) {
          let spanArr = this.getSpanArr1(this.subjectBalanceData)
          const _row = spanArr[rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col,
          }
        }
      }
    },
    getSpanArr(arr) {
      if (arr) {
        const spanOneArr = []
        let concatOne = 0
        arr.forEach((item, index) => {
          if (index === 0) {
            spanOneArr.push(1)
          } else {
            if (item.SUBJECT_TYPE=== arr[index - 1].SUBJECT_TYPE) {
              spanOneArr[concatOne] += 1
              spanOneArr.push(0)
            } else {
              spanOneArr.push(1)
              concatOne = index
            }
          }
        })
        return spanOneArr
      }
    },
    getSpanArr1(arr) {
      if (arr) {
        const spanOneArr = []
        let concatOne = 0
        arr.forEach((item, index) => {
          if (index === 0) {
            spanOneArr.push(1)
          } else {
            if (item.SUBJECT_NAME=== arr[index - 1].SUBJECT_NAME) {
              spanOneArr[concatOne] += 1
              spanOneArr.push(0)
            } else {
              spanOneArr.push(1)
              concatOne = index
            }
          }
        })
        return spanOneArr
      }
    },
    cellStyleMethod({ row, columnIndex }) {
      if (row.SUBJECT_TYPE === '合计' || row.SUBJECT_TYPE === '总计') {
        return { 'text-align': 'right', 'font-weight': '600', 'background':'#f0f9eb' };
      }
      if(columnIndex === 0 || columnIndex === 1){
        return { 'background':'#f5f7fa'};
      }
    },
  }
}
</script>

<style scoped>

</style>
