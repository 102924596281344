<template>
  <el-container class="base-container">
    <el-header class="base-height">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>报表查询</el-breadcrumb-item>
        <el-breadcrumb-item>实时损益表</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>

    <el-container class="main-container" ref="mainSector">
      <el-main >
        <div>
          <el-row style="margin-bottom: 10px">
            <el-col :span="4" class="input-search">
              <el-date-picker
                  v-model="search.recordMonth"
                  type="month"
                  placeholder="选择年月"
                  style="width: 100%"
                  value-format="YYYY-MM"
                  format="YYYY年MM月"
                  @change="loadData()"
                  :clearable="false"
              />
            </el-col>
            <el-col :span="4" class="input-search">
              <el-cascader
                  placeholder="请选择公司或部门"
                  style="width: 100%;"
                  v-model="search.organOrDep"
                  :props="organprops"
                  clearable
                  :show-all-levels="false"
                  @change="loadData($event)"
                  :options="OrganTreeData" >
              </el-cascader>
            </el-col>
            <el-col :span="14">
              <el-dropdown>
                <el-button type="primary" >
                  <i class="iconfont icon-print" style="font-size: 14px"></i><span style="margin-right: 5px">打印 </span>  <el-icon><ArrowDown /></el-icon>
                </el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item>
                      <el-link @click="toPrint('detail')" type="primary" :underline="false" >
                        <i class="iconfont icon-print-full"></i>打印明细
                      </el-link>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-link @click="toPrint('summary')" type="primary" :underline="false" >
                        <i class="iconfont icon-print-full"></i>打印汇总
                      </el-link>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
              <el-dropdown  style="margin-left: 10px;">
                <el-button type="warning" >
                  <i class="iconfont icon-daochu" style="font-size: 14px"></i><span style="margin-right: 5px">导出 </span>  <el-icon><ArrowDown /></el-icon>
                </el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item>
                      <el-link @click="doExport('detail')" type="warning" :underline="false">
                        <i class="iconfont icon-daochu"></i>导出明细
                      </el-link>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-link @click="doExport('summary')" type="warning" :underline="false">
                        <i class="iconfont icon-daochu"></i>导出汇总
                      </el-link>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
              <a ref="expLink" target="_blank" style="display: none;"></a>
              <span style="font-size: 12px; margin-left: 20px">{{ exportTip }}</span>
            </el-col>
            <el-col :span="2" style="text-align: right;">
              <el-tooltip effect="dark" content="刷新" placement="top">
                <el-link :underline="false" @click="toRefresh()">
                  <el-icon :size="20"><Refresh /></el-icon>
                </el-link>
              </el-tooltip>
            </el-col>
          </el-row>
        </div>
        <el-tabs type="border-card">
          <el-tab-pane>
            <template #label>
              <span class="custom-tabs-label">
                <i class="iconfont icon-chaxunbaobiaomingxi" style="font-size: 22px;"></i>
                <span>明细查询</span>
              </span>
            </template>
            <div style="display: flex; flex-direction: row ">
              <div style="width: 50%;  overflow:hidden;">
                <el-table
                    :height="tableHeight"
                    :span-method="objectSpanMethod"
                    :data="revenueDataDetail"
                    border
                    :cell-style="cellStyleMethod">
                  <el-table-column
                      prop="SUBJECT_NAME"
                      label="收入项目"
                      show-overflow-tooltip
                      align="center"
                      min-width="60px"
                  >
                  </el-table-column>
                  <el-table-column
                      prop="SUBJECT_DETAIL"
                      label="收支明细"
                      align="center"
                      show-overflow-tooltip
                      min-width="80px"
                  >
                  </el-table-column>

                  <el-table-column
                      prop="REVENUE_MONEY"
                      label="收入金额(元)"
                      align="center"
                      show-overflow-tooltip
                      min-width="80px"
                  >
                  </el-table-column>
                </el-table>
              </div>
              <div style="width: 50%;  overflow:hidden;">
                <el-table
                    :height="tableHeight"
                    :span-method="objectSpanMethod1"
                    :data="expenditureDataDetail"
                    border
                    :cell-style="cellStyleMethod">
                  <el-table-column
                      prop="SUBJECT_NAME"
                      label="支出项目"
                      show-overflow-tooltip
                      align="center"
                      min-width="60px"
                  >
                  </el-table-column>
                  <el-table-column
                      prop="SUBJECT_DETAIL"
                      label="支出明细"
                      align="center"
                      show-overflow-tooltip
                      min-width="80px"
                  >
                  </el-table-column>

                  <el-table-column
                      prop="EXPENDITURE_MONEY"
                      label="支出金额(元)"
                      align="center"
                      show-overflow-tooltip
                      min-width="80px"
                  >
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div style="width: 100%; line-height: 40px; background:#A2D5F2">
              <span style="color: #606266; padding-left: 50px;" v-if="this.rev_total - this.exp_total > 0">
                <i class="iconfont icon-yingli" style="font-size: 14px; color: #0ea204"></i>盈利金额: {{ Math.abs((this.rev_total - this.exp_total).toFixed(2)) }}元
              </span>
              <span style="color: #606266; padding-left: 50px;" v-else>
                <i class="iconfont icon-kuisun" style="font-size: 14px; color: #d81e06"></i>亏损金额: {{ Math.abs((this.rev_total - this.exp_total).toFixed(2)) }} 元
              </span>
            </div>
          </el-tab-pane>

          <el-tab-pane>
            <template #label>
              <span class="custom-tabs-label">
                <i class="iconfont icon-zonghebaobiaohuizong" style="font-size: 22px;"></i>
                <span>汇总查询</span>
              </span>
            </template>
            <div style="display: flex; flex-direction: row ">
              <div style="width: 50%;  overflow:hidden;">
                <el-table
                    :height="tableHeight"
                    :data="revenueDataSummary"
                    border
                    :cell-style="cellStyleMethod">
                  <el-table-column
                      prop="SUBJECT_NAME"
                      label="收入项目"
                      show-overflow-tooltip
                      align="center"
                      min-width="60px"
                  >
                  </el-table-column>
                  <el-table-column
                      prop="REVENUE_MONEY"
                      label="收入金额(元)"
                      align="center"
                      show-overflow-tooltip
                      min-width="80px"
                  >
                  </el-table-column>
                </el-table>
              </div>
              <div style="width: 50%;  overflow:hidden;">
                <el-table
                    :height="tableHeight"
                    :data="expenditureDataSummary"
                    border
                    :cell-style="cellStyleMethod">
                  <el-table-column
                      prop="SUBJECT_NAME"
                      label="支出项目"
                      show-overflow-tooltip
                      align="center"
                      min-width="60px"
                  >
                  </el-table-column>
                  <el-table-column
                      prop="EXPENDITURE_MONEY"
                      label="支出金额(元)"
                      align="center"
                      show-overflow-tooltip
                      min-width="80px"
                  >
                  </el-table-column>
                </el-table>
              </div>
            </div>

            <div style="width: 100%; line-height: 40px; background:#A2D5F2">
              <span style="color: #606266; padding-left: 50px;" v-if="this.rev_total1 - this.exp_total1 > 0">
                <i class="iconfont icon-yingli" style="font-size: 14px; color: #0ea204"></i>盈利金额: {{ Math.abs((this.rev_total1 - this.exp_total1).toFixed(2)) }}元
              </span>
              <span style="color: #606266; padding-left: 50px;" v-else>
                <i class="iconfont icon-kuisun" style="font-size: 14px; color: #d81e06"></i>亏损金额: {{ Math.abs((this.rev_total1 - this.exp_total1).toFixed(2)) }} 元
              </span>
            </div>
          </el-tab-pane>
        </el-tabs>

        <el-drawer :size="1000"  v-model="drawerVisi" :with-header="false">
          <div style="width:100%; height: calc(100% - 10px); ">
            <iframe :src="pdffile" id="fra" style="width: 100%;height: 100%;"></iframe>
          </div>
        </el-drawer>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import apiURL from "@/components/apiUrl/api";

export default {
  name: "ProfitAndLossReport",
  data () {
    return {
      tableHeight: 0,
      search:{
        recordMonth:"",
        organOrDep:"",
        s_organid:"",
        s_depid:"",
      },
      OrganTreeData:[],
      organprops:{
        label:'text',value:'value',children:'children',
        checkStrictly: true,
        emitPath:false,
      },
      exportTip: "",
      revenueDataDetail:[],
      expenditureDataDetail:[],
      revenueDataSummary:[],
      expenditureDataSummary:[],
      rev_total:0,
      exp_total:0,
      rev_total1:0,
      exp_total1:0,
      pdffile:"",
      drawerVisi:false,
    }
  },
  computed:{
    getNowTime() {
      let now = new Date();
      let year = now.getFullYear(); //得到年份
      let month = now.getMonth(); //得到月份
      month = month + 1;
      month = month.toString().padStart(2, "0");
      return `${year}-${month}`;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.calcTableHeight()
      window.removeEventListener("resize", this.calcTableHeight)
      window.addEventListener("resize", this.calcTableHeight)
      this.search.recordMonth = this.getNowTime+"";
      this.search.organOrDep = "company-"+sessionStorage.getItem("organ-organid")
      this.loadTree()
      this.loadData()
    })
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calcTableHeight)
  },
  methods: {
    calcTableHeight() {
      let mainHeight = window.getComputedStyle(this.$refs.mainSector.$el).height
      if (mainHeight.indexOf("px") !== -1)
        mainHeight = Number(mainHeight.substring(0, mainHeight.indexOf("px")))
      else
        mainHeight = Number(mainHeight)
      this.tableHeight = mainHeight - 200
    },
    toRefresh() {
      this.loadData("refresh")
    },
    loadTree(){
      this.$axios.get("/api/common/getqueryorgantree")
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.OrganTreeData = data.result
            } else {
              this.$alert(response.data.result, "获取组织列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取组织列表出错")
          })
    },
    //收入
    loadData(d) {
      if(this.search.organOrDep !== "" && this.search.organOrDep !== null){
        const parts = this.search.organOrDep.toString().split('-');
        if(parts[0] === "company"){
          this.search.s_organid = parts[1]
          this.search.s_depid = ""
        }else{
          this.search.s_organid = parts[0]
          this.search.s_depid = parts[1]
        }
      }else{
        this.search.s_organid = ""
        this.search.s_depid = ""
      }
      this.$axios.post("/api/report/loadrevenuedatadetail", this.search)
          .then((response) => {
            if (response.data.success) {
              this.revenueDataDetail = response.data.result
              this.formatData(this.revenueDataDetail,'rev')
            } else {
              this.$alert(response.data.result, "获取收入明细数据出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取收入明细数据出错")
          })
      this.$axios.post("/api/report/loadexpendituredatadetail", this.search)
          .then((response) => {
            if (response.data.success) {
              this.expenditureDataDetail = response.data.result
              this.formatData(this.expenditureDataDetail,'exp')
              if (d === "refresh") {
                this.$message({message: "刷新成功", type: "success"})
              }
            } else {
              this.$alert(response.data.result, "获取支出明细数据出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取支出明细数据出错")
          })

      this.$axios.post("/api/report/loadrevenuedatasummary", this.search)
          .then((response) => {
            if (response.data.success) {
              this.revenueDataSummary = response.data.result
              this.formatData1(this.revenueDataSummary,'rev')
            } else {
              this.$alert(response.data.result, "获取收入汇总数据出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取收入汇总数据出错")
          })
      this.$axios.post("/api/report/loadexpendituredatasummary", this.search)
          .then((response) => {
            if (response.data.success) {
              this.expenditureDataSummary = response.data.result
              this.formatData1(this.expenditureDataSummary,'exp')
              if (d === "refresh") {
                this.$message({message: "刷新成功", type: "success"})
              }
            } else {
              this.$alert(response.data.result, "获取支出汇总数据出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取支出汇总数据出错")
          })
    },

    cellStyleMethod({ row, columnIndex }) {
      if (row.SUBJECT_NAME === '合计' || row.SUBJECT_NAME === '总计') {
        return { 'text-align': 'right', 'font-weight': '600', 'background':'#f0f9eb' };
      }else if(columnIndex === 0 ){
        return { 'background':'#fafbfd'};
      }

    },
    //导出
    doExport (type) {
      if(type === "summary"){
        this.exportTip = "损益表汇总导出Excel中..."
        this.$axios.post("/api/report/exportprofitandlossdatasummary", {revenueData: this.revenueDataSummary, expenditureData: this.expenditureDataSummary, recordMonth:this.search.recordMonth}, {
          responseType: "blob"
        })
            .then((response) => {
              setTimeout(this.exportTip = "", 1000)
              const err = response.headers["error-content"]
              if (typeof err === "undefined") {
                const blobUrl = window.URL.createObjectURL(new Blob([response.data]))
                let fname = response.headers["content-disposition"].split("filename=")[1]
                fname = decodeURIComponent(fname)
                const a = this.$refs.expLink
                a.href = blobUrl
                a.setAttribute("download", fname)
                a.click()
              }
              else
                this.$alert(decodeURIComponent(err), "损益表汇总导出出错")
            })
            .catch((error) => {
              this.exportTip = ""
              this.$alert(error, "损益表汇总导出出错")
            })
      }else if(type === "detail"){
        this.exportTip = "损益表明细导出Excel中..."
        this.$axios.post("/api/report/exportprofitandlossdatadetail", {revenueData: this.revenueDataDetail, expenditureData: this.expenditureDataDetail, recordMonth:this.search.recordMonth}, {
          responseType: "blob"
        })
            .then((response) => {
              setTimeout(this.exportTip = "", 1000)
              const err = response.headers["error-content"]
              if (typeof err === "undefined") {
                const blobUrl = window.URL.createObjectURL(new Blob([response.data]))
                let fname = response.headers["content-disposition"].split("filename=")[1]
                fname = decodeURIComponent(fname)
                const a = this.$refs.expLink
                a.href = blobUrl
                a.setAttribute("download", fname)
                a.click()
              }
              else
                this.$alert(decodeURIComponent(err), "损益表明细导出出错")
            })
            .catch((error) => {
              this.exportTip = ""
              this.$alert(error, "损益表明细导出出错")
            })
      }
    },
    objectSpanMethod({ rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        let spanArr = this.getSpanArr(this.revenueDataDetail)
        const _row = spanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col,
        }
      }
    },
    objectSpanMethod1({ rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        let spanArr = this.getSpanArr(this.expenditureDataDetail)
        const _row = spanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col,
        }
      }
    },
    getSpanArr(arr) {
      if (arr) {
        const spanOneArr = []
        let concatOne = 0
        arr.forEach((item, index) => {
          if (index === 0) {
            spanOneArr.push(1)
          } else {
            if (item.SUBJECT_NAME=== arr[index - 1].SUBJECT_NAME) {
              spanOneArr[concatOne] += 1
              spanOneArr.push(0)
            } else {
              spanOneArr.push(1)
              concatOne = index
            }
          }
        })
        return spanOneArr
      }
    },

    formatData(info, type) {
      let data = JSON.parse(JSON.stringify(info));
      let temp = {};
      let pos = 0

      if(type === 'rev') {
        for (let i = 0; i < data.length; i++) {
          if (data[i].REVENUE_MONEY == null)
            data[i].REVENUE_MONEY = 0
          if (temp[data[i].SUBJECT_SORT]) {
            temp[data[i].SUBJECT_SORT]['REVENUE_MONEY'] += parseFloat(data[i].REVENUE_MONEY);
          } else {
            temp[data[i].SUBJECT_SORT] = {};
            temp[data[i].SUBJECT_SORT]['REVENUE_MONEY'] = parseFloat(data[i].REVENUE_MONEY);
          }
          if (i > 0 && data[i].SUBJECT_SORT !== data[i - 1].SUBJECT_SORT) {
            info.splice(i + pos, 0, {
              SUBJECT_NAME: '合计',
              SUBJECT_DETAIL: '',
              REVENUE_MONEY: temp[data[i - 1].SUBJECT_SORT]['REVENUE_MONEY'].toFixed(2).replace(/\.00$/, ''),
            });
            if (i === data.length - 1) {
              info.push({
                SUBJECT_NAME: '合计',
                SUBJECT_DETAIL: '',
                REVENUE_MONEY: temp[data[i].SUBJECT_SORT]['REVENUE_MONEY'].toFixed(2).replace(/\.00$/, ''),
              });
            }
            pos++
          } else if (i === data.length - 1) {
            if (i === 0) {
              info.push({
                SUBJECT_NAME: '合计',
                SUBJECT_DETAIL: '',
                REVENUE_MONEY: temp[data[i].SUBJECT_SORT]['REVENUE_MONEY'].toFixed(2).replace(/\.00$/, ''),
              });
            } else {
              info.push({
                SUBJECT_NAME: '合计',
                SUBJECT_DETAIL: '',
                REVENUE_MONEY: temp[data[i - 1].SUBJECT_SORT]['REVENUE_MONEY'].toFixed(2).replace(/\.00$/, ''),
              });
            }
            pos++
          }
        }
        let totalAmount1 = 0;
        for (let key in data) {
          totalAmount1 += parseFloat(data[key].REVENUE_MONEY);
        }
        info.push({
          SUBJECT_NAME: '总计',
          SUBJECT_DETAIL: '',
          REVENUE_MONEY: totalAmount1.toFixed(2).replace(/\.00$/, ''),
        });
        this.rev_total = totalAmount1.toFixed(2).replace(/\.00$/, '')
      }
      else if(type === "exp"){
        for (let i = 0; i < data.length; i++) {
          if (data[i].EXPENDITURE_MONEY == null)
            data[i].EXPENDITURE_MONEY = 0
          if (temp[data[i].SUBJECT_SORT]) {
            temp[data[i].SUBJECT_SORT]['EXPENDITURE_MONEY'] += parseFloat(data[i].EXPENDITURE_MONEY);
          } else {
            temp[data[i].SUBJECT_SORT] = {};
            temp[data[i].SUBJECT_SORT]['EXPENDITURE_MONEY'] = parseFloat(data[i].EXPENDITURE_MONEY);
          }
          if (i > 0 && data[i].SUBJECT_SORT !== data[i - 1].SUBJECT_SORT) {
            info.splice(i + pos, 0, {
              SUBJECT_NAME: '合计',
              SUBJECT_DETAIL: '',
              EXPENDITURE_MONEY: temp[data[i - 1].SUBJECT_SORT]['EXPENDITURE_MONEY'].toFixed(2).replace(/\.00$/, ''),
            });
            if (i === data.length - 1) {
              info.push({
                SUBJECT_NAME: '合计',
                SUBJECT_DETAIL: '',
                EXPENDITURE_MONEY: temp[data[i].SUBJECT_SORT]['EXPENDITURE_MONEY'].toFixed(2).replace(/\.00$/, ''),
              });
            }
            pos++
          } else if (i === data.length - 1) {
            if (i === 0) {
              info.push({
                SUBJECT_NAME: '合计',
                SUBJECT_DETAIL: '',
                EXPENDITURE_MONEY: temp[data[i].SUBJECT_SORT]['EXPENDITURE_MONEY'].toFixed(2).replace(/\.00$/, ''),
              });
            } else {
              info.push({
                SUBJECT_NAME: '合计',
                SUBJECT_DETAIL: '',
                EXPENDITURE_MONEY: temp[data[i - 1].SUBJECT_SORT]['EXPENDITURE_MONEY'].toFixed(2).replace(/\.00$/, ''),
              });
            }
            pos++
          }
        }
        let totalAmount1 = 0;
        for (let key in data) {
          totalAmount1 += parseFloat(data[key].EXPENDITURE_MONEY);
        }
        info.push({
          SUBJECT_NAME: '总计',
          SUBJECT_DETAIL: '',
          EXPENDITURE_MONEY: totalAmount1.toFixed(2).replace(/\.00$/, ''),
        });
        this.exp_total = totalAmount1.toFixed(2).replace(/\.00$/, '')
      }
    },

    formatData1(info, type) {
      let data = JSON.parse(JSON.stringify(info));
      if(type === 'rev') {
        for (let i = 0; i < data.length; i++) {
          if (data[i].REVENUE_MONEY == null)
            data[i].REVENUE_MONEY = 0
        }
        let totalAmount1 = 0;
        for (let key in data) {
          totalAmount1 += parseFloat(data[key].REVENUE_MONEY);
        }
        info.push({
          SUBJECT_NAME: '总计',
          SUBJECT_DETAIL: '',
          REVENUE_MONEY: totalAmount1.toFixed(2).replace(/\.00$/, ''),
        });
        this.rev_total1 = totalAmount1.toFixed(2).replace(/\.00$/, '')
      }
      else if(type === "exp"){
        let totalAmount1 = 0;
        for (let key in data) {
          if (data[key].EXPENDITURE_MONEY == null)
            data[key].EXPENDITURE_MONEY = 0
          totalAmount1 += parseFloat(data[key].EXPENDITURE_MONEY);
        }
        info.push({
          SUBJECT_NAME: '总计',
          SUBJECT_DETAIL: '',
          EXPENDITURE_MONEY: totalAmount1.toFixed(2).replace(/\.00$/, ''),
        });
        this.exp_total1 = totalAmount1.toFixed(2).replace(/\.00$/, '')
      }
    },

    toPrint (type){
      this.drawerVisi = true
      this.pdffile = ""
      if(this.search.organOrDep !== "" && this.search.organOrDep !== null){
        const parts = this.search.organOrDep.toString().split('-');
        if(parts[0] === "company"){
          this.search.s_organid = parts[1]
          this.search.s_depid = ""
        }else{
          this.search.s_organid = parts[0]
          this.search.s_depid = parts[1]
        }
      }else{
        this.search.s_organid = ""
        this.search.s_depid = ""
      }
      if(type === "detail"){
        this.pdffile = apiURL+"/api/report/printprofitandlossdatadetail?recordMonth="+this.search.recordMonth +"&s_organid="+this.search.s_organid +
            "&s_depid="+this.search.s_depid
        console.log(this.pdffile)
      }else if(type === "summary"){
        this.pdffile = apiURL+"/api/report/printprofitandlossdatasummary?recordMonth="+this.search.recordMonth +"&s_organid="+this.search.s_organid +
            "&s_depid="+this.search.s_depid
      }
    },
  }
}
</script>

<style scoped>

</style>
