import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus, {ElMessageBox} from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import * as ElementPlusIcons from '@element-plus/icons-vue'
import "./assets/css/common.css"
import axios from '@/axios'
import "./assets/ttf/TheFont.css"


const app =createApp(App)
app.use(router)

app.use(ElementPlus, {
    locale: zhCn,
})
for (const iconName in ElementPlusIcons) {
    if (Reflect.has(ElementPlusIcons, iconName)) {
        const item = ElementPlusIcons[iconName]
        app.component(iconName, item)
    }
}
app.config.globalProperties.$axios = axios;
axios.interceptors.response.use(response => {
    const result = response.data
    if (result.success) {
        return response
    } else {
        sessionStorage.setItem("is-login", "0")
        if (typeof result === "object" && typeof result.result === "string" && result.result === "未登录或登录失效，请重新登录") {
            if(document.getElementsByClassName('el-message-box').length !== 0){
                setTimeout(() => {
                    ElMessageBox.close(false)
                    ElMessageBox.alert(result.result, '失败信息提示')
                },500)
            }
            if (router.currentRoute.name !== "login-page") {
                router.replace({name: "login-page"})
            }
            return response
        } else {
            return response
        }
    }
})
app.mount('#app')
