<template>
  <el-container class="base-container">
    <el-header class="base-height">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>系统配置</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-container class="main-container"  ref="mainSector">
      <el-main style="height: 100%">
        <div style="height: calc(100% - 55px);padding: 10px">
          <el-scrollbar >
            <el-form
                :inline="true"
                :model="titleData"
                :rules="rules"
                ref="titleForm"
                label-width="130px"
            >
              <el-divider content-position="left">APP联系配置</el-divider>
              <el-form-item class="form-row">
                <el-button type="success" @click="addDomain">增加机构</el-button>
              </el-form-item>
              <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
                <div v-for="(domain, index) in titleData.domains" :key="index" style="margin: 10px; width: 23%;">
                  <el-card >
                    <el-form-item :label="'联系机构' + index +':'"  :prop="'domains.' + index + '.name'" label-width="90px">
                      <el-input v-model="domain.name" />
                    </el-form-item>
                    <el-form-item :label="'联系地址:' + index +':'" :prop="'domains.' + index + '.address'" label-width="90px">
                      <el-input v-model="domain.address" />
                    </el-form-item>
                    <el-form-item :label="'联系邮箱:' + index +':'" :prop="'domains.' + index + '.email'" label-width="90px">
                      <el-input v-model="domain.email" />
                    </el-form-item>
                    <el-form-item :label="'联系电话:' + index +':'" :prop="'domains.' + index + '.phone'" label-width="90px">
                      <el-input v-model="domain.phone" />
                    </el-form-item>
                    <div style="text-align: center;">
                      <el-button @click="removeDomain(domain)" type="danger">删除</el-button>
                    </div>
                  </el-card>
                </div>
              </div>
              <el-divider content-position="left">APP轮播图配置</el-divider>
              <el-form-item class="form-row">
                <div>
                  <el-upload
                      v-model:file-list="titleData.imgList"
                      style="height: 100px"
                      class="upload"
                      name="uploadfile"
                      list-type="picture-card"
                      method="post"
                      :action="uploadAction"
                      :with-credentials="true"
                      :headers="headerObj"
                      :before-upload="handlebe"
                      :limit="5"
                      :on-error="error"
                      :on-success="uploadSuccess"
                      :disabled="ifup"
                      accept="image/jpg, image/jpeg, image/png">
                    <el-icon><Plus /></el-icon>
                    <template #file="{ file }">
                      <div>
                        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                        <span class="el-upload-list__item-actions">
                  <span class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)">
                    <el-icon><zoom-in /></el-icon>
                  </span>
                  <span class="el-upload-list__item-delete" @click="onRemove(file)">
                    <el-icon><Delete /></el-icon>
                  </span>
                </span>
                      </div>
                    </template>
                    <template #tip>
                      <div class="el-upload__tip">
                        仅限数量5个以内、大小10MB以内jpg/png/jpeg图片,355*185为最佳宽高.
                      </div>
                    </template>
                  </el-upload>
                  <el-dialog v-model="dialogVisible" :title="tfjname" style="width:60%; height: calc(100% - 184px);min-height: 550px; overflow: auto">
                    <div  style="height:500px;">
                      <el-image w-full :src="dialogImageUrl" alt="预览" v-if="picsee" />
                    </div>
                  </el-dialog>
                  <div style="display: flex; margin-top: 100px;" v-if="this.imgLists.length !== 0">
                    <div v-for="it in this.imgLists" :key="it.IMG_ID" style="width: 200px; height:120px; margin-left: 5px">
                      <img :src="it.RODE" :title="it.IMG_NAME+'.'+it.IMG_SUFFIX" style="width: 100%;height: 100%;cursor: pointer">
                    </div>
                  </div>

                </div>
              </el-form-item>

            </el-form>

          </el-scrollbar>
        </div>
        <div style="text-align: center;">
          <el-button type="primary" @click="toSave">保 存</el-button>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import {ElMessage} from "element-plus";
import apiURL from "../apiUrl/api.js";

export default {
  name: "systemConfiguration",
  data(){
    return{
      tableHeight: 0,
      titleData: {
        domains: [],
        imgList:[],
      },
      uploadAction: "",
      headerObj: {
        "Authorization":""
      },
      picsee: false,
      dialogVisible: false,
      dialogImageUrl:"",
      imgLists:[],
      tfjname:"",
      ifup:false,
      rules: {}
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.calcTableHeight()
      window.removeEventListener("resize", this.calcTableHeight)
      window.addEventListener("resize", this.calcTableHeight)
      this.loadData()
    })
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calcTableHeight)
  },
  methods: {
    calcTableHeight() {
      let mainHeight = window.getComputedStyle(this.$refs.mainSector.$el).height
      if (mainHeight.indexOf("px") !== -1)
        mainHeight = Number(mainHeight.substring(0, mainHeight.indexOf("px")))
      else
        mainHeight = Number(mainHeight)
      this.tableHeight = mainHeight - 130
    },
    loadData() {
      this.$axios.get("/api/sysconfiguration/loadsysconfiguration")
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.titleData.domains = data.result.contact_list
              this.imgLists = data.result.img_list
            } else {
              this.$alert(response.data.result, "查询平台信息出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "查询平台信息出错")
          })
    },
    removeDomain(item){
      const index = this.titleData.domains.indexOf(item)
      if (index !== -1) {
        this.titleData.domains.splice(index, 1)
      }
    },
    addDomain(){
      this.titleData.domains.push({
        name: '',
        address: '',
        email: '',
        phone: '',
      })
    },
    toSave(){
      this.$axios.post("/api/sysconfiguration/saveconfiguration", this.titleData)
          .then((response) => {
            if (response.data.success) {
              this.$message({message: "系统配置数据保存成功", type: "success"})
              this.dailVisi = false
              this.loadData()
            } else {
              this.$alert(response.data.result, "系统配置数据保存出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "系统配置数据保存出错")
          })
    },

    error(err){
      this.ifup = false
      this.$message({message:err, type: "false"})
    },
    //限制用户上传文件的格式和大小
    handlebe(rawFile) {
      return new Promise((resolve, reject) => {
        const filename = rawFile.name
        if (rawFile.size / 1024 / 1024 > 10) {
          ElMessage.error('文件必须小于100MB')
          this.ifup = false
          reject(false)
        }
        this.titleData.imgList.forEach(function (item) {
          if (item["name"] === filename) {
            ElMessage.error('同名文件已存在')
            this.ifup = false
            reject(false)
          }
        })
        this.uploadAction = apiURL + "/api/sysconfiguration/uploadtempfile"
        this.headerObj.Authorization = localStorage.getItem('sessionId')
        resolve()
        this.ifup = true
      })
    },
    //预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = ""
      this.picsee = false
      var suffix = file.name.substring(file.name.lastIndexOf("."))
      this.dialogVisible = true;
      this.$axios.post("/api/sysconfiguration/loadtemppic", {fname:file.name})
          .then((response) => {
            if (response.data.success) {
              if (suffix===".jpg"||suffix===".jpeg"||suffix===".png") {
                this.picsee = true
                this.dialogImageUrl = response.data.result.url
              }
              else{
                this.$alert(response.data.result, "该格式无法预览")
              }
            } else {
              this.$alert(response.data.result, "获取预览图出错")
            }
            this.tfjname = file.name
          })
          .catch((error) => {
            this.$alert(error, "获取预览图出错")
          })
    },
    //删除
    onRemove(file) {
      return new Promise((resolve, reject) => {
        this.$axios.post("/api/sysconfiguration/deltemp", {filename: file.name})
            .then((response) => {
              if (response.data.success) {
                resolve()
                let removeId = file.uid
                this.titleData.imgList.forEach((item, index) => {
                  if (item.uid === removeId) {
                    this.titleData.imgList.splice(index, 1)
                  }
                })
              } else {
                this.$alert(response.data.result, "删除出错")
                reject(false)
              }
            })
            .catch((error) => {
              this.$alert(error, "删除出错")
              reject(false)
            })
      })
    },
    uploadSuccess(){
      this.ifup = false
    },
  }
}
</script>

<style scoped>

</style>
