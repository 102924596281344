<template>
  <el-container class="base-container">
    <el-header class="base-height">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>报表查询</el-breadcrumb-item>
        <el-breadcrumb-item>现金流报表</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-container class="main-container" ref="mainSector">
      <el-main>
        <el-row style="margin-bottom: 10px">
          <el-col :span="4" class="input-search">
            <el-date-picker
                v-model="search.recordMonth"
                type="month"
                placeholder="选择年月"
                style="width: 100%"
                value-format="YYYY-MM"
                format="YYYY年MM月"
                @change="loadData()"
            />
          </el-col>
          <el-col :span="4" class="input-search">
            <el-cascader
                placeholder="请选择公司或部门"
                style="width: 100%;"
                v-model="search.organOrDep"
                :props="organprops"
                clearable
                :show-all-levels="false"
                @change="loadData($event)"
                :options="OrganTreeData" >
            </el-cascader>
          </el-col>
          <el-col :span="8">
            <el-button type="primary" @click="toPrint()"  style="margin-left: 10px;">
              <i class="iconfont icon-print" style="font-size: 15px"></i><span style="margin-right: 5px">打印</span>
            </el-button>
            <el-button type="warning" @click="doExport()" :loading="exportTip !== ''" style="margin-left: 10px;">
              <span><i class="iconfont icon-daochu" style="font-size: 15px"></i>导出</span>
            </el-button>
            <a ref="expLink" target="_blank" style="display: none;"></a>
            <span style="font-size: 12px; margin-left: 20px">{{ exportTip }}</span>
          </el-col>
          <el-col :span="8" style="text-align: right;">
            <el-tooltip effect="dark" content="刷新" placement="top">
              <el-link :underline="false" @click="toRefresh()">
                <el-icon :size="20"><Refresh /></el-icon>
              </el-link>
            </el-tooltip>
          </el-col>
        </el-row>
        <el-table
          :height="tableHeight"
          :span-method="objectSpanMethod"
          :data="cashFlowData"
          border
          :cell-style="cellStyleMethod"
        >
          <el-table-column
              prop="SUBJECT_NAME"
              label="科目名称"
              align="center"
              show-overflow-tooltip
              min-width="80px"
          >
          </el-table-column>
          <el-table-column
              prop="REV_EXP_TYPE_NAME"
              label="现金收支类别"
              align="center"
              show-overflow-tooltip
              min-width="80px"
          >
          </el-table-column>

          <el-table-column
              prop="total_rev"
              label="现金净流入"
              align="center"
              show-overflow-tooltip
              min-width="80px"
          >
            <template #default="scope">
              {{ formatNumber(scope.row.total_rev) }}
            </template>
          </el-table-column>
          <el-table-column
              prop="total_exp"
              label="现金净流出"
              align="center"
              show-overflow-tooltip
              min-width="80px"
          >
            <template #default="scope">
              {{ formatNumber(scope.row.total_exp) }}
            </template>
          </el-table-column>
          <el-table-column
              label="现金净量净额"
              align="center"
              show-overflow-tooltip
              min-width="80px"
          >
            <template #default="scope">
              {{ calculateTotal(scope.row) }}
            </template>
          </el-table-column>
        </el-table>

        <el-drawer :size="1000"  v-model="drawerVisi" :with-header="false">
          <div style="width:100%; height: calc(100% - 10px); ">
            <iframe :src="pdffile" id="fra" style="width: 100%;height: 100%;"></iframe>
          </div>
        </el-drawer>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import apiURL from "@/components/apiUrl/api";

export default {
  name: "CashFlowReport",
  data(){
    return{
      tableHeight: 0,
      search:{
        recordMonth:"",
        organOrDep:"",
        s_organid:"",
        s_depid:"",
      },
      OrganTreeData:[],
      organprops:{
        label:'text',value:'value',children:'children',
        checkStrictly: true,
        emitPath:false,
      },
      exportTip: "",
      cashFlowData:[],
      pdffile:"",
      drawerVisi:false,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.calcTableHeight()
      window.removeEventListener("resize", this.calcTableHeight)
      window.addEventListener("resize", this.calcTableHeight)
      this.search.recordMonth = this.current("month")
      this.search.organOrDep = "company-"+sessionStorage.getItem("organ-organid")
      this.loadTree()
      this.loadData()
    })
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calcTableHeight)
  },
  methods: {
    calcTableHeight() {
      let mainHeight = window.getComputedStyle(this.$refs.mainSector.$el).height
      if (mainHeight.indexOf("px") !== -1)
        mainHeight = Number(mainHeight.substring(0, mainHeight.indexOf("px")))
      else
        mainHeight = Number(mainHeight)
      this.tableHeight = mainHeight - 90
    },
    current(d) {
      var nowDate = new Date();
      var date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        day: nowDate.getDate()
      };
      if (d === "month")
        return date.year + "-" + (date.month >= 10 ? date.month : "0" + date.month);
      else if (d === "day")
        return date.year + "-" + (date.month >= 10 ? date.month : "0" + date.month) + "-" + (date.day >= 10 ? date.day : "0" + date.day);
    },
    toRefresh() {
      this.loadData("refresh")
    },
    calculateTotal(row) {
      return this.formatNumber((Number(row.total_rev) - Number(row.total_exp)).toFixed(2).replace(/\.00$/,''));
    },
    formatNumber(value) {
      // 使用 Intl.NumberFormat 来将数值格式化为千分位显示
      return new Intl.NumberFormat().format(value);
    },
    loadTree(){
      this.$axios.get("/api/common/getqueryorgantree")
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.OrganTreeData = data.result
            } else {
              this.$alert(response.data.result, "获取组织列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取组织列表出错")
          })
    },
    loadData(d) {
      if(this.search.organOrDep !== "" && this.search.organOrDep !== null){
        const parts = this.search.organOrDep.toString().split('-');
        if(parts[0] === "company"){
          this.search.s_organid = parts[1]
          this.search.s_depid = ""
        }else{
          this.search.s_organid = parts[0]
          this.search.s_depid = parts[1]
        }
      }else{
        this.search.s_organid = ""
        this.search.s_depid = ""
      }
      this.$axios.post("/api/report/loadcashflowdata", this.search)
          .then((response) => {
            if (response.data.success) {
              this.cashFlowData = response.data.result
              this.formatData(this.cashFlowData)
              if (d === "refresh") {
                this.$message({message: "刷新成功", type: "success"})
              }
            } else {
              this.$alert(response.data.result, "获取收入数据出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取收入数据出错")
          })
    },
    cellStyleMethod({ row, columnIndex }) {
      if (row.SUBJECT_SORT === '小计' || row.SUBJECT_SORT === '合计') {
        return { 'text-align': 'right', 'font-weight': '600', 'background':'#f0f9eb' };
      }
      if(columnIndex === 0 ){
        return { 'background':'#f5f7fa'};
      }
    },

    formatData(info) {
      let data = JSON.parse(JSON.stringify(info));
      let temp = {};
      let pos = 0
      for (let i = 0; i < data.length; i++) {
        if(data[i].total_rev == null)
          data[i].total_rev = 0
        if(data[i].total_exp == null)
          data[i].total_exp = 0
        if (temp[data[i].SUBJECT_SORT]) {
          temp[data[i].SUBJECT_SORT]['total_rev'] += parseFloat(data[i].total_rev);
          temp[data[i].SUBJECT_SORT]['total_exp'] += parseFloat(data[i].total_exp);
        } else {
          temp[data[i].SUBJECT_SORT] = {};
          temp[data[i].SUBJECT_SORT]['total_rev'] = parseFloat(data[i].total_rev);
          temp[data[i].SUBJECT_SORT]['total_exp'] = parseFloat(data[i].total_exp);
        }
        if (i > 0 && data[i].SUBJECT_SORT !== data[i - 1].SUBJECT_SORT) {
          info.splice(i+pos, 0, {
            SUBJECT_SORT: '小计',
            SUBJECT_NAME: data[i - 1].SUBJECT_NAME+'小计',
            total_rev: temp[data[i - 1].SUBJECT_SORT]['total_rev'].toFixed(2).replace(/\.00$/,''),
            total_exp: temp[data[i - 1].SUBJECT_SORT]['total_exp'].toFixed(2).replace(/\.00$/,''),
          });
          if(i === data.length - 1){
            info.push({
              SUBJECT_SORT: '小计',
              SUBJECT_NAME: data[i - 1].SUBJECT_NAME+'小计',
              total_rev: temp[data[i].SUBJECT_SORT]['total_rev'].toFixed(2).replace(/\.00$/,''),
              total_exp: temp[data[i].SUBJECT_SORT]['total_exp'].toFixed(2).replace(/\.00$/,''),
            });
          }
          pos++
        }else if (i === data.length - 1) {
          if(i === 0){
            info.push({
              SUBJECT_SORT: '小计',
              SUBJECT_NAME: data[i].SUBJECT_NAME+'小计',
              total_rev: temp[data[i].SUBJECT_SORT]['total_rev'].toFixed(2).replace(/\.00$/,''),
              total_exp: temp[data[i].SUBJECT_SORT]['total_exp'].toFixed(2).replace(/\.00$/,''),
            });
          }else{
            info.push({
              SUBJECT_SORT: '小计',
              SUBJECT_NAME: data[i - 1].SUBJECT_NAME+'小计',
              total_rev: temp[data[i - 1].SUBJECT_SORT]['total_rev'].toFixed(2).replace(/\.00$/,''),
              total_exp: temp[data[i - 1].SUBJECT_SORT]['total_exp'].toFixed(2).replace(/\.00$/,''),
            });
          }
          pos++
        }
      }
      let totalAmount2 = 0;
      let totalAmount3 = 0;
      for (let key in data) {
        totalAmount2 += parseFloat(data[key].total_rev);
        totalAmount3 += parseFloat(data[key].total_exp);
      }
      info.push({
        SUBJECT_SORT: '合计',
        SUBJECT_NAME: '现金流量合计',
        total_rev: totalAmount2.toFixed(2).replace(/\.00$/,''),
        total_exp: totalAmount3.toFixed(2).replace(/\.00$/,''),
      });
    },

    //导出
    doExport () {
      this.exportTip = "现金流报表导出Excel中..."
      this.$axios.post("/api/report/exportcashflowdata", {data: this.cashFlowData, recordMonth:this.search.recordMonth}, {
        responseType: "blob"
      })
          .then((response) => {
            setTimeout(this.exportTip = "", 1000)
            const err = response.headers["error-content"]
            if (typeof err === "undefined") {
              const blobUrl = window.URL.createObjectURL(new Blob([response.data]))
              let fname = response.headers["content-disposition"].split("filename=")[1]
              fname = decodeURIComponent(fname)
              const a = this.$refs.expLink
              a.href = blobUrl
              a.setAttribute("download", fname)
              a.click()
            }
            else
              this.$alert(decodeURIComponent(err), "现金流报表导出出错")
          })
          .catch((error) => {
            this.exportTip = ""
            this.$alert(error, "现金流报表导出出错")
          })

    },

    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      console.log(row, column, rowIndex, columnIndex);
      if (row.SUBJECT_SORT === '小计' || row.SUBJECT_SORT === '合计') {
        if (columnIndex === 0) {
          return [1, 2];
        } else if (columnIndex === 1) {
          return [0, 0];
        }
      } else {
        if (columnIndex === 0) {
          let spanArr = this.getSpanArr(this.cashFlowData)
          const _row = spanArr[rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col,
          }
        }
      }
    },
    getSpanArr(arr) {
      if (arr) {
        const spanOneArr = []
        let concatOne = 0
        arr.forEach((item, index) => {
          if (index === 0) {
            spanOneArr.push(1)
          } else {
            if (item.SUBJECT_NAME=== arr[index - 1].SUBJECT_NAME) {
              spanOneArr[concatOne] += 1
              spanOneArr.push(0)
            } else {
              spanOneArr.push(1)
              concatOne = index
            }
          }
        })
        return spanOneArr
      }
    },
    toPrint (){
      this.drawerVisi = true
      this.pdffile = ""
      if(this.search.organOrDep !== "" && this.search.organOrDep !== null){
        const parts = this.search.organOrDep.toString().split('-');
        if(parts[0] === "company"){
          this.search.s_organid = parts[1]
          this.search.s_depid = ""
        }else{
          this.search.s_organid = parts[0]
          this.search.s_depid = parts[1]
        }
      }else{
        this.search.s_organid = ""
        this.search.s_depid = ""
      }
      this.pdffile = apiURL+"/api/report/printcashflowdata?recordMonth="+this.search.recordMonth +"&s_organid="+this.search.s_organid +
          "&s_depid="+this.search.s_depid
    },
  }
}
</script>

<style scoped>

</style>
