<template>
  <div v-if="isLoading" class="loading-overlay">
    <div class="loading-content">
      <p>Loading...</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingPage",
  data() {
    return {
      isLoading: false
    };
  },
  methods:{
    show(){
      this.isLoading = true
    },
    hide(){
      this.isLoading = false
    },
  }
}
</script>

<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-content {
  color: white;
  /* 样式自定义 */
}
</style>
