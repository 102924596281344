<template>
  <el-container class="base-container">
    <el-header class="base-height">
      <div style="color: #fff; font-size: 48px; height: 100%; letter-spacing:10px;">
        <div style="margin-left: 15%; margin-top: 10px; font-family: ysz;">专注盈利管理</div>
        <div style="margin-left: 55%; margin-top: -30px; font-family: ysz;" >提升管理效率</div>
      </div>
    </el-header>
    <el-container class="main-container">
      <el-main>
        <div class="div-main">
          <div class="div-left" >
            <div class="div-year">
              <el-date-picker
                  v-model="search.recordMonth"
                  type="year"
                  value-format="YYYY"
                  format="YYYY年"
                  @change="loadStatistic"
              />
            </div>
            <div style="width: 100%; height: 100%" ref="moneyChart"></div>
          </div>
<!--          <div class="div-center">-->
<!--            <div style="width: 100%; height: 100%" ref="userChart"></div>-->
<!--          </div>-->
          <div class="div-right">
            <div style="margin-bottom: 10px;"><span style="font-weight: 550; font-size: 16px">{{ this.search.recordMonth }}年损益表</span></div>
            <el-table
                border
                :data="lossmoney"
                :header-cell-style="{background:'#f5f7fa'}"
                :cell-style="cellStyleMethod">
              <el-table-column
                  prop="month"
                  label="月份"
                  show-overflow-tooltip
                  align="center"
                  min-width="60px"
              >
                <template #default="scope">
                  {{ scope.row.month }}月份
                </template>
              </el-table-column>
              <el-table-column
                  prop="in_money"
                  label="收入金额(元)"
                  align="center"
                  show-overflow-tooltip
                  min-width="80px"
              >
                <template #default="scope">
                  {{ calculate(scope.row.in_money) }}
                </template>
              </el-table-column>
              <el-table-column
                  prop="out_money"
                  label="支出金额(元)"
                  show-overflow-tooltip
                  align="center"
                  min-width="80px"
              >
                <template #default="scope">
                  {{ calculate(scope.row.out_money) }}
                </template>
              </el-table-column>
              <el-table-column
                  prop="bal_money"
                  label="期末盈亏金额(元)"
                  show-overflow-tooltip
                  align="center"
                  min-width="100px"
              >
                <template #default="scope">
                  {{ calculate((scope.row.in_money - scope.row.out_money).toFixed(2)) }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>

import * as Echarts from "echarts";

export default {
  name: "HomePage.vue",
  data () {
    return {
      search:{
        recordMonth: "",
      },
      moneyInfo:[],
      staffInfo:[],
      lossmoney:[],
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.search.recordMonth = this.current()
      this.loadStatistic()
    })
  },
  methods: {
    calculate(data) {
      if(data.toString() === '0' || data.toString() === '0.00')
        return ''
      else
        return data
    },
    current() {
      const nowDate = new Date();
      const date = {
        year: nowDate.getFullYear(),
      };
      return date.year.toString() ;
    },
    loadStatistic() {
      this.$axios.post("/api/login/gethomeinfo", this.search)
        .then((response) => {
          if (response.data.success) {
            let data = response.data.result
            this.moneyInfo = data.moneyInfo
            this.staffInfo = data.staffInfo
            this.lossmoney = data.lossmoney
            setTimeout(this.showChart, 1000)
          }else {
            this.$alert(response.data.result, "获取首页统计信息出错")
          }
        })
        .catch((error) => {
          this.$alert(error, "获取首页统计信息出错")
        })
    },
    showChart () {
      if (typeof this.moneyInfo !== "undefined") {
        let moneyChart = Echarts.init(this.$refs.moneyChart)
        moneyChart.setOption({
          title: {
            text:  this.search.recordMonth+'年资金收支及盈亏平衡表'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            data: ['收入', '支出', '差额']
          },
          xAxis: {
            axisLabel: { interval: 0 },
            data: ['一月', '\n二月', '三月', '\n四月', '五月', '\n六月', '七月', '\n八月', '九月', '\n十月', '十一月', '\n十二月']
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              name: '收入',
              type: 'bar',
              itemStyle: {color: '#32CD32'},
              data: [
                this.moneyInfo[0].in_money,
                this.moneyInfo[1].in_money,
                this.moneyInfo[2].in_money,
                this.moneyInfo[3].in_money,
                this.moneyInfo[4].in_money,
                this.moneyInfo[5].in_money,
                this.moneyInfo[6].in_money,
                this.moneyInfo[7].in_money,
                this.moneyInfo[8].in_money,
                this.moneyInfo[9].in_money,
                this.moneyInfo[10].in_money,
                this.moneyInfo[11].in_money,
              ]
            },
            {
              name: '支出',
              type: 'bar',
              itemStyle: {color: '#E74C3C'},
              data: [
                this.moneyInfo[0].out_money,
                this.moneyInfo[1].out_money,
                this.moneyInfo[2].out_money,
                this.moneyInfo[3].out_money,
                this.moneyInfo[4].out_money,
                this.moneyInfo[5].out_money,
                this.moneyInfo[6].out_money,
                this.moneyInfo[7].out_money,
                this.moneyInfo[8].out_money,
                this.moneyInfo[9].out_money,
                this.moneyInfo[10].out_money,
                this.moneyInfo[11].out_money,
              ]
            },
            {
              name: '差额',
              type: 'line',
              data: [
                this.moneyInfo[0].sur_money,
                this.moneyInfo[1].sur_money,
                this.moneyInfo[2].sur_money,
                this.moneyInfo[3].sur_money,
                this.moneyInfo[4].sur_money,
                this.moneyInfo[5].sur_money,
                this.moneyInfo[6].sur_money,
                this.moneyInfo[7].sur_money,
                this.moneyInfo[8].sur_money,
                this.moneyInfo[9].sur_money,
                this.moneyInfo[10].sur_money,
                this.moneyInfo[11].sur_money,
              ]
            },
          ]
        })
      }

      // if (typeof this.staffInfo !== "undefined") {
      //   const dataSet = [];
      //   let json = {};
      //   this.staffInfo.forEach(item => {
      //     json = {value: item.num, name: item.DEPARTMENT_NAME + item.num+'人'};
      //     dataSet.push(json);
      //   })
      //
      //   let userChart = Echarts.init(this.$refs.userChart)
      //   userChart.setOption({
      //     title: {
      //       text: '员工统计情况',
      //       left: 'center'
      //     },
      //     tooltip: {
      //       trigger: 'item',
      //       formatter: '{a} <br/>{b}'
      //     },
      //     legend: {
      //       top: '10%',
      //       orient: 'vertical',
      //       left: 'right'
      //     },
      //     series: [
      //       {
      //         name: '员工统计',
      //         type: 'pie',
      //         radius: ['30%', '50%'],
      //         center: ['35%', '50%'],
      //         avoidLabelOverlap: false,
      //         itemStyle: {
      //           borderRadius: 10,
      //           borderColor: '#fff',
      //           borderWidth: 2
      //         },
      //         label: {
      //           show: false,
      //           position: 'center'
      //         },
      //         emphasis: {
      //           label: {
      //             show: true,
      //             fontSize: 15,
      //             fontWeight: 'bold'
      //           }
      //         },
      //         labelLine: {
      //           show: false
      //         },
      //         data: dataSet,
      //       }
      //     ]
      //   })
      // }

    },
    cellStyleMethod({ columnIndex }) {
      if(columnIndex === 0){
        return { 'background':'#f5f7fa'};
      }else if (columnIndex === 1) {
        return { 'background':'#ebf6f9' };
      }else if (columnIndex === 2) {
        return { 'background':'#f0f9eb' };
      }else if (columnIndex === 3) {
        return { 'background':'#f9f7eb' };
      }
      else if (columnIndex === 4) {
        return { 'background':'#faf0e5' };
      }
    },
  }
}
</script>

<style scoped>
.base-height {
  width: 100%;
  height: 100px;
  min-width: 1000px;
  background: url("../assets/img/bt.jpeg") fixed no-repeat;
  background-size: cover;
}

.div-main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}
.div-year{
  height: 50px;
}
.div-left {
  width: 60%;
  height: calc(100% - 50px);
  margin: 0 10px;
}
.div-center{
  width: 20%;
  height: 50%;
}
.div-right {
  width: 40%;
  height: 100%;
  margin: 0 10px;
}
</style>
