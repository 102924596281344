<!--
主界面
最后编辑人：ls
最后编辑时间：2023-1-10
最后编辑细节：
-->
<template>
  <el-container style="width: 100%; height: 100%;">
    <el-container style="height: calc(100%);">
      <!-- <el-aside :width="menuWidth" class="main-nav back">
        <div style="text-align: right">
          <el-button size="default" type="text" @click="collapseMenu" class="icon-button">
            <div v-if="menuCollapse"><el-icon :size="26"><Expand /></el-icon></div>
            <div v-else><el-icon :size="26"><Fold /></el-icon></div>
          </el-button>
        </div>
        <el-scrollbar style="height: calc(100% - 40px);">
          <el-menu
              :default-active="$route.path"
              router
              :collapse="menuCollapse"
              :unique-opened="true"
              background-color="#455a64"
              text-color="#FFFFFF"
              active-text-color="#ffd04b"
              style="border-right: none;"
          >
            <el-sub-menu index="organ">
              <template #title>
                <i class="iconfont icon-large icon-xitongquanxian"></i>
                <span class="menu-fone">组织权限</span>
              </template>
              <el-menu-item index="/organ/organmge"><i class="iconfont icon-small icon-zuzhiguanli"></i>网格小区</el-menu-item>
              <el-menu-item index="/organ/rolemge"><i class="iconfont icon-small icon-jiaoseguanli1"></i>楼层</el-menu-item>
              <el-menu-item index="/organ/usermge"><i class="iconfont icon-small icon-jiaoseguanli2"></i>住户</el-menu-item>
              <el-menu-item index="/organ/personnelInfo"><i class="iconfont icon-small icon-jiaoseguanli2"></i>详细人员</el-menu-item>
            </el-sub-menu>
          </el-menu>
        </el-scrollbar>
      </el-aside> -->

      <el-main id="maincontainer">
        <router-view v-slot="{ Component }">
          <component ref="child" :is="Component" />
        </router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>

import {ElNotification} from "element-plus";

export default {
  name: "MainPage",
  data () {
    let validConfPwd = (rule, value, callback) => {
      if (this.pwdData.newpwd !== value)
        callback(new Error("新密码与确认密码不一致"))
      else
        callback()
    }
    return {
      pwdChangeVisi: false,
      menuWidth: "240px",
      menuCollapse: false,
      userinfo: {
        account: "",
        urname: "",
        urrole:"",
        orname: "",
        urls: [],
        organname:"",
        departmentname:"",
      },
      pwdData: {
        oldpwd: "",
        newpwd: "",
        confpwd: "",
      },
      pwdrules: {
        oldpwd: [{ required: true, message: '请输入原密码', trigger: 'blur'}],
        newpwd: [{ required: true, message: '请输入新密码', trigger: 'blur'}, {pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\s\S]{8,16}$/, message: "登录密码必须包含大写字母、小写字母、数字，长度8-16个字符", trigger: "blur"}],
        confpwd: [{ required: true, message: '请输入确认密码', trigger: 'blur' }, { validator: validConfPwd, trigger: 'blur' }]
      },

    }
  },
  mounted() {
    // const {path} = this.$route
    // if (path.toLowerCase() === "/mainpage"){
    //   this.$router.replace({name: "home-page"})
    // }
    // this.loadUserInfo()
  },
  methods: {
    collapseMenu () {
      if (this.menuCollapse) {
        this.menuWidth = "240px"
        this.menuCollapse = false
      }
      else {
        this.menuWidth = "60px"
        this.menuCollapse = true
      }
    },
    showChangePwd () {
      this.pwdData = {
        oldpwd: "",
        newpwd: "",
        confpwd: ""
      }
      this.pwdChangeVisi = true
      if (this.$refs.pwdForm)
        this.$refs.pwdForm.clearValidate()
    },
    doChangePwd () {
      this.$refs.pwdForm.validate((valid) => {
        if (valid) {
          this.$axios.post("/api/login/changepwd", this.pwdData)
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "修改成功", type: "success"})
                  this.pwdChangeVisi = false
                } else {
                  this.$alert(response.data.result, "修改密码出错")
                }
              })
              .catch((error) => {
                this.$alert(error, "修改密码出错")
              })
        }
      })
    },
    exit () {
      ElNotification.closeAll()
      setTimeout(()=>{   //设置延迟执行
        this.$axios.get("/api/login/dologout")
        sessionStorage.removeItem("organ-account")
        sessionStorage.removeItem("organ-name")
        sessionStorage.removeItem("organ-role")
        sessionStorage.removeItem("organ-role-name")
        sessionStorage.removeItem("organ-cellphone")
        sessionStorage.removeItem("organ-organname")
        sessionStorage.removeItem("organ-departmentname")
        this.$router.replace({name: "login-page"})
        localStorage.removeItem('sessionId')
      },1000);
    },

  },
}
</script>

<style scoped>
.main-header {
  position: relative;
  padding-top: 3px;
  padding-bottom: 2px;
  background-color: #455a64;
  color: #ffffff;
  overflow: hidden;
}

.header-title {
  line-height: 50px;
  max-height: 60px;
  letter-spacing: 7px;
  text-shadow: #2c80c5 0 0 3px;
  color: #e6fffd;
  font-size: 30px;
  font-family: "PingFang SC", sans-serif;
}

.user-block {
  max-height: 60px;
  padding-right: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.avatar_div {
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.avatar_div:hover {
  cursor: pointer;
}
.icon-avatar{
  font-size: 38px;
  color: #fff;
  margin-right: 10px;
}
.main-nav {
  background-color: #455a64;
  transition: 600ms;
}

.menu-fone{
  font-size: 16px;
  margin-left: 10px;
}
.icon-button {
  width: 60px;
  color: white;
  transition: all 0.3s;
}
.icon-button:hover {
  color: white;
  transform: scaleX(1.2) scaleY(1.2) translateZ(0);
}
.menu-item{
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
