<template>
  <el-container class="base-container">
    <el-header class="base-height">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>资产管理</el-breadcrumb-item>
        <el-breadcrumb-item>出入库管理</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-container class="main-container" ref="mainSector">
      <el-main style="height: 100%; border-left: 10px solid #f5f5f5; overflow:hidden;">
        <el-row style="margin-bottom: 10px">
          <el-col :span="4" class="input-search">
            <el-date-picker
                v-model="search.recordMonth"
                type="month"
                placeholder="选择年月"
                style="width: 100%"
                value-format="YYYY-MM"
                format="YYYY-MM"
                @change="loadAssetStorageData"
            />
          </el-col>
          <el-col :span="4" class="input-search">
            <el-input v-model="search.goodsName" placeholder="请输入商品名称" @keyup.enter="loadAssetStorageData" @clear="loadAssetStorageData" clearable size="default"></el-input>
          </el-col>
          <el-col :span="3">
            <el-button
                type="primary"
                size="default"
                @click="loadAssetStorageData">查询</el-button>
            <el-button
                size="default"
                @click="toReset">重置</el-button>
          </el-col>
          <el-col :span="8">
            <el-button type="success" @click="doInStorage()" style="margin-left: 10px;">
              <span><i class="iconfont icon-ruku" style="font-size: 15px"></i>物资入库</span>
            </el-button>
            <el-button type="warning" @click="doOutStorage()" style="margin-left: 10px;">
              <span><i class="iconfont icon-chuku" style="font-size: 15px"></i>物资出库</span>
            </el-button>
          </el-col>
          <el-col :span="5" style="text-align: right;">
            <el-tooltip effect="dark" content="刷新" placement="top">
              <el-link :underline="false" @click="toRefresh()">
                <el-icon :size="20"><Refresh /></el-icon>
              </el-link>
            </el-tooltip>
          </el-col>
        </el-row>

        <el-tabs type="border-card">
          <el-tab-pane>
            <template #label>
              <span class="custom-tabs-label">
                <i class="iconfont icon-rukuguanli" style="font-size: 22px;"></i>
                <span>入库</span>
              </span>
            </template>
            <el-table
                :height="tableHeight"
                :data="InStorageList"
                @row-click="doInStorage">
              <el-table-column
                  prop="RECORD_TIME"
                  label="日期"
                  show-overflow-tooltip
                  align="center"
                  min-width="80px"
              >
              </el-table-column>
              <el-table-column
                  prop="GOODS_TYPE_NAME"
                  label="商品分类"
                  align="center"
                  show-overflow-tooltip
                  min-width="80px"
              >
              </el-table-column>
              <el-table-column
                  prop="SPECS_NAME"
                  label="规格名称"
                  align="center"
                  show-overflow-tooltip
                  min-width="100px"
              >
              </el-table-column>
              <el-table-column
                  label="商品名称"
                  min-width="80"
                  show-overflow-tooltip
                  align="center">
                <template #default="scope">
                  <el-button type="text" size="default" >{{ scope.row.GOODS_NAME }}</el-button>
                </template>
              </el-table-column>
              <el-table-column
                  prop="UNIT_NAME"
                  label="单位"
                  show-overflow-tooltip
                  align="center"
                  min-width="80px"
              >
              </el-table-column>
              <el-table-column
                  prop="IN_STORAGE_NUM"
                  label="入库数量"
                  show-overflow-tooltip
                  align="center"
                  min-width="80px"
              >
              </el-table-column>
              <el-table-column
                  prop="IN_STORAGE_UNIT"
                  label="入库单价"
                  show-overflow-tooltip
                  align="center"
                  min-width="70px"
              >
              </el-table-column>
              <el-table-column
                  prop="IN_STORAGE_MONEY"
                  label="入库金额"
                  show-overflow-tooltip
                  align="center"
                  min-width="70px"
              >
              </el-table-column>

            </el-table>
            <el-pagination
                background
                v-model:current-page="search.page"
                v-model:page-size="search.pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                @current-change="loadAssetStorageData"
                @size-change="loadAssetStorageData"
                :page-sizes="[10, 50, 100, 200]"
                :total="total"
                style="margin-top: 10px;"
            >
            </el-pagination>
          </el-tab-pane>
          <el-tab-pane>
            <template #label>
              <span class="custom-tabs-label">
                <i class="iconfont icon-chukuguanli" style="font-size: 22px;"></i>
                <span>出库</span>
              </span>
            </template>
            <el-table
                :height="tableHeight"
                :data="OutStorageList"
                @row-click="doOutStorage">
              <el-table-column
                  prop="RECORD_TIME"
                  label="日期"
                  show-overflow-tooltip
                  align="center"
                  min-width="80px"
              >
              </el-table-column>
              <el-table-column
                  prop="GOODS_TYPE_NAME"
                  label="商品分类"
                  align="center"
                  show-overflow-tooltip
                  min-width="80px"
              >
              </el-table-column>
              <el-table-column
                  prop="SPECS_NAME"
                  label="规格名称"
                  align="center"
                  show-overflow-tooltip
                  min-width="100px"
              >
              </el-table-column>
              <el-table-column
                  label="商品名称"
                  min-width="80"
                  show-overflow-tooltip
                  align="center">
                <template #default="scope">
                  <el-button type="text" size="default" >{{ scope.row.GOODS_NAME }}</el-button>
                </template>
              </el-table-column>
              <el-table-column
                  prop="UNIT_NAME"
                  label="计量单位"
                  show-overflow-tooltip
                  align="center"
                  min-width="80px"
              >
              </el-table-column>
              <el-table-column
                  prop="moveUnit"
                  label="移动单价"
                  show-overflow-tooltip
                  align="center"
                  min-width="70px"
              >
              </el-table-column>
              <el-table-column
                  label="实时库存"
                  min-width="80"
                  show-overflow-tooltip
                  align="center">
                <template #default="scope">
                  <el-button type="text" size="default" >{{ scope.row.realTimeNum }}</el-button>
                </template>
              </el-table-column>
              <el-table-column
                  prop="balanceMoney"
                  label="结余金额"
                  show-overflow-tooltip
                  align="center"
                  min-width="70px"
              >
              </el-table-column>
            </el-table>
            <el-pagination
                background
                v-model:current-page="search.page"
                v-model:page-size="search.pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                @current-change="loadAssetStorageData"
                @size-change="loadAssetStorageData"
                :page-sizes="[10, 50, 100, 200]"
                :total="total1"
                style="margin-top: 10px;"
            >
            </el-pagination>
          </el-tab-pane>
        </el-tabs>
      </el-main>

      <el-dialog
          width="1000px"
          :title=Title
          v-model="dailVisi"
          :close-on-click-modal="false"
          :close-on-press-escape="false">

        <div style="height: 400px; overflow: auto;">
          <el-form
              :inline="true"
              :model="voucherData"
              :rules="in_rules"
              ref="voucherDataForm"
              label-width="130px"
          >
            <el-form-item label="业务类型(选填)" prop="businessType" style="width: calc(70% - 85px);">
              <el-cascader
                  placeholder="请选择科目"
                  style="width: 100%;"
                  v-model="businessType"
                  :props="props"
                  clearable
                  @change="getBusinessData"
                  :options="businessTree" >
              </el-cascader>
            </el-form-item>
            <el-form-item label="摘要:" prop="abstract" style="width: calc(70% - 85px);">
              <el-input v-model="voucherData.abstract" />
            </el-form-item>
            <el-form-item label="会计科目" prop="subjectSort" class="form-col">
              <el-cascader
                  placeholder="请选择科目"
                  style="width: 100%;"
                  v-model="voucherData.subjectSort"
                  :props="props"
                  clearable
                  @change="getSubjectName()"
                  :options="subjectData" >
              </el-cascader>
            </el-form-item>
            <el-form-item label="科目明细:" prop="subjectDetail" class="form-col">
              <el-select v-model="voucherData.subjectDetail" :placeholder="pla_subjectDetail" style="width: 100%;">
                <template #header>
                  <el-button type="success" @click="toDetail('1')" style="width: 100%" size="small">
                    <el-icon><Plus /></el-icon><span class="icon-right">添加明细</span>
                  </el-button>
                </template>
                <el-option
                    v-for="item in backType"
                    :key="item.SUBJECT_DETAIL"
                    :label="item.SUBJECT_DETAIL"
                    :value="item.SUBJECT_DETAIL">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="收支类别:" prop="revExpType" class="form-col">
              <el-cascader
                  placeholder="请选择收支类别"
                  style="width: 100%;"
                  v-model="voucherData.revExpType"
                  :props="props"
                  clearable
                  @change="getSubjectName()"
                  :options="subjectAllTree" >
              </el-cascader>
            </el-form-item>
            <el-form-item label="收支明细:" prop="revExpDetail" class="form-col">
              <el-select v-model="voucherData.revExpDetail" :placeholder="pla_revExpDetail" style="width: 100%;">
                <template #header>
                  <el-button type="success" @click="toDetail('2')" style="width: 100%" size="small">
                    <el-icon><Plus /></el-icon><span class="icon-right">添加明细</span>
                  </el-button>
                </template>
                <el-option
                    v-for="item in paymentType"
                    :key="item.SUBJECT_DETAIL"
                    :label="item.SUBJECT_DETAIL"
                    :value="item.SUBJECT_DETAIL">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="收入金额:" prop="revenueMoney" class="form-col">
              <el-input v-model="voucherData.revenueMoney"
                        :placeholder="pla_revenueMoney"
                        :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                        :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')">
                <template #append>元</template>
              </el-input>
            </el-form-item>
            <el-form-item label="支出金额:" prop="expenditureMoney" class="form-col" >
              <el-input v-model="voucherData.expenditureMoney"
                        :placeholder="pla_expenditureMoney"
                        :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                        :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')">
                <template #append>元</template>
              </el-input>
            </el-form-item>
            <el-form-item label="税率:" prop="taxRate"   class="form-col" >
              <el-select v-model="voucherData.taxRate" clearable :placeholder="pla_taxRate"  style="width: 100%;">
                <el-option value="" label="无"></el-option>
                <el-option
                    v-for="item in taxRate"
                    :key="item.CODE_NAME"
                    :label="item.CODE_NAME+'%'"
                    :value="item.CODE_NAME">
                </el-option>
                <template #tag>%</template>
              </el-select>
            </el-form-item>
          </el-form>
          <div v-if="showInStorage">
            <el-divider style="margin: 15px 0;">入库</el-divider>
            <el-form
                :inline="true"
                :model="InStorageData"
                :rules="rules"
                ref="inStorageDataForm"
                label-width="130px"
            >
              <el-form-item label="物资分类" prop="goodsType" class="form-col">
                <el-cascader
                    placeholder="请选择物资分类"
                    style="width: 100%;"
                    v-model="InStorageData.goodsType"
                    :props="props"
                    clearable
                    :options="subjectData" >
                </el-cascader>
              </el-form-item>
              <el-form-item label="规格名称:" prop="specsName" class="form-col">
                <el-select v-model="InStorageData.specsName" placeholder="请选择规格名称">
                  <el-option
                      v-for="item in SpecsType"
                      :key="item.CODE"
                      :label="item.CODE_NAME"
                      :value="item.CODE">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="商品名称:" prop="goodsName" class="form-col">
                <el-input v-model="InStorageData.goodsName" />
              </el-form-item>
              <el-form-item label="单位:" prop="unit" class="form-col" >
                <el-select v-model="InStorageData.unit" placeholder="请选择单位">
                  <el-option
                      v-for="item in UnitData"
                      :key="item.CODE"
                      :label="item.CODE_NAME"
                      :value="item.CODE">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="入库数量:" prop="number" class="form-col">
                <el-input v-model="InStorageData.number" @blur="getMoney()"/>
              </el-form-item>
              <el-form-item label="入库单价:" prop="unitPrice" class="form-col" >
                <el-input v-model="InStorageData.unitPrice" @blur="getMoney()">
                  <template #append>元</template>
                </el-input>
              </el-form-item>
              <el-form-item label="入库金额:" prop="money" class="form-col" >
                <el-input v-model="InStorageData.money"
                          :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                          :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')">
                  <template #append>元</template>
                </el-input>
              </el-form-item>
              <el-form-item label="记录时间:" prop="enableTime" class="form-col" >
                <el-date-picker
                    v-model="InStorageData.enableTime"
                    type="date"
                    value-format="YYYY-MM-DD"
                    placeholder="选择记录时间"
                    style="width: 100%"
                />
              </el-form-item>
            </el-form>
            <div class="div-button">
              <el-button type="primary" @click="toSaveInStorage">保 存</el-button>
            </div>
          </div>
          <div v-if="showOutStorage">
            <el-divider style="margin: 15px 0;">出库</el-divider>
            <el-form
                :inline="true"
                :model="OutStorageData"
                :rules="rules1"
                ref="OutStorageDataForm"
                label-width="130px"
            >
              <el-form-item label="物资分类" prop="goodsType" class="form-col">
                <el-cascader
                    placeholder="请选择物资分类"
                    style="width: 100%;"
                    v-model="OutStorageData.goodsType"
                    :props="props"
                    clearable
                    :options="subjectData"
                    disabled>
                </el-cascader>
              </el-form-item>
              <el-form-item label="规格名称:" prop="specsName" class="form-col">
                <el-select v-model="OutStorageData.specsName" placeholder="请选择规格名称" disabled>
                  <el-option
                      v-for="item in SpecsType"
                      :key="item.CODE"
                      :label="item.CODE_NAME"
                      :value="item.CODE">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="商品名称:" prop="goodsName" class="form-col">
                <el-cascader
                    placeholder="请选择商品名称"
                    style="width: 100%;"
                    v-model="OutStorageData.goodsName"
                    :props="props"
                    clearable
                    :options="GoodsList"
                    @change="showOutData"
                    :disabled="disabled">
                </el-cascader>
              </el-form-item>
              <el-form-item label="单位:" prop="unit" class="form-col" >
                <el-select v-model="OutStorageData.unit" placeholder="请选择单位" disabled>
                  <el-option
                      v-for="item in UnitData"
                      :key="item.CODE"
                      :label="item.CODE_NAME"
                      :value="item.CODE">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="实时库存:" prop="realTimeNum" class="form-col">
                <el-input v-model="OutStorageData.realTimeNum" disabled/>
              </el-form-item>
              <el-form-item label="移动单价:" prop="moveUnit" class="form-col" >
                <el-input v-model="OutStorageData.moveUnit" disabled>
                  <template #append>元</template>
                </el-input>
              </el-form-item>
              <el-form-item label="结余金额:" prop="balanceMoney" class="form-col" >
                <el-input v-model="OutStorageData.balanceMoney"
                          :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                          :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')" disabled>
                  <template #append>元</template>
                </el-input>
              </el-form-item>
              <el-form-item label="出库数量:" prop="number" class="form-col">
                <el-input v-model="OutStorageData.number"  @blur="getMoney1()"/>
              </el-form-item>
              <el-form-item label="出库单价:" prop="unitPrice" class="form-col" >
                <el-input v-model="OutStorageData.unitPrice" @blur="getMoney1()">
                  <template #append>元</template>
                </el-input>
              </el-form-item>
              <el-form-item label="出库金额:" prop="money" class="form-col" >
                <el-input v-model="OutStorageData.money"
                          :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                          :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')">
                  <template #append>元</template>
                </el-input>
              </el-form-item>
              <el-form-item label="记录时间:" prop="enableTime" class="form-col" >
                <el-date-picker
                    v-model="OutStorageData.enableTime"
                    type="date"
                    value-format="YYYY-MM-DD"
                    placeholder="选择记录时间"
                    style="width: 100%"
                />
              </el-form-item>
            </el-form>
            <div class="div-button">
              <el-button type="primary" @click="toSaveOutStorage">保 存</el-button>
            </div>
          </div>
        </div>
      </el-dialog>

    </el-container>
  </el-container>
</template>

<script>
export default {
  name: "AssetStorageMge",
  data() {
    return{
      tableHeight: 0,
      total: 0,
      search:{
        recordMonth:"",
        goodsName:"",
        page: 1,
        pagesize: 10,
      },
      total1: 0,
      InStorageList:[],
      OutStorageList:[],
      dailVisi: false,
      businessAllTree:[],
      businessTree:[],
      businessType:"",

      backType:[],
      paymentType:[],
      taxRate:[],
      InStorageData: {
        goodsType:"",
        specsName:"",
        goodsName:"",
        unit:"",
        number:"",
        unitPrice:"",
        money:"",
        enableTime:"",
      },
      SpecsType:[],
      UnitData:[],
      subjectData:[],
      subjectAllTree:[],
      props:{
        label:'label',value:'id',children:'children',
        emitPath:false,
      },
      rules: {
        goodsType: [{required: true, message: "请选择物资分类", trigger: "blur"}],
        unit: [{required: true, message: "请选择单位", trigger: "blur"}],
        enableTime: [{required: true, message: "请选择记录时间", trigger: "blur"}],
        goodsName: [{required: true, message: "请填写商品名称", trigger: "blur"}],
        number: [{required: true, message: "请填写入库数量", trigger: "blur"}],
        unitPrice: [{required: true, message: "请填写入库单价", trigger: "blur"}],
        money: [{required: true, message: "请填写入库金额", trigger: "blur"}],
      },
      disabled:false,
      GoodsList:[],
      OutStorageData: {
        goodsType:"",
        specsName:"",
        goodsName:"",
        unit:"",
        realTimeNum:"",
        moveUnit:"",
        balanceMoney:"",
        number:"",
        unitPrice:"",
        money:"",
        enableTime:"",
      },
      rules1:{
        enableTime: [{required: true, message: "请选择记录时间", trigger: "blur"}],
        number: [{required: true, message: "请填写出库数量", trigger: "blur"}],
        unitPrice: [{required: true, message: "请填写出库单价", trigger: "blur"}],
        money: [{required: true, message: "请填写出库金额", trigger: "blur"}],
      },
      Title:"",
      pla_subjectDetail:"",
      pla_revExpDetail:"",
      pla_revenueMoney:"",
      pla_expenditureMoney:"",
      pla_taxRate:"",
      showOutStorage:false,
      showInStorage:false,
      voucherData: {
        abstract:"",
        subjectSort:"",
        subjectName:"",
        subjectDetail: "",
        revExpType:"",
        revExpName:"",
        revExpDetail:"",
        revenueMoney:"",
        expenditureMoney:"",
        taxRate:"",
      },
      in_rules: {
        abstract: [{required: true, message: "请填写摘要", trigger: "blur"}],
        subjectSort: [{required: true, message: "请选择科目类别", trigger: "blur"}],
        revExpType: [{required: true, message: "请选择收支类别", trigger: "blur"}],
      },
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.calcTableHeight()
      window.removeEventListener("resize", this.calcTableHeight)
      window.addEventListener("resize", this.calcTableHeight)
      this.search.recordMonth = this.current("month")
      this.loadData()
      this.loadAssetStorageData()
    })
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calcTableHeight)
  },
  methods: {
    calcTableHeight() {
      let mainHeight = window.getComputedStyle(this.$refs.mainSector.$el).height
      if (mainHeight.indexOf("px") !== -1)
        mainHeight = Number(mainHeight.substring(0, mainHeight.indexOf("px")))
      else
        mainHeight = Number(mainHeight)
      this.tableHeight = mainHeight - 180
    },
    current(d) {
      const nowDate = new Date();
      const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        day: nowDate.getDate()
      };
      if (d === "month")
        return date.year + "-" + (date.month >= 10 ? date.month : "0" + date.month);
      else if (d === "day")
        return date.year + "-" + (date.month >= 10 ? date.month : "0" + date.month) + "-" + (date.day >= 10 ? date.day : "0" + date.day);
    },
    loadData() {
      this.$axios.post("/api/common/getcodelists", {codes: ["UNIT", "SPECS_TYPE","TAX_RATE"]})
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.UnitData = data.result.UNIT
              this.SpecsType = data.result.SPECS_TYPE
              this.taxRate = data.result.TAX_RATE
            } else {
              this.$alert(response.data.result, "获取代码列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取代码列表出错")
          })
      this.$axios.get("/api/common/getsubjecttree")
          .then((response) => {
            if (response.data.success) {
              this.subjectAllTree = response.data.result
              var d = []
              this.subjectAllTree.forEach( item =>{
                if(item.label === "资产"){
                  item.children.forEach( i =>{
                    if(i.label === "存货") {
                      d.push(i)
                    }
                  })
                }
              })
              this.subjectData = d
            }
            else {
              this.$alert(response.data.result, "获取会计科目列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取会计科目列表出错")
          })

      this.$axios.get("/api/asset/getallgoodslist")
          .then((response) => {
            if (response.data.success) {
              this.GoodsList = response.data.result
            }
            else {
              this.$alert(response.data.result, "获取可出库库存列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取可出库库存列表出错")
          })

      this.$axios.get("/api/common/getbusinesstree")
          .then((response) => {
            if (response.data.success) {
              this.businessAllTree = response.data.result
              let f = this.businessAllTree.filter((item) => {
                return item.label === "库存管理"
              })
              this.businessTree = f
            }
            else {
              this.$alert(response.data.result, "获取业务类型列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取业务类型列表出错")
          })
    },
    loadAssetStorageData(d) {
      this.$axios.post("/api/asset/loadAssetInStorageData", this.search)
          .then((response) => {
            if (response.data.success) {
              this.total = response.data.result.totalrecords
              this.InStorageList = response.data.result.datalist
              if (d === "refresh") {
                this.$message({message: "刷新成功", type: "success"})
              }
            } else {
              this.$alert(response.data.result, "获取物资入库记录列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取物资入库记录列表出错")
          })

      this.$axios.post("/api/asset/loadAssetOutStorageData", this.search)
          .then((response) => {
            if (response.data.success) {
              this.total1 = response.data.result.totalrecords
              this.OutStorageList = response.data.result.datalist
              if (d === "refresh") {
                this.$message({message: "刷新成功", type: "success"})
              }
            } else {
              this.$alert(response.data.result, "获取物资出库记录列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取物资出库记录列表出错")
          })
    },
    toRefresh() {
      this.search.goodsName = ""
      this.loadAssetStorageData("refresh")
    },
    toReset() {
      this.search.goodsName = ""
      this.loadAssetStorageData()
    },
    doInStorage(row){
      this.Title = "物资入库"
      this.dailVisi =true
      this.showInStorage = true
      this.showOutStorage = false
      this.businessType = ""
      this.voucherData.abstract = ""
      this.voucherData.subjectSort = ""
      this.voucherData.subjectDetail = ""
      this.pla_subjectDetail = ""
      this.voucherData.revExpType = ""
      this.voucherData.revExpDetail = ""
      this.pla_revExpDetail = ""
      this.voucherData.revenueMoney = ""
      this.pla_revenueMoney = ""
      this.voucherData.expenditureMoney = ""
      this.pla_expenditureMoney = ""
      this.voucherData.taxRate = ""
      if(row){
        this.InStorageData.goodsType = row.GOODS_TYPE
        this.InStorageData.specsName = row.SPECS_TYPE
        this.InStorageData.goodsName = row.GOODS_NAME
        this.InStorageData.unit = row.UNIT
        this.InStorageData.money = ""
        this.InStorageData.number = ""
        this.InStorageData.unitPrice = ""
        this.InStorageData.enableTime = ""
      }else{
        this.InStorageData.goodsType = ""
        this.InStorageData.specsName = ""
        this.InStorageData.goodsName = ""
        this.InStorageData.unit = ""
        this.InStorageData.money = ""
        this.InStorageData.number = ""
        this.InStorageData.unitPrice = ""
        this.InStorageData.enableTime = ""
      }
      if (this.$refs.inStorageDataForm)
        this.$refs.inStorageDataForm.clearValidate()
    },

    getMoney(){
      if(this.InStorageData.number !== "" && this.InStorageData.unitPrice !== ""){
        this.InStorageData.money = Number(this.InStorageData.number) * Number(this.InStorageData.unitPrice)
      }
    },
    toSaveInStorage(){
      if(this.voucherData.revenueMoney !== "" && this.voucherData.expenditureMoney !== ""){
        return this.$message({message: "收入金额和支出金额不能同时填写，无法保存", type: "error"})
      }
      if(this.voucherData.subjectDetail === ""){
        this.voucherData.subjectDetail = this.pla_subjectDetail
      }
      if(this.voucherData.revExpDetail === ""){
        this.voucherData.revExpDetail = this.pla_revExpDetail
      }
      if(this.voucherData.taxRate === ""){
        this.voucherData.taxRate = this.pla_taxRate.replace(/%/g, '')
      }
      this.$refs["inStorageDataForm"].validate((valid) => {
        if (valid) {
          this.$axios.post("/api/asset/savestoragedata", {"InStorageData":this.InStorageData, "voucherData":this.voucherData})
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "物资入库登记成功", type: "success"})
                  this.dailVisi = false
                  this.loadAssetStorageData()
                } else {
                  this.$alert(response.data.result, "物资入库登记出错")
                }
              })
              .catch((error) => {
                this.$alert(error, "物资入库登记出错")
              })
        }
        else
          return false
      })
    },
    doOutStorage(row){
      this.Title = "物资出库"
      this.dailVisi =true
      this.showInStorage = false
      this.showOutStorage = true
      this.businessType = ""
      this.voucherData.abstract = ""
      this.voucherData.subjectSort = ""
      this.voucherData.subjectDetail = ""
      this.pla_subjectDetail = ""
      this.voucherData.revExpType = ""
      this.voucherData.revExpDetail = ""
      this.pla_revExpDetail = ""
      this.voucherData.revenueMoney = ""
      this.pla_revenueMoney = ""
      this.voucherData.expenditureMoney = ""
      this.pla_expenditureMoney = ""
      this.voucherData.taxRate = ""
      if(row) {
        this.disabled = true
        this.OutStorageData.goodsType = row.GOODS_TYPE
        this.OutStorageData.specsName = row.SPECS_TYPE
        this.OutStorageData.goodsName = row.GOODS_NAME
        this.OutStorageData.unit = row.UNIT
        this.OutStorageData.realTimeNum = row.realTimeNum
        this.OutStorageData.moveUnit = row.moveUnit
        this.OutStorageData.balanceMoney = row.balanceMoney
        this.OutStorageData.number = ""
        this.OutStorageData.money = ""
        this.OutStorageData.unitPrice = ""
        this.OutStorageData.enableTime = ""
      }else{
        this.disabled = false
        this.OutStorageData.goodsType = ""
        this.OutStorageData.specsName = ""
        this.OutStorageData.goodsName = ""
        this.OutStorageData.unit = ""
        this.OutStorageData.realTimeNum = ""
        this.OutStorageData.moveUnit = ""
        this.OutStorageData.balanceMoney = ""
        this.OutStorageData.number = ""
        this.OutStorageData.money = ""
        this.OutStorageData.unitPrice = ""
        this.OutStorageData.enableTime = ""
      }
      if (this.$refs.OutStorageDataForm)
        this.$refs.OutStorageDataForm.clearValidate()
    },
    showOutData(){
      this.OutStorageList.forEach(item => {
        if(item.GOODS_NAME === this.OutStorageData.goodsName){
          this.OutStorageData.goodsType = item.GOODS_TYPE
          this.OutStorageData.specsName = item.SPECS_TYPE
          this.OutStorageData.goodsName = item.GOODS_NAME
          this.OutStorageData.unit = item.UNIT
          this.OutStorageData.realTimeNum = item.realTimeNum
          this.OutStorageData.moveUnit = item.moveUnit
          this.OutStorageData.balanceMoney = item.balanceMoney
        }
      })
    },
    getMoney1(){
      if(this.OutStorageData.number !== "" && this.OutStorageData.unitPrice !== ""){
        this.OutStorageData.money = Number(this.OutStorageData.number) * Number(this.OutStorageData.unitPrice)
      }
    },
    toSaveOutStorage(){
      if(this.voucherData.revenueMoney !== "" && this.voucherData.expenditureMoney !== ""){
        return this.$message({message: "收入金额和支出金额不能同时填写，无法保存", type: "error"})
      }
      if(this.voucherData.subjectDetail === ""){
        this.voucherData.subjectDetail = this.pla_subjectDetail
      }
      if(this.voucherData.revExpDetail === ""){
        this.voucherData.revExpDetail = this.pla_revExpDetail
      }
      if(this.voucherData.taxRate === ""){
        this.voucherData.taxRate = this.pla_taxRate.replace(/%/g, '')
      }
      this.$refs["OutStorageDataForm"].validate((valid) => {
        if (valid) {
          this.$axios.post("/api/asset/saveoutstoragedata", {"OutStorageData":this.OutStorageData, "voucherData":this.voucherData})
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "物资出库登记成功", type: "success"})
                  this.dailVisi = false
                  this.loadAssetStorageData()
                } else {
                  this.$alert(response.data.result, "物资出库登记出错")
                }
              })
              .catch((error) => {
                this.$alert(error, "物资出库登记出错")
              })
        }
        else
          return false
      })
    },
    getBusinessData(){
      var info =[]
      if(this.businessType !== "") {
        this.businessTree.forEach(item => {
          if (item.children != null) {
            item.children.forEach(ch => {
              if (ch.children != null) {
                ch.children.forEach(c => {
                  if (c.id === this.businessType) {
                    return info = c.info
                  }
                })
              }
            })
          }
        })
        this.InStorageData.goodsType = info.SUBJECT_SORT.toString()
        this.InStorageData.goodsName = info.SUBJECT_DETAIL

        this.voucherData.abstract = info.BUSINESS_ABSTRACT
        this.voucherData.subjectSort = info.SUBJECT_SORT.toString()
        this.voucherData.subjectDetail = ""
        this.pla_subjectDetail = info.SUBJECT_DETAIL
        this.voucherData.revExpType = info.REV_EXP_TYPE.toString()
        this.voucherData.revExpDetail = ""
        this.pla_revExpDetail = info.REV_EXP_DETAIL
        this.voucherData.revenueMoney = ""
        this.pla_revenueMoney = info.REVENUE_MONEY
        this.voucherData.expenditureMoney = ""
        this.pla_expenditureMoney = info.EXPENDITURE_MONEY
        this.voucherData.taxRate = ""
        if(info.TAX_RATE)
          this.pla_taxRate = info.TAX_RATE +"%"
        else
          this.pla_taxRate = ""
        this.getSubjectName("bus")
      }
    },
    getSubjectName(type) {
      this.backType = []
      this.paymentType = []
      this.voucherData.subjectDetail = ""
      this.voucherData.revExpDetail = ""
      if(type !== "bus"){
        this.pla_subjectDetail= ""
        this.pla_revExpDetail= ""
      }
      this.subjectAllTree.forEach(item => {
        if (item.children != null) {
          item.children.forEach(ch => {
            ch.children.forEach(c => {
              if (c.id === this.voucherData.subjectSort) {
                this.backType = c.detail
                this.voucherData.subjectName = c.label
                if(this.backType.length >0){
                  this.pla_subjectDetail= this.backType[0].SUBJECT_DETAIL
                }
                this.sub_nature = c.nature
              }
              if (c.id === this.voucherData.revExpType) {
                this.paymentType = c.detail
                this.voucherData.revExpName = c.label
                if(this.paymentType.length >0){
                  this.pla_revExpDetail= this.paymentType[0].SUBJECT_DETAIL
                }
                this.rev_nature = c.nature
              }
            })
          })
        }
      })
    },
    toDetail(data){
      var subjectName = ""
      this.subjectAllTree.forEach(item => {
        if (item.children != null) {
          item.children.forEach(ch => {
            ch.children.forEach(c => {
              if(data === "1"){
                if (c.id === this.InStorageData.subjectSort) {
                  subjectName = c.label
                }
              }else{
                if (c.id === this.InStorageData.revExpType) {
                  subjectName = c.label
                }
              }
            })
          })
        }
      })
      this.$router.replace({name: "subject-mge", query:{name:subjectName}})
    },
  }

}
</script>

<style scoped>

</style>
