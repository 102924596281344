<!--
权限管理界面
最后编辑人：张鹏
最后编辑时间：2022-12-12
最后编辑细节：修改标题
-->
<template>
  <el-container class="base-container">
    <el-header class="base-height">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>权限管理</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-container class="main-container" ref="mainSector">
      <el-aside width="40%" id="permissionTree" style="padding: 20px;">
        <el-row>
          <el-col :span="7" style="min-width: 100px">
            <el-button type="success" @click="toAdd(null)"><el-icon style="margin-right: 5px;"><FolderAdd /></el-icon>根权限</el-button>
          </el-col>
          <el-col :span="16" class="input-search">
            <el-input v-model="filterName" placeholder="请输入权限名称" prefix-icon="Search" clearable size="default" style="width: 90%;"></el-input>
          </el-col>
          <el-col :span="1">
            <span>
              <el-tooltip effect="dark" content="展开/收起" placement="top" >
                <el-link :underline="false" @click="toggleRowExpansion()">
                  <el-icon :size="20"><Sort /></el-icon>
                </el-link>
              </el-tooltip>
            </span>
          </el-col>
        </el-row>
        <el-divider style="margin: 15px 0;"/>
        <div style="height: calc(100% - 65px); overflow: auto;">
          <el-tree
              :data="treeData"
              :props="treeProps"
              node-key="PERMISSION_ID"
              default-expand-all
              draggable
              @node-drop="nodeDrop"
              :filter-node-method="filterNode"
              :expand-on-click-node="false"
              ref="permissionTree">
            <template  #default="{ node, data }">
              <span @click="toEdit(data)" style="max-width: 140px">
                <el-icon v-if="data.PERMISSION_TYPE === '1'"><Connection /></el-icon>
                <el-icon v-else-if="data.PERMISSION_TYPE === '2'"><FolderOpened /></el-icon>
                <el-icon v-else><Postcard /></el-icon>
                {{ node.label }}
              </span>
              <span style="text-align: right;position: absolute;right: 0;">
                <el-button-group>
                  <el-tooltip content="上移" placement="top" effect="light" transition="" :enterable="false">
                    <el-button type="primary" size="small" class="tree-button" @click="moveUp(data)" ><el-icon><Top /></el-icon></el-button>
                  </el-tooltip>
                  <el-tooltip content="下移" placement="top" effect="light" transition="" :enterable="false">
                    <el-button type="primary" size="small" class="tree-button" @click="moveDown(data)"><el-icon><Bottom /></el-icon></el-button>
                  </el-tooltip>
                  <el-tooltip content="新增子权限" placement="top" effect="light" transition="" :enterable="false">
                    <el-button type="success" size="small" class="tree-button" @click="toAdd(data)"><el-icon><Plus /></el-icon></el-button>
                  </el-tooltip>
                  <el-tooltip content="删除" placement="top" effect="light" transition="" :enterable="false">
                    <el-button type="danger" size="small" class="tree-button" @click="toDel(data)"><el-icon><DeleteFilled /></el-icon></el-button>
                  </el-tooltip>
                </el-button-group>
              </span>
            </template >
          </el-tree>
        </div>
      </el-aside>
      <el-main style="background-color: #FBFBF9; border-left: 1px solid #EBEEF5;">
        <el-row>
          <el-col :span="24"><div style="padding: 7px 15px">{{ formTitle }}</div></el-col>
        </el-row>
        <el-form :model="permissionData" :rules="rules" ref="permissionForm" label-width="100px">
          <el-form-item label="权限名称" prop="permissionname">
            <el-input v-model="permissionData.permissionname" :disabled="unedit"></el-input>
          </el-form-item>
          <el-form-item label="权限类型" prop="type">
            <el-select v-model="permissionData.type" placeholder="请选择权限类型" @change="typeChange" :disabled="unedit">
              <el-option label="功能" value="1"></el-option>
              <el-option label="目录" value="2"></el-option>
              <el-option label="页面" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="权限地址" prop="link" v-show="permissionData.type !== '2'">
            <el-input v-model="permissionData.link" :disabled="unedit"></el-input>
          </el-form-item>
          <el-form-item label="排序号" prop="sort">
            <el-input v-model="permissionData.sort" :disabled="unedit"></el-input>
          </el-form-item>
          <div class="div-button">
            <el-button type="primary" @click="toSave" :disabled="unedit">保 存</el-button>
          </div>
        </el-form>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: "PermissionMge",
  watch: {
    filterName (val) {
      this.$refs.permissionTree.filter(val)
    }
  },
  data () {
    var validateLink = (rule, value, callback) => {
      if (this.permissionData.type !== "2") {
        if (value === "") {
          callback(new Error('请填写权限地址'))
        } else {
          callback()
        }
      }
      else {
        callback()
      }
    };
    return {
      unedit: true,
      formTitle: "权限",
      filterName: "",
      treeData: [],
      rules: {
        permissionname: [{required: true, message: "请填写权限名称", trigger: "blur"}, {max: 255, message: "不能超过255个字", trigger: "blur"}],
        type: [{required: true, message: "请选择权限类型", trigger: "change"}],
        sort: [{required: true, message: "请填写排序号", trigger: "blur"}, {pattern: /^\d+$/, message: "必须是整数", trigger: "blur"}],
        link: [{validator: validateLink, trigger: "blur"}, {max: 255, message: "不能超过255个字符", trigger: "blur"}]
      },
      treeProps: {
        children: 'subpermission',
        label: 'PERMISSION_NAME'
      },
      permissionData: {
        permissionid: "",
        permissionname: "",
        type: "1",
        link: "",
        sort: "",
        parentid: "",
      }
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    //权限类型选择发生变更
    typeChange () {
      if (this.permissionData.type === "2")
        this.permissionData.link = ""
    },
    //查找树节点
    filterNode (value, data) {
      if (!value) return true;
      return data.PERMISSION_NAME.indexOf(value) !== -1;
    },
    //拖拽节点变更父级单位
    nodeDrop (dragging, drop, type) {
      this.$axios.post("/api/permission/changepermission", {
        srcid: dragging.data.PERMISSION_ID,
        tgtid: drop.data.PERMISSION_ID,
        type: type
      })
          .then((response) => {
            if (!response.data.success) {
              this.loadData()
            }
          })
          .catch((error) => {
            this.$alert(error, "变更权限位置出错")
            this.loadData()
          })
    },
    /** 展开收缩 */
    toggleRowExpansion(){
      const nodes = this.$refs.permissionTree.store._getAllNodes();
      nodes.forEach(item=>{
        item.expanded = !item.expanded
      })
    },
    //载入全部权限树形列表
    loadData () {
      this.$axios.get("/api/permission/loadallpermission")
          .then((response) => {
            if (response.data.success) {
              this.treeData = response.data.result
              if (this.filterName !== "")
                setTimeout(() => {this.$refs.permissionTree.filter(this.filterName)}, 600)
            }
            else {
              this.$alert(response.data.result, "获取权限列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取权限列表出错")
          })
    },
    //权限上移
    moveUp (data) {
      this.$axios.post("/api/permission/moveuppermission", {permissionid: data.PERMISSION_ID, sort: data.SORT_NUMBER, parentid: data.PARENT_ID})
          .then((response) => {
            if (response.data.success) {
              this.$message({message: "上移成功", type: "success"})
              this.loadData()
              if (data.PERMISSION_ID === this.permissionData.permissionid)
                this.permissionData.sort = (data.SORT_NUMBER - 1).toString()
            }
            else {
              this.$alert(response.data.result, "权限上移出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "权限上移出错")
          })
    },
    //权限下移
    moveDown (data) {
      this.$axios.post("/api/permission/movedownpermission", {permissionid: data.PERMISSION_ID, sort: data.SORT_NUMBER, parentid: data.PARENT_ID})
          .then((response) => {
            if (response.data.success) {
              this.$message({message: "下移成功", type: "success"})
              this.loadData()
              if (data.PERMISSION_ID === this.permissionData.permissionid)
                this.permissionData.sort = (data.SORT_NUMBER + 1).toString()
            }
            else {
              this.$alert(response.data.result, "权限下移出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "权限下移出错")
          })
    },
    //权限新增
    toAdd (data) {
      this.$refs["permissionForm"].resetFields()
      this.unedit = false
      this.permissionData.permissionid = ""
      if (data === null) {
        this.permissionData.parentid = ""
        this.formTitle = "新增根权限"
      }
      else {
        this.permissionData.parentid = data.PERMISSION_ID
        this.formTitle = "新增子权限（父权限：" + data.PERMISSION_NAME + "）"
      }
      if(data != null) {
        if (typeof data.subpermission !== "undefined") {
          let sort = 1
          const sub = data.subpermission
          sub.forEach(perm => {
            if (parseInt(perm.SORT_NUMBER) >= sort)
              sort = parseInt(perm.SORT_NUMBER) + 1
          })
          this.permissionData.sort = sort
        }
      }else{
        let sort = 1
        const sub = this.treeData
        sub.forEach(perm => {
          if (parseInt(perm.SORT_NUMBER) >= sort)
            sort = parseInt(perm.SORT_NUMBER) + 1
        })
        this.permissionData.sort = sort
      }
    },
    //权限编辑
    toEdit (node) {
      this.$refs["permissionForm"].resetFields()
      this.unedit = false
      this.permissionData.permissionid = node.PERMISSION_ID
      this.permissionData.permissionname = node.PERMISSION_NAME
      this.permissionData.type = node.PERMISSION_TYPE
      this.permissionData.link = node.PERMISSION_URL === null ? "" : node.PERMISSION_URL
      this.permissionData.sort = node.SORT_NUMBER.toString()
      this.permissionData.parentid = node.PARENT_ID
      this.formTitle = "编辑权限"
    },
    //权限删除
    toDel (data) {
      this.$confirm("删除此权限将一并删除其下子权限，确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$axios.post("/api/permission/delpermission", {permissionid: data.PERMISSION_ID})
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "权限删除成功", type: "success"})
                if (data.PERMISSION_ID === this.permissionData.permissionid) {
                  this.$refs["permissionForm"].resetFields()
                  this.unedit = true
                  this.permissionData.permissionid = ""
                  this.permissionData.parentid = ""
                }
                this.loadData()
              }
              else {
                this.$alert(response.data.result, "权限删除出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "权限删除出错")
            })
      })
    },
    //权限保存
    toSave () {
      this.$refs["permissionForm"].validate((valid) => {
        if (valid) {
          this.$axios.post("/api/permission/savepermission", this.permissionData)
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "权限保存成功", type: "success"})
                  this.loadData()
                }
                else {
                  this.$alert(response.data.result, "权限保存出错")
                }
              })
              .catch((error) => {
                this.$alert(error, "权限保存出错")
              })
        }
        else
          return false
      })
    }
  }
}
</script>

<style scoped>

</style>
