<template>
  <el-container class="base-container">
    <el-header class="base-height">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>会计报表</el-breadcrumb-item>
        <el-breadcrumb-item>利润表</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-container class="main-container" ref="mainSector">
      <el-main>
        <el-row>
          <el-col :span="3" class="input-search">
            <el-date-picker
                v-model="search.recordMonth"
                value-format="YYYY-MM"
                type="month"
                style="width: 100%;"
                @change="loadData()">
            </el-date-picker>
          </el-col>
          <el-col :span="4" class="input-search">
            <el-cascader
                placeholder="请选择公司或部门"
                style="width: 100%;"
                v-model="search.organOrDep"
                :props="organprops"
                clearable
                :show-all-levels="false"
                @change="loadData($event)"
                :options="OrganTreeData" >
            </el-cascader>
          </el-col>
          <el-col :span="7">
            <el-button type="primary" @click="toPrint()"  style="margin-left: 10px;">
              <i class="iconfont icon-print" style="font-size: 15px"></i><span style="margin-right: 5px">打印</span>
            </el-button>
            <el-button type="warning" @click="doExport()" :loading="exportTip !== ''" style="margin-left: 10px;">
              <i class="iconfont icon-daochu" style="font-size: 15px"></i><span style="margin-right: 5px">导出</span>
            </el-button>
            <a ref="expLink" target="_blank" style="display: none;"></a>
            <span style="font-size: 12px; margin-left: 20px">{{ exportTip }}</span>
          </el-col>
          <el-col :span="10" style="text-align: right;">
            <el-tooltip effect="dark" content="刷新" placement="top">
              <el-link :underline="false" @click="toRefresh()">
                <el-icon :size="20"><Refresh /></el-icon>
              </el-link>
            </el-tooltip>
          </el-col>
        </el-row>

        <div class="content">
          <div class="table">
            <table>
              <tr>
                <th><div class="cell1">收支项目</div></th>
                <th><div class="cell1" >序号</div></th>
                <th><div class="cell1">本月合计</div></th>
                <th><div class="cell1">本年累计</div></th>
              </tr>
              <tr>
                <td><div class="cell">一、营业收入</div></td>
                <td><div class="cell2">1</div></td>
                <td><div class="cell2">{{ formatNumber(num1) }}</div></td>
                <td><div class="cell2">{{ formatNumber(tol_num1) }}</div></td>
              </tr>
              <tr>
                <td><div class="cell3">减：营业成本</div></td>
                <td><div class="cell2">2</div></td>
                <td><div class="cell2">{{ formatNumber(num2) }}</div></td>
                <td><div class="cell2">{{ formatNumber(tol_num2) }}</div></td>
              </tr>
              <tr>
                <td><div class="cell4">税金及附加</div></td>
                <td><div class="cell2">3</div></td>
                <td><div class="cell2">{{ formatNumber(num3) }}</div></td>
                <td><div class="cell2">{{ formatNumber(tol_num3) }}</div></td>
              </tr>
              <tr>
                <td><div class="cell4">管理费用</div></td>
                <td><div class="cell2">4</div></td>
                <td><div class="cell2">{{ formatNumber(num4) }}</div></td>
                <td><div class="cell2">{{ formatNumber(tol_num4) }}</div></td>
              </tr>
              <tr>
                <td><div class="cell4">销售费用</div></td>
                <td><div class="cell2">5</div></td>
                <td><div class="cell2">{{ formatNumber(num5) }}</div></td>
                <td><div class="cell2">{{ formatNumber(tol_num5) }}</div></td>
              </tr>
              <tr>
                <td><div class="cell4">财务费用</div></td>
                <td><div class="cell2">6</div></td>
                <td><div class="cell2">{{ formatNumber(num6) }}</div></td>
                <td><div class="cell2">{{ formatNumber(tol_num6) }}</div></td>
              </tr>
              <tr>
                <td><div class="cell4">研发费用</div></td>
                <td><div class="cell2">7</div></td>
                <td><div class="cell2">{{ formatNumber(num7) }}</div></td>
                <td><div class="cell2">{{ formatNumber(tol_num7) }}</div></td>
              </tr>
              <tr>
                <td><div class="cell">二、营业利润</div></td>
                <td><div class="cell2">8</div></td>
                <td><div class="cell2">{{ formatNumber(num8) }}</div></td>
                <td><div class="cell2">{{ formatNumber(tol_num8) }}</div></td>
              </tr>
              <tr>
                <td><div class="cell3">加：营业外收入</div></td>
                <td><div class="cell2">9</div></td>
                <td><div class="cell2">{{ formatNumber(num9) }}</div></td>
                <td><div class="cell2">{{ formatNumber(tol_num9) }}</div></td>
              </tr>
              <tr>
                <td><div class="cell3">减：营业外支出</div></td>
                <td><div class="cell2">10</div></td>
                <td><div class="cell2">{{ formatNumber(num10) }}</div></td>
                <td><div class="cell2">{{ formatNumber(tol_num10) }}</div></td>
              </tr>
              <tr>
                <td><div class="cell">三、利润总额</div></td>
                <td><div class="cell2">11</div></td>
                <td><div class="cell2">{{ formatNumber(num11) }}</div></td>
                <td><div class="cell2">{{ formatNumber(tol_num11) }}</div></td>
              </tr>
              <tr>
                <td><div class="cell3">减：所得税费用</div></td>
                <td><div class="cell2">12</div></td>
                <td><div class="cell2">{{ formatNumber(num12) }}</div></td>
                <td><div class="cell2">{{ formatNumber(tol_num12) }}</div></td>
              </tr>
              <tr>
                <td><div class="cell">四、净利润</div></td>
                <td><div class="cell2">13</div></td>
                <td><div class="cell2">{{ formatNumber(num13) }}</div></td>
                <td><div class="cell2">{{ formatNumber(tol_num13) }}</div></td>
              </tr>
            </table>
          </div>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: "profitReport",
  data () {
    return {
      tableHeight: 0,
      search: {
        recordMonth: "",
        organOrDep: "",
        s_organid: "",
        s_depid: "",
      },
      exportTip: "",
      OrganTreeData: [],
      organprops: {
        label: 'text', value: 'value', children: 'children',
        checkStrictly: true,
        emitPath: false,
      },
      num1: "",
      num2: "",
      num3: "",
      num4: "",
      num5: "",
      num6: "",
      num7: "",
      num8: "",
      num9: "",
      num10: "",
      num11: "",
      num12: "",
      num13: "",

      tol_num1: "",
      tol_num2: "",
      tol_num3: "",
      tol_num4: "",
      tol_num5: "",
      tol_num6: "",
      tol_num7: "",
      tol_num8: "",
      tol_num9: "",
      tol_num10: "",
      tol_num11: "",
      tol_num12: "",
      tol_num13: "",
    }
  },
  computed:{
    getNowTime() {
      let now = new Date();
      let year = now.getFullYear(); //得到年份
      let month = now.getMonth(); //得到月份
      month = month + 1;
      month = month.toString().padStart(2, "0");
      return `${year}-${month}`;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.calcTableHeight()
      window.removeEventListener("resize", this.calcTableHeight)
      window.addEventListener("resize", this.calcTableHeight)
      this.search.recordMonth = this.getNowTime+"";
      this.search.organOrDep = "company-"+sessionStorage.getItem("organ-organid")
      // this.loadData()
      this.loadCode()
    })
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calcTableHeight)
  },
  methods: {
    calcTableHeight() {
      let mainHeight = window.getComputedStyle(this.$refs.mainSector.$el).height
      if (mainHeight.indexOf("px") !== -1)
        mainHeight = Number(mainHeight.substring(0, mainHeight.indexOf("px")))
      else
        mainHeight = Number(mainHeight)
      this.tableHeight = mainHeight - 100
    },
    toRefresh() {
      this.loadData("refresh")
    },
    formatNumber(value) {
      if (value !== "" && value !== null)
        return new Intl.NumberFormat().format(value);
    },
    loadCode() {
      this.$axios.get("/api/common/getqueryorgantree")
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.OrganTreeData = data.result
            } else {
              this.$alert(response.data.result, "获取组织列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取组织列表出错")
          })
    },

    loadData(d) {
      if (this.search.organOrDep !== "" && this.search.organOrDep !== null) {
        const parts = this.search.organOrDep.toString().split('-');
        if (parts[0] === "company") {
          this.search.s_organid = parts[1]
          this.search.s_depid = ""
        } else {
          this.search.s_organid = parts[0]
          this.search.s_depid = parts[1]
        }
      } else {
        this.search.s_organid = ""
        this.search.s_depid = ""
      }
      this.$axios.post("/api/profit/loadprofitreportdata", this.search)
          .then((response) => {
            if (response.data.success) {
              this.subjectBalanceData = response.data.result
              if (d === "refresh") {
                this.$message({message: "刷新成功", type: "success"})
              }
            } else {
              this.$alert(response.data.result, "获取利润表数据出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取利润表数据出错")
          })
    },
  }
}
</script>

<style scoped>
.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100% - 50px);
  margin-top: 10px;
  border-bottom: 1px solid #ebeef5;
}
.table {
  width: calc(100% - 0);
  text-align: center;
  background-color: white;
  height:  100%;
  overflow-y: auto;
}
.table table {
  width: 100%;
  border-right: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
  border-spacing: 0;
  border-collapse: collapse
}
.table table td {
  border-left: 1px solid#ebeef5;
  border-top: 1px solid #ebeef5;
  font-size: 12px;

  border-right: 1px solid #ebeef5;
}
.table table tr th {
  position: sticky;
  top: 0;
  background-color: #F5F7FA;
  height: 40px;
}
.table table tr td {
  height: 40px;
}

.cell1 {
  line-height: 23px;
  padding: 0 12px;
  color: #909399;
  font-size: 14px;
  font-weight: bold;
}
.cell {
  line-height: 23px;
  padding: 0 12px;
  color: #606266;
  font-size: 14px;
  text-align: left;
}
.cell2 {
  line-height: 23px;
  padding: 0 12px;
  color: #606266;
  font-size: 14px;
  font-weight: bold;
}
.cell3 {
  line-height: 23px;
  padding-left: 40px;
  color: #606266;
  font-size: 14px;
  text-align: left;
}
.cell4 {
  line-height: 23px;
  padding-left: 70px;
  color: #606266;
  font-size: 14px;
  text-align: left;
}
</style>
