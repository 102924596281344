<template>
  <el-form :inline="true" :model="personnelData" :rules="rules" ref="userForm" label-width="130px">
    <el-form-item label="姓名" prop="personnel_name" class="form-col25">
      <el-input v-model="personnelData.personnel_name" clearable />
    </el-form-item>
    <el-form-item label="出生日期" prop="birth" class="form-col25">
      <el-input v-model="personnelData.birth" clearable placeholder="YYYY-MM-DD" />
    </el-form-item>
    <el-form-item label="性别" prop="sex" class="form-col25">
      <el-input v-model="personnelData.sex" clearable />
    </el-form-item>
    <el-form-item label="民族" prop="nation" class="form-col25">
      <el-input v-model="personnelData.nation" clearable />
    </el-form-item>
    <el-form-item label="身份证号码" prop="card" class="form-col25">
      <el-input v-model="personnelData.card" clearable />
    </el-form-item>
    <el-form-item label="政治面貌" prop="landscape" class="form-col25">
      <el-input v-model="personnelData.landscape" clearable />
    </el-form-item>
    <el-form-item label="户籍所在地" prop="residence" class="form-col25">
      <el-input v-model="personnelData.residence" clearable />
    </el-form-item>
    <el-form-item label="健康状况" prop="health" class="form-col25">
      <el-input v-model="personnelData.health" clearable />
    </el-form-item>
    <el-form-item label="文化程度" prop="culture" class="form-col25">
      <el-input v-model="personnelData.culture" clearable />
    </el-form-item>
    <el-form-item label="宗教信仰" prop="religious" class="form-col25">
      <el-input v-model="personnelData.religious" clearable />
    </el-form-item>
    <el-form-item label="联系电话" prop="phone" class="form-col25">
      <el-input v-model="personnelData.phone" clearable />
    </el-form-item>
    <el-form-item label="工作单位" prop="working" class="form-col25">
      <el-input v-model="personnelData.working" clearable />
    </el-form-item>
    <el-form-item label="婚姻状况" prop="marriage" class="form-col25">
      <el-input v-model="personnelData.marriage" clearable />
    </el-form-item>
    <el-form-item label="初（再）婚时间" prop="marriageTime" class="form-col25">
      <el-input v-model="personnelData.marriageTime" clearable placeholder="YYYY-MM-DD" />
    </el-form-item>
    <el-form-item label="独生子女证时间" prop="childTime" class="form-col25">
      <el-input v-model="personnelData.childTime" clearable placeholder="YYYY-MM-DD" />
    </el-form-item>
    <el-form-item label="生育子女数" prop="childNum" class="form-col25">
      <el-input v-model="personnelData.childNum" clearable />
    </el-form-item>
    <el-form-item label="就业情况" prop="employment" class="form-col25">
      <el-input v-model="personnelData.employment" clearable />
    </el-form-item>
    <el-form-item label="职工医保" prop="workers" class="form-col25">
      <el-input v-model="personnelData.workers" clearable />
    </el-form-item>
    <el-form-item label="社会养老保险" prop="society" class="form-col25">
      <el-input v-model="personnelData.society" clearable />
    </el-form-item>
    <el-form-item label="居民养老保险" prop="resident" class="form-col25">
      <el-input v-model="personnelData.resident" clearable />
    </el-form-item>
    <el-form-item label="居民医保" prop="residentInsurance" class="form-col25">
      <el-input v-model="personnelData.residentInsurance" clearable />
    </el-form-item>
    <el-form-item label="高龄补贴" prop="advanced" class="form-col25">
      <el-input v-model="personnelData.advanced" clearable />
    </el-form-item>
    <el-form-item label="残疾级别" prop="disability_level" class="form-col25">
      <el-input v-model="personnelData.disability_level" clearable />
    </el-form-item>
    <el-form-item label="残疾类型" prop="disability_type" class="form-col25">
      <el-input v-model="personnelData.disability_type" clearable />
    </el-form-item>
    <el-form-item label="残疾证号" prop="disability_number" class="form-col25">
      <el-input v-model="personnelData.disability_number" clearable />
    </el-form-item>
    <el-form-item label="入伍时间" prop="enlistment" class="form-col25">
      <el-input v-model="personnelData.enlistment" clearable placeholder="YYYY-MM-DD" />
    </el-form-item>
    <el-form-item label="退伍时间" prop="retirement" class="form-col25">
      <el-input v-model="personnelData.retirement" clearable placeholder="YYYY-MM-DD" />
    </el-form-item>
    <el-form-item label="是否参战" prop="JoinWar" class="form-col25">
      <el-input v-model="personnelData.JoinWar" clearable />
    </el-form-item>
    <el-form-item label="伤残类型（级别）" prop="military_disability_type" class="form-col25">
      <el-input v-model="personnelData.military_disability_type" clearable />
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    personnelData: {
      type: Object,
      default: () => { }
    },
    treeData: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return {
      checkList: [],
      rules: {
        organid: [{ required: true, message: "请选择所属机构", trigger: "blur" }],
        userrole: [{ required: true, message: "请选择用户角色", trigger: "blur" }],
      },
      treeProps: {
        label: 'text', value: 'value', children: 'children',
      },
    }
  },
  mounted () {
  },
  methods: {
    toRoleDep (data) {
      console.log(data);
      if (data === null) {
        this.personnelData.FLOOR_ID = ""
        return
      }
      if (data.length <= 3) {
        this.personnelData.FLOOR_ID = ""
        this.$alert("请选择正确的楼层")
        return
      }
      this.personnelData.FLOOR_ID = data[4]
      console.log(this.personnelData.FLOOR_ID);
    },
    // 获取出生日期
    getDatatimeChange (val) {
      if (val.card.length >= 18) {
        val.birth = val.card.substring(6, 10) + "-" + val.card.substring(10, 12) + "-" + val.card.substring(12, 14)
      }
      // this.personnelData.communityInfo.forEach((item)=>{
      //   console.log(item);
      // })
      // console.log(val.substring(6, 10));
      // console.log(val.substring(10, 12));
      // console.log(val.substring(12, 14));
    },
    // 人员表新增
    infoAdd () {
      this.personnelData.communityInfo.push({
        personnel_name: "",
        sex: "",
        nation: "",
        birth: "",
        mark: "1",
        card: "",
        landscape: "",
        residence: "",
        health: "",
        culture: "",
        religious: "",
        phone: "",
        working: "",
        marriage: "",
        marriageTime: "",
        childTime: "",
        childNum: "",
        employment: "",
        workers: "",
        society: "",
        resident: "",
        residentInsurance: "",
        advanced: "",
        disability: "",
        disability_level: "",
        disability_type: "",
        disability_number: "",
        enlistment: "",
        retirement: "",
        JoinWar: "",
        military_disability_type: "",
      })
    },
    // // 民生表新增
    // deformAdd () {
    //   this.personnelData.deformInfo.push({
    //     deformit_name: "",
    //     mark: "1",
    //     advanced: "",
    //     disability: "",
    //     disability_level: "",
    //     disability_type: "",
    //     disability_number: "",
    //   })
    // },
    // // 兵役表新增
    // soldAdd () {
    //   this.personnelData.soldInfo.push({
    //     sold_name: "",
    //     mark: "1",
    //     enlistment: "",
    //     retirement: "",
    //     JoinWar: "",
    //     military_disability_type: "",
    //   })
    // },
    // 人员表删除
    infoDelete (scope) {
      if (scope.row.propertyid) {
        this.$confirm("确认删除此类容?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          this.$axios.post("/api/user/delCommunity", { personnel_id: scope.row.personnel_id })
            .then((response) => {
              if (response.data.success) {
                this.$message({ message: "删除成功", type: "success" })
                this.personnelData.communityInfo.splice(scope.$index, 1)
              }
              else {
                this.$alert(response.data.result, "删除出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "删除出错")
            })
        })
      } else {
        this.personnelData.communityInfo.splice(scope.$index, 1)
      }
    },
    // 民生表表删除
    deformDelete (scope) {
      if (scope.row.deformit_id) {
        this.$confirm("确认删除此类容?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          this.$axios.post("/api/user/delDeformity", { deformit_id: scope.row.deformit_id })
            .then((response) => {
              if (response.data.success) {
                this.$message({ message: "删除成功", type: "success" })
                this.personnelData.deformInfo.splice(scope.$index, 1)
              }
              else {
                this.$alert(response.data.result, "删除出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "删除出错")
            })
        })
      } else {
        this.personnelData.deformInfo.splice(scope.$index, 1)

      }
    },
    // 兵役表删除
    soldDelete (scope) {
      if (scope.row.sold_id) {
        this.$confirm("确认删除此类容?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          this.$axios.post("/api/user/delSoldier", { sold_id: scope.row.sold_id })
            .then((response) => {
              if (response.data.success) {
                this.$message({ message: "删除成功", type: "success" })
                this.personnelData.soldInfo.splice(scope.$index, 1)
              }
              else {
                this.$alert(response.data.result, "删除出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "删除出错")
            })
        })
      } else {
        this.personnelData.soldInfo.splice(scope.$index, 1)

      }
    }
  }
}
</script>