<!--
系统日志查询
最后编辑人：张鹏
最后编辑时间：2023-1-6
最后编辑细节：增加重置按钮
-->
<template>
  <el-container class="base-container">
    <el-header class="base-height">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>系统日志</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-container class="main-container" ref="mainSector">
      <el-main>
        <el-row>
          <el-col :span="3" class="input-search">
            <el-input v-model="searchData.url" placeholder="输入请求地址" clearable @clear="loadData" @keyup.enter="loadData"></el-input>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-input v-model="searchData.acc" placeholder="输入用户姓名" clearable @clear="loadData" @keyup.enter="loadData"></el-input>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-input v-model="searchData.rp" placeholder="输入参数内容" clearable @clear="loadData" @keyup.enter="loadData"></el-input>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-select v-model="searchData.module" placeholder="请选择操作端" clearable @clear="loadData"  @change="loadData">
              <el-option value="">全部</el-option>
              <el-option value="web" label="web"></el-option>
              <el-option value="微信" label="微信"></el-option>
            </el-select>
          </el-col>
          <el-col :span="8" class="input-search">
            <el-date-picker
                v-model="searchData.stime"
                type="datetime"
                format="YYYY-MM-DD hh:mm:ss"
                value-format="YYYY-MM-DD hh:mm:ss"
                placeholder="选择起始时间"
                style="width: 48%; margin: 0 8px">
            </el-date-picker>
            <el-date-picker
                v-model="searchData.etime"
                type="datetime"
                format="YYYY-MM-DD hh:mm:ss"
                value-format="YYYY-MM-DD hh:mm:ss"
                placeholder="选择结束时间"
                style="width: 48%;">
            </el-date-picker>
          </el-col>
          <el-col :span="4">
            <el-button
                type="primary"
                @click="loadData">查询</el-button>
            <el-button
                size="default"
                @click="toReset">重置</el-button>
          </el-col>
        </el-row>

        <el-table
            :height="tableHeight"
            :data="logData">
          <el-table-column
              label="请求时间"
              min-width="100">
            <template #default="scope">
              <span style="cursor: pointer;" @click="selectRow(scope.row)">{{ scope.row.REQUEST_TIME }}</span>
            </template>
          </el-table-column>
          <el-table-column
              label="请求地址"
              min-width="150">
            <template #default="scope">
              <el-button type="text" size="default" @click="urlLog(scope.row)">{{ scope.row.REQUEST_URL }}</el-button>
            </template>
          </el-table-column>
          <el-table-column
              label="客户端IP"
              min-width="100">
            <template #default="scope">
              <span style="cursor: pointer;" @click="selectRow(scope.row)">{{ scope.row.IP_ADDRESS }}</span>
            </template>
          </el-table-column>
          <el-table-column
              label="操作用户名"
              min-width="100">
            <template #default="scope">
              <el-button type="text" size="default" @click="accLog(scope.row)">{{ scope.row.USER_NAME }}</el-button>
            </template>
          </el-table-column>
          <el-table-column
              label="操作端"
              min-width="100">
            <template #default="scope">
              <el-button type="text" size="default" @click="moduleLog(scope.row)">{{ scope.row.MODULE }}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            background
            v-model:current-page="searchData.page"
            v-model:page-size="searchData.pagesize"
            @current-change="loadData"
            @size-change="loadData"
            :page-sizes="[10, 25, 50]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="dataTotal"
            style="margin-top: 10px;">
        </el-pagination>

        <el-drawer
            title="日志内容"
            v-model="drawVisi"
            :close-on-press-escape="false"
            :size="800">
          <el-descriptions
              :column="2"
              border
              width="100px"
              min-width="100px">
            <el-descriptions-item label="日志号">{{ logInfo.LOG_ID }}</el-descriptions-item>
            <el-descriptions-item label="是否成功">{{ logInfo.PROC_SUCCESS === "1" ? "是" : "否" }}</el-descriptions-item>
            <el-descriptions-item label="操作用户">{{ logInfo.USER_NAME }}</el-descriptions-item>
            <el-descriptions-item label="客户端IP">{{ logInfo.IP_ADDRESS }}</el-descriptions-item>
            <el-descriptions-item label="请求时间">{{ logInfo.REQUEST_TIME }}</el-descriptions-item>
            <el-descriptions-item label="处理耗时">{{ logInfo.TAKE_TIME }}</el-descriptions-item>

            <el-descriptions-item :width="100" :span="2" label="请求地址"><div class="large-content">{{ logInfo.REQUEST_URL }}</div></el-descriptions-item>
            <el-descriptions-item :span="2" label="代理字符串"><div class="large-content">{{ logInfo.USER_AGENT }}</div></el-descriptions-item>
            <el-descriptions-item :span="2" label="传入参数"><div class="large-content">
              {{ logInfo.REQUEST_PARAMS == null ? "" : logInfo.REQUEST_PARAMS.replaceAll('\\"', '"') }}
            </div></el-descriptions-item>
            <el-descriptions-item :span="2" label="返回内容"><div class="large-content">{{ logInfo.RETURN_CONTENT }}</div></el-descriptions-item>
          </el-descriptions>
        </el-drawer>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: "SysLog",
  data () {
    return {
      searchData: {
        type: "1",
        url: "",
        acc: "",
        stime: "",
        etime: "",
        rp: "",
        module:"",
        page: 1,
        pagesize: 10,
      },
      dataTotal: 0,
      logData: [],
      tableHeight: 0,
      logInfo: {
        LOG_ID: "",
        REQUEST_TIME: "",
        RETURN_TIME: "",
        IP_ADDRESS: "",
        USER_AGENT: "",
        REQUEST_URL: "",
        REQUEST_PARAMS: "",
        RETURN_CONTENT: "",
        PROC_SUCCESS: "",
        TAKE_TIME: "",
        USER_ID: "",
        USER_NAME: "",
        MODULE: ""
      },
      drawVisi: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.calcTableHeight()
      window.addEventListener("resize", this.calcTableHeight)
      this.loadData()
    })
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calcTableHeight)
  },
  methods: {
    calcTableHeight () {
      let mainHeight = window.getComputedStyle(this.$refs.mainSector.$el).height
      if (mainHeight.indexOf("px") !== -1)
        mainHeight = Number(mainHeight.substring(0, mainHeight.indexOf("px")))
      else
        mainHeight = Number(mainHeight)
      this.tableHeight = mainHeight - 120
    },
    //载入日志
    loadData () {
      this.$axios.post("/api/log/querysyslog", this.searchData)
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.dataTotal = data.result.totalrecords
              this.logData = data.result.datalist
            }
            else {
              this.$alert(data.result, "查询日志出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "查询日志出错")
          })
    },
    toReset() {
      this.searchData.url = ""
      this.searchData.acc = ""
      this.searchData.stime = ""
      this.searchData.etime = ""
      this.searchData.rp = ""
      this.searchData.module = ""
      this.loadData()
    },
    //查看指定用户日志
    accLog (data) {
      this.searchData.acc = data.USER_NAME
      this.searchData.url = ""
      this.loadData()
    },
    //查看指定请求地址
    urlLog (data) {
      this.searchData.url = data.REQUEST_URL
      this.searchData.acc = ""
      this.loadData()
    },
    //查看指定操作端
    moduleLog (data) {
      this.searchData.module = data.MODULE
      this.loadData()
    },
    //选中日志查看详情
    selectRow (data) {
      this.logInfo = data
      this.drawVisi = true
    }
  }
}
</script>

<style scoped>
.large-content {
  word-break: break-all;
  overflow-wrap: anywhere;
}

</style>
