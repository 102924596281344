<!--
登录日志查询
最后编辑人：张鹏
最后编辑时间：2023-1-6
最后编辑细节：增加重置按钮
-->
<template>
  <el-container class="base-container">
    <el-header class="base-height">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>登录日志</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-container class="main-container" ref="mainSector">
      <el-main>
        <el-row>
          <el-col :span="4" class="input-search">
            <el-input v-model="searchData.acc" placeholder="输入用户账号" clearable @clear="loadData" @keyup.enter="loadData"></el-input>
          </el-col>
          <el-col :span="4" class="input-search">
            <el-select v-model="searchData.module" placeholder="请选择操作端" clearable @clear="loadData"  @change="loadData">
              <el-option value="">全部</el-option>
              <el-option value="web" label="web"></el-option>
              <el-option value="微信" label="微信"></el-option>
            </el-select>
          </el-col>
          <el-col :span="8">
            <el-date-picker
                v-model="searchData.stime"
                type="datetime"
                format="YYYY-MM-DD hh:mm:ss"
                value-format="YYYY-MM-DD hh:mm:ss"
                placeholder="选择起始时间"
                style="width: 48%; margin: 0 8px">
            </el-date-picker>
            <el-date-picker
                v-model="searchData.etime"
                type="datetime"
                format="YYYY-MM-DD hh:mm:ss"
                value-format="YYYY-MM-DD hh:mm:ss"
                placeholder="选择结束时间"
                style="width: 48%;">
            </el-date-picker>
          </el-col>
          <el-col :span="4">
            <el-button
                type="primary"
                @click="loadData">查询</el-button>
            <el-button
                size="default"
                @click="toReset">重置</el-button>
          </el-col>
        </el-row>

        <el-table
            :height="tableHeight"
            :data="logData">
          <el-table-column
              label="请求时间"
              min-width="100">
            <template  #default="scope">
              <span style="cursor: pointer;" @click="selectRow(scope.row)">{{ scope.row.REQUEST_TIME }}</span>
            </template>
          </el-table-column>
          <el-table-column
              label="操作用户名"
              min-width="100">
            <template  #default="scope">
              <el-button type="text" size="medium" @click="accLog(scope.row)">{{ scope.row.LOGIN_ACCOUNT }}</el-button>
            </template>
          </el-table-column>
          <el-table-column
              label="登录类型"
              min-width="100">
            <template  #default="scope">
              <span style="cursor: pointer;" @click="selectRow(scope.row)">{{ scope.row.LOGIN_TYPE === "1" ? "登录" : "登出" }}</span>
            </template>
          </el-table-column>
          <el-table-column
              label="客户端IP"
              min-width="100">
            <template  #default="scope">
              <span style="cursor: pointer;" @click="selectRow(scope.row)">{{ scope.row.IP_ADDRESS }}</span>
            </template>
          </el-table-column>
          <el-table-column
              label="操作端"
              min-width="100">
            <template #default="scope">
              <el-button type="text" size="default" @click="moduleLog(scope.row)">{{ scope.row.MODULE }}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            background
            v-model:current-page="searchData.page"
            v-model:page-size="searchData.pagesize"
            @current-change="loadData"
            @size-change="loadData"
            :page-sizes="[10, 25, 50]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="dataTotal"
            style="margin-top: 10px;">
        </el-pagination>

        <el-drawer
            title="日志内容"
            v-model="drawVisi"
            :close-on-press-escape="false"
            :size="800">
          <el-descriptions
              :column="2"
              border>
            <el-descriptions-item :width="120" label="日志号">{{ logInfo.LOG_ID }}</el-descriptions-item>
            <el-descriptions-item label="是否成功">{{ logInfo.PROC_SUCCESS === "1" ? "是" : "否" }}</el-descriptions-item>
            <el-descriptions-item label="操作用户">{{ logInfo.LOGIN_ACCOUNT }}</el-descriptions-item>
            <el-descriptions-item label="客户端IP">{{ logInfo.IP_ADDRESS }}</el-descriptions-item>
            <el-descriptions-item label="请求时间">{{ logInfo.REQUEST_TIME }}</el-descriptions-item>
            <el-descriptions-item label="处理耗时">{{ logInfo.TAKE_TIME }}</el-descriptions-item>
            <el-descriptions-item label="操作类型">{{ logInfo.LOGIN_TYPE === "1" ? "登录" : "登出" }}</el-descriptions-item>
            <el-descriptions-item label="操作端">{{ logInfo.MODULE }}</el-descriptions-item>
            <el-descriptions-item :width="100" :span="2" label="代理字符串"><div class="large-content">{{ logInfo.USER_AGENT }}</div></el-descriptions-item>
            <el-descriptions-item :span="2" label="传入参数"><div class="large-content">{{ logInfo.REQUEST_PARAMS }}</div></el-descriptions-item>
            <el-descriptions-item :span="2" label="返回内容"><div class="large-content">{{ logInfo.RETURN_CONTENT }}</div></el-descriptions-item>
          </el-descriptions>
        </el-drawer>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: "LoginLog",
  data () {
    return {
      searchData: {
        acc: "",
        stime: "",
        etime: "",
        module:"",
        page: 1,
        pagesize: 10,
      },
      dataTotal: 0,
      logData: [],
      tableHeight: 0,
      logInfo: {
        LOG_ID: "",
        REQUEST_TIME: "",
        IP_ADDRESS: "",
        USER_AGENT: "",
        LOGIN_TYPE: "",
        REQUEST_PARAMS: "",
        RETURN_CONTENT: "",
        PROC_SUCCESS: "",
        TAKE_TIME: "",
        USER_ID: "",
        LOGIN_ACCOUNT: "",
        MODULE: ""
      },
      drawVisi: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.calcTableHeight()
      window.addEventListener("resize", this.calcTableHeight)
      this.loadData()
    })
  },
  beforeUnmount () {
    window.removeEventListener("resize", this.calcTableHeight)
  },
  methods: {
    calcTableHeight () {
      let mainHeight = window.getComputedStyle(this.$refs.mainSector.$el).height
      if (mainHeight.indexOf("px") !== -1)
        mainHeight = Number(mainHeight.substring(0, mainHeight.indexOf("px")))
      else
        mainHeight = Number(mainHeight)
      this.tableHeight = mainHeight - 120
    },

    loadData () {
      this.$axios.post("/api/log/queryloginlog", this.searchData)
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.dataTotal = data.result.totalrecords
              this.logData = data.result.datalist
            }
            else {
              this.$alert(data.result, "查询日志出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "查询日志出错")
          })
    },
    toReset() {
      this.searchData.acc = ""
      this.searchData.stime = ""
      this.searchData.etime = ""
      this.searchData.module = ""
      this.loadData()
    },
    //查看指定用户日志
    accLog (data) {
      this.searchData.acc = data.LOGIN_ACCOUNT
      this.loadData()
    },
    //查看指定操作端
    moduleLog (data) {
      this.searchData.module = data.MODULE
      this.loadData()
    },
    //选中日志查看详情
    selectRow (data) {
      this.logInfo = data
      this.drawVisi = true
    }
  }
}
</script>

<style scoped>
.large-content {
  word-break: break-all;
  overflow-wrap: anywhere;
}
</style>
