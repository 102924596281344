<template>
  <el-container class="base-container">
    <el-header class="base-height">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>数据管理</el-breadcrumb-item>
        <el-breadcrumb-item>字典管理</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-container class="main-container" ref="mainSector">
      <el-aside width="50%" id="permissionTree" style="padding: 20px;">
        <el-row>
          <el-col :span="7">
            <el-button type="success" @click="toAdd(null)">
              <el-icon><FolderAdd /></el-icon><span class="icon-right">新增代码</span>
            </el-button>
          </el-col>
          <el-col :span="17" class="input-search">
            <el-input v-model="typeName" placeholder="请输入类型名称" prefix-icon="Search" clearable size="default" style="width: 60%;"></el-input>
          </el-col>
        </el-row>
        <el-divider style="margin: 15px 0;"/>
        <div style="height: calc(100% - 80px); overflow-y: auto; overflow-x: hidden;">
          <el-tree
              :data="treeData"
              :props="treeProps"
              node-key="CODE_ID"
              draggable
              :filter-node-method="filterNode"
              :expand-on-click-node="false"
              :default-expand-all="expanded"
              accordion
              ref="codeTree">
            <template  #default="{ node, data }">
              <span @click="toEdit(data)">
                <el-icon v-if="data.codeid != null"><Connection /></el-icon>
                <el-icon v-else><FolderOpened /></el-icon>
                {{ node.label }}
              </span>
              <span style="text-align: right;position: absolute;right: 0;">
                <el-button-group>
                  <el-tooltip content="上移" placement="top" effect="light" transition="" :enterable="false">
                    <el-button type="primary" size="small" class="tree-button" @click="moveUp(data)" v-show="data.codeid != null" ><el-icon><Top /></el-icon></el-button>
                  </el-tooltip>
                  <el-tooltip content="下移" placement="top" effect="light" transition="" :enterable="false">
                    <el-button type="primary" size="small" class="tree-button" @click="moveDown(data)" v-show="data.codeid != null"><el-icon><Bottom /></el-icon></el-button>
                  </el-tooltip>
                  <el-tooltip content="新增子代码" placement="top" effect="light" transition="" :enterable="false">
                    <el-button type="success" size="small" class="tree-button" @click="toAdd(data)" v-show="data.codeid == null"><el-icon><Plus /></el-icon></el-button>
                  </el-tooltip>
                  <el-tooltip content="删除" placement="top" effect="light" transition="" :enterable="false">
                    <el-button type="danger" size="small" class="tree-button" @click="toDel(data)" v-show="data.codeid != null"><el-icon><DeleteFilled /></el-icon></el-button>
                  </el-tooltip>
                </el-button-group>
              </span>
            </template >
          </el-tree>
        </div>
      </el-aside>
      <el-main style="background-color: #FBFBF9; border-left: 1px solid #EBEEF5;">
        <el-row>
          <el-col :span="24"><div style="padding: 7px 15px">{{ formTitle }}</div></el-col>
        </el-row>
        <el-form :model="codeData" :rules="rules" ref="codeForm" label-width="100px">
          <el-form-item label="类型代码" prop="type_code">
            <el-input v-model="codeData.type_code" :disabled="unedit"></el-input>
          </el-form-item>
          <el-form-item label="类型名称" prop="type_name">
            <el-input v-model="codeData.type_name" :disabled="unedit"></el-input>
          </el-form-item>
          <el-form-item label="代码值" prop="code">
            <el-input v-model="codeData.code" :disabled="unedit"></el-input>
          </el-form-item>
          <el-form-item label="代码名称" prop="code_name">
            <el-input v-model="codeData.code_name" :disabled="unedit"></el-input>
          </el-form-item>
          <div class="div-button">
            <el-button type="primary" @click="toSave" :disabled="unedit">保 存</el-button>
          </div>
        </el-form>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: "CodeMge",
  data () {
    return {
      expanded:true,
      unedit: true,
      typeName: "",
      treeData: [],
      treeProps: {
        children: 'subcode',
        label: 'label'
      },
      codeData:{
        code_id:"",
        type_code:"",
        type_name:"",
        code:"",
        code_name:"",
      },
      formTitle: "代码",
      rules: {
        type_code: [{required: true, message: "请填写类型代码", trigger: "blur"}],
        type_name: [{required: true, message: "请选择类型名称", trigger: "change"}],
        code: [{required: true, message: "请填写代码值", trigger: "blur"}],
        code_name: [{validator: true,  message: "请填写代码名称", trigger: "blur"}]
      },
    }
  },
  watch: {
    typeName (val) {
      this.$refs.codeTree.filter(val)
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    //查找树节点
    filterNode(value, data) {
      if (!value) return true;
      return data.label.includes(value);
    },
    //载入全部代码树形列表
    loadData() {
      this.$axios.get("/api/code/loadallcode")
          .then((response) => {
            if (response.data.success) {
              this.treeData = response.data.result
              if (this.typeName !== "")
                setTimeout(() => {
                  this.$refs.codeTree.filter(this.typeName)
                }, 600)
            } else {
              this.$alert(response.data.result, "获取代码列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取代码列表出错")
          })
    },
    //代码上移
    moveUp(data) {
      this.$axios.post("/api/code/moveupcode", {
        codeid: data.codeid,
        code: data.code,
        typecode: data.typecode
      })
        .then((response) => {
          if (response.data.success) {
            this.$message({message: "上移成功", type: "success"})
            this.loadData()
          } else {
            this.$alert(response.data.result, "代码上移出错")
          }
        })
        .catch((error) => {
          this.$alert(error, "代码上移出错")
        })
    },
    //代码下移
    moveDown(data) {
      this.$axios.post("/api/code/movedowncode", {
        codeid: data.codeid,
        code: data.code,
        typecode: data.typecode
      })
        .then((response) => {
          if (response.data.success) {
            this.$message({message: "下移成功", type: "success"})
            this.loadData()
          } else {
            this.$alert(response.data.result, "代码下移出错")
          }
        })
        .catch((error) => {
          this.$alert(error, "代码下移出错")
        })
    },
    //代码新增
    toAdd(data) {
      this.$refs["codeForm"].resetFields()
      this.unedit = false
      this.codeData.code_id = ""
      this.formTitle = "新增代码"
      if (data === null) {
        this.codeData.type_code = ""
        this.codeData.type_name = ""
        this.codeData.code = ""
        this.codeData.code_name = ""
      } else {
        this.codeData.type_code = data.typecode
        this.codeData.type_name = data.label
        if (typeof data.subcode !== "undefined") {
          let co = 1
          const sub = data.subcode
          sub.forEach(perm => {
            if (parseInt(perm.code) >= co)
              co = parseInt(perm.code) + 1
          })
          this.codeData.code = co
        }
        this.codeData.code_name = ""
      }
    },
    //代码编辑
    toEdit(node) {
      if(node.codeid != null){
        this.$refs["codeForm"].resetFields()
        this.unedit = false
        this.codeData.code_id = node.codeid
        this.codeData.type_code = node.typecode
        this.codeData.type_name = node.typename
        this.codeData.code = node.code
        this.codeData.code_name = node.label
      }else{
        this.unedit = true
        this.codeData.code_id = ""
        this.codeData.type_code = node.typecode
        this.codeData.type_name = node.label
        this.codeData.code = ""
        this.codeData.code_name = ""
      }
      this.formTitle = "编辑代码"
    },
    //代码删除
    toDel(data) {
      this.$confirm("确认删除此代码吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$axios.post("/api/code/delcode", {codeid: data.codeid})
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "代码删除成功", type: "success"})

                this.loadData()
              } else {
                this.$alert(response.data.result, "代码删除出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "代码删除出错")
            })
      })
    },
    //代码保存
    toSave() {
      this.$refs["codeForm"].validate((valid) => {
        if (valid) {
          this.$axios.post("/api/code/savecode", this.codeData)
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "代码保存成功", type: "success"})
                  this.$refs["codeForm"].resetFields()
                  this.codeData.code_id = ""
                  this.codeData.type_code = ""
                  this.codeData.type_name = ""
                  this.codeData.code = ""
                  this.codeData.code_name = ""
                  this.loadData()
                } else {
                  this.$alert(response.data.result, "代码保存出错")
                }
              })
              .catch((error) => {
                this.$alert(error, "代码保存出错")
              })
        } else
          return false
      })
    }
  }

}
</script>

<style scoped>

</style>
