<!--
用户管理
最后编辑人：彭飞洪
最后编辑时间：2024-8-13
最后编辑细节：增加查询
-->
<template>
  <el-container class="base-container">
    <el-container class="main-container" ref="mainSector">
      <el-aside width="20%" style="padding: 20px 5px 0 20px;">
        <div style="display: flex;">
          <span>网格机构</span>
          <span style="margin-left: auto; margin-right: 10px">
            <el-tooltip effect="dark" content="展开/收起" placement="top">
              <el-link :underline="false" @click="toggleRowExpansion()">
                <el-icon :size="20">
                  <Sort />
                </el-icon>
              </el-link>
            </el-tooltip>
          </span>
        </div>
        <el-divider style="margin: 15px 0;" />
        <div style="height: calc(100% - 65px); overflow: auto;">
          <el-tree :data="treeData" :props="treeProps" node-key="value" :default-expand-all="expanded" :expand-on-click-node="false" ref="organTree" v-loading="treelding">
            <template #default="{ node, data }">
              <!-- <span @click="toShowUser(data)" v-if="data.type === 'floor'">
                <i class="iconfont icon-tree-department-1" style="font-size: 18px;"></i>
                {{ node.label +`(${data.leng}人)` }}
              </span> -->
              <span @click="toShowUser(data)">
                <i class="iconfont icon-tree-department-1" style="font-size: 18px;"></i>
                {{ node.label }}
              </span>
            </template>
          </el-tree>
        </div>
      </el-aside>
      <el-main style="height: 100%; border-left: 10px solid #f5f5f5; overflow:hidden;">
        <el-row>
          <!-- <el-col :span="3">
            <el-button type="success" @click="toAddUser()">
              <el-icon>
                <Plus />
              </el-icon><span class="icon-right">新建住户</span>
            </el-button>
          </el-col> -->
          <el-col :span="4" class="input-search">
            <el-input v-model="search.personnel_name" placeholder="请输入关键字" @keyup.enter="loadUser" @clear="loadUser" clearable size="default"></el-input>
          </el-col>
          <el-col :span="3" class="input-search" style="align-items: center;display:flex;">输入年龄区间:</el-col>
          <el-col :span="2" class="input-search">
            <el-input v-model.number="search.start" type="number" placeholder="请输入年龄" @clear="loadUser" clearable size="default"></el-input>
          </el-col>
          <el-col :span="2" class="input-search">
            <el-input v-model.number="search.ending" type="number" placeholder="请输入年龄" @clear="loadUser" clearable size="default"></el-input>
          </el-col>
          <el-col :span="3">
            <el-button type="primary" size="default" @click="loadUser">查询</el-button>
            <!-- <el-button type="primary" size="default" @click="querInfo">清洗出生日期</el-button> -->
            <el-button size="default" @click="toReset">重置</el-button>
          </el-col>
          <el-col :span="5" class="input-search">
            <el-select v-model="search.queryPersonnel" placeholder="请选择" @change="optionsChange" clearable>
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="5" style="text-align: right;">
            <el-tooltip effect="dark" content="刷新" placement="top">
              <el-link :underline="false" @click="toRefresh()">
                <el-icon :size="20">
                  <Refresh />
                </el-icon>
              </el-link>
            </el-tooltip>
          </el-col>
        </el-row>
        <el-divider style="margin: 6px 0;" />
        <div style="padding:5px;">
          <el-table :height="tableHeight" :data="userListData" style="width: 100%" v-loading="loading">
            <template v-slot:empty>
              <el-empty description="暂无数据" :image-size="120"></el-empty>
            </template>
            <el-table-column prop="personnel_name" label="姓名" show-overflow-tooltip align="center">
            </el-table-column>
            <el-table-column prop="phone" label="电话" show-overflow-tooltip align="center" width="150px">
            </el-table-column>
            <el-table-column prop="landscape" label="政治面貌" show-overflow-tooltip align="center">
            </el-table-column>
            <el-table-column prop="nation" label="名族" show-overflow-tooltip align="center" width="100px">
            </el-table-column>
            <el-table-column prop="sex" label="性别" show-overflow-tooltip align="center" width="100px">
            </el-table-column>
            <el-table-column prop="FLOOR_NAME" label="所属楼层" show-overflow-tooltip align="center" width="400px">
            </el-table-column>
            <el-table-column prop="property_name" label="所属名下户主名称" show-overflow-tooltip align="center" width="400px">
            </el-table-column>
            <el-table-column fixed="right" label="操作" min-width="100px" align="center">
              <template #default="scope">
                <el-button class="table-button" size="small" type="primary" @click="toEdit(scope.row)">编辑</el-button>
                <!-- <el-button class="table-button" size="small" type="danger" @click="toDel(scope.row)">删除</el-button> -->
              </template>
            </el-table-column>
          </el-table>
          <el-pagination background v-model:current-page="search.page" v-model:page-size="search.pagesize" layout="total, prev, pager, next, jumper" @current-change="loadUser" :total="total"
                         style="margin-top: 10px;">
          </el-pagination>
        </div>
        <!-- 详细信息编辑 -->
        <el-dialog width="1500px" :title="UserTitle" v-model="dailVisi" :close-on-click-modal="false" :close-on-press-escape="false">
          <div style="height: calc(100% - 100px); overflow: auto;">
            <Information :personnelData="personnelData" :treeData="treeData"></Information>
            <div class="div-button">
              <el-button type="primary" @click="toSave">保 存</el-button>
            </div>
          </div>
        </el-dialog>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Information from "@/components/information/information.vue"
export default {
  name: "UserMge",
  components: {
    Information
  },
  data () {
    return {
      expanded: false,
      treeData: [],
      treeProps: {
        label: 'text', value: 'value', children: 'children',
      },
      options: [{
        value: 'physical',
        label: '查询残疾人'
      }, {
        value: 'military',
        label: '查询兵役人'
      }],
      search: {
        page: 1,
        pagesize: 17,
        unitid: "",
        building: "",
        organid: "",
        department: "",
        floorid: "",
        personnel_name: ""
      },
      loading: false,
      treelding: true,
      tableHeight: 0,
      userListData: [],
      total: 0,
      UserTitle: "",
      dailVisi: false,
      props: {
        label: 'text', value: 'value', children: 'children',
        emitPath: false,
      },
      personnelData: {
        FLOOR_ID: "",
        property_name: "",
        mark: "1",
        propertyid: "",
        register: "",
        property: [],
        specialName: "",
        opinion: "",
        familyType: [],
        remarks: "",
        communityInfo: [{
          personnel_name: "",
          mark: "1",
          sex: "",
          nation: "",
          card: "",
          landscape: "",
          residence: "",
          health: "",
          culture: "",
          religious: "",
          phone: "",
          working: "",
          marriage: "",
          marriageTime: "",
          childTime: "",
          childNum: "",
          employment: "",
          workers: "",
          society: "",
          resident: "",
          residentInsurance: "",
          advanced: "",
          disability_level: "",
          disability_type: "",
          disability_number: "",
          enlistment: "",
          retirement: "",
          JoinWar: "",
          military_disability_type: "",
        }],
      },
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.calcTableHeight()
      window.removeEventListener("resize", this.calcTableHeight)
      window.addEventListener("resize", this.calcTableHeight)
      this.loadData()
      this.loadUser()
    })
  },
  beforeUnmount () {
    window.removeEventListener("resize", this.calcTableHeight)
  },
  methods: {
    // 保存
    toSave () {
      console.log(this.personnelData);
      this.$axios.post("/api/common/saveInformation", this.personnelData)
        .then((response) => {
          if (response.data.success) {
            this.$message({ message: "保存成功", type: "success" })
            this.dailVisi = false
            this.loadUser()
          } else {
            this.$alert(response.data.result, "保存出错")
          }
        })
        .catch((error) => {
          this.$alert(error, "保存出错")
        })
    },
    // 清洗年月日
    querInfo () {
      this.$axios.get("/api/common/updataBirth")
        .then((response) => {
          if (response.data.success) {
            this.$message({ message: "清洗成功", type: "success" })
          } else {
            this.$alert(response.data.result, "清洗失败")
          }
        })
        .catch((error) => {
          this.$alert(error, "清洗失败")
        })
    },
    // 编辑
    toEdit (data) {
      console.log(data);
      this.personnelData = data
      this.personnelData.mark = "2"
      if (data.card.length >= 18) {
        data.birth = data.card.substring(6, 10) + "-" + data.card.substring(10, 12) + "-" + data.card.substring(12, 14)
      }
      this.UserTitle = "详细信息编辑"
      this.dailVisi = true
      if (this.$refs.userForm)
        this.$refs.userForm.clearValidate()
    },
    // 获取网格树
    loadData () {
      this.$axios.get("/api/common/getorgantree")
        .then((response) => {
          const data = response.data
          if (data.success) {
            this.treeData = data.result
            this.treelding = false
            this.treeData.forEach(item => {
              if (item.type === "company" && item.children.length === 0) {
                item["disabled"] = true
              } else {
                item.children.forEach(i => {
                  if (i.type === "company" && i.children.length === 0) {
                    i["disabled"] = true
                  }
                })
              }
            })
          } else {
            this.$alert(response.data.result, "获取网格列表出错")
          }
        })
        .catch((error) => {
          this.$alert(error, "获取网格列表出错")
        })
    },
    toShowUser (node) {
      console.log(node);
      if (node.type === "unit") {
        this.search.unitid = node.UNIT_ID
        this.search.building = ""
        this.search.organid = ""
        this.search.department = ""
        this.search.floorid = ""
      }
      if (node.type === "buliding") {
        this.search.building = node.BUILDING_ID
        this.search.unitid = ""
        this.search.organid = ""
        this.search.department = ""
        this.search.floorid = ""
      }
      if (node.type === "organ") {
        this.search.organid = node.ORGAN_ID
        this.search.building = ""
        this.search.unitid = ""
        this.search.department = ""
        this.search.floorid = ""
      }
      if (node.type === "department") {
        this.search.department = node.DEPARTMENT_ID
        this.search.building = ""
        this.search.unitid = ""
        this.search.floorid = ""
        this.search.organid = ""
      }
      if (node.type === "floor") {
        this.search.floorid = node.FLOOR_ID
        this.search.department = ""
        this.search.building = ""
        this.search.unitid = ""
        this.search.organid = ""
      }
      this.loadUser()
    },
    calcTableHeight () {
      let mainHeight = window.getComputedStyle(this.$refs.mainSector.$el).height
      if (mainHeight.indexOf("px") !== -1)
        mainHeight = Number(mainHeight.substring(0, mainHeight.indexOf("px")))
      else
        mainHeight = Number(mainHeight)
      this.tableHeight = mainHeight - 120
    },
    // 刷新
    toRefresh () {
      this.search.organid = ""
      this.search.depid = ""
      this.search.physical = ""
      this.search.military = ""
      this.search.personnel_name = ""
      this.search.start = ""
      this.search.ending = ""
      this.search.queryPersonnel = ""
      this.loadUser("refresh")
    },
    /** 展开收缩 */
    toggleRowExpansion () {
      const nodes = this.$refs.organTree.store._getAllNodes();
      nodes.forEach(item => {
        item.expanded = !item.expanded
      })
    },
    optionsChange (val) {
      this.search.physical = ""
      this.search.military = ""
      if (val === "physical") {
        this.search.physical = val
      }
      if (val === "military") {
        this.search.military = val
      }
      this.loadUser()
    },
    // 查询搜索
    loadUser (d) {
      this.loading = true
      this.$axios.post("/api/personnel/loaduserinfo", this.search)
        .then((response) => {
          if (response.data.success) {
            this.loading = false
            this.total = response.data.result.totalrecords
            this.userListData = response.data.result.datalist
            if (d === "refresh") {
              this.$message({ message: "刷新成功", type: "success" })
            }
          }
          else {
            this.$alert(response.data.result, "获取用户列表出错")
          }
        })
        .catch((error) => {
          this.$alert(error, "获取用户列表出错")
        })
    },
    // 重置
    toReset () {
      this.search.physical = ""
      this.search.military = ""
      this.search.personnel_name = ""
      this.search.start = ""
      this.search.ending = ""
      this.search.queryPersonnel = ""
      this.search.unitid = ""
      this.search.building = ""
      this.search.organid = ""
      this.search.department = ""
      this.search.floorid = ""
      this.loadUser()
    },

  }
}
</script>

<style scoped>
</style>
