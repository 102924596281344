<template>
  <el-container class="base-container">
    <el-header class="base-height">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>负债管理</el-breadcrumb-item>
        <el-breadcrumb-item>负债及利息管理</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-container class="main-container" ref="mainSector">
      <el-main>
        <el-row>
          <el-col :span="3" class="input-search">
            <el-button type="success" @click="toAdd()">
              <el-icon><Plus /></el-icon><span class="icon-right">新增</span>
            </el-button>
          </el-col>
          <el-col :span="4" class="input-search">
            <el-date-picker
                v-model="search.recordMonth"
                type="month"
                placeholder="选择年月"
                style="width: 100%"
                value-format="YYYY-MM"
                format="YYYY年MM月"
                @change="loadLiabilities()"
            />
          </el-col>
          <el-col :span="4" class="input-search">
            <el-cascader
                placeholder="请选择公司或部门"
                style="width: 100%;"
                v-model="search.organOrDep"
                :props="organprops"
                clearable
                :show-all-levels="false"
                @change="loadLiabilities($event)"
                :options="OrganTreeData" >
            </el-cascader>
          </el-col>
          <el-col :span="4" class="input-search">
            <el-select v-model="search.subjectName" placeholder="请选择科目名称" @change="loadLiabilities()" clearable style="width: 100%;">
              <el-option
                  v-for="item in subjectNameData"
                  :key="item.CODE_NAME"
                  :label="item.CODE_NAME"
                  :value="item.CODE_NAME">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4" class="input-search">
            <el-input v-model="search.arrearsOrgan" placeholder="请输入欠款单位名称" @keyup.enter="loadLiabilities" @clear="loadLiabilities" clearable size="default"></el-input>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-button type="success" @click="showProvision()">
              <el-icon><Finished /></el-icon><span class="icon-right">利息计提</span>
            </el-button>
          </el-col>
          <el-col :span="2" style="text-align: right;">
            <el-tooltip effect="dark" content="刷新" placement="top">
              <el-link :underline="false" @click="toRefresh()">
                <el-icon :size="20"><Refresh /></el-icon>
              </el-link>
            </el-tooltip>
          </el-col>
        </el-row>

        <el-table
            :height="tableHeight"
            :data="liabilitiesList">
          <el-table-column
              prop="SUBJECT_NAME"
              label="科目名称"
              align="center"
              show-overflow-tooltip
              min-width="50px"
          >
          </el-table-column>
          <el-table-column
              prop="LOAN_TIME"
              label="借款取得时间"
              align="center"
              show-overflow-tooltip
              min-width="120px"
          >
          </el-table-column>
          <el-table-column
              prop="ARREARS_ORGAN"
              label="欠款单位名称"
              show-overflow-tooltip
              align="center"
              min-width="80px"
          >
          </el-table-column>
          <el-table-column
              prop="LOAN_MONEY"
              label="借款本金"
              show-overflow-tooltip
              align="center"
              min-width="80px"
          >
          </el-table-column>

          <el-table-column
              prop="RETURN_MONEY"
              label="归还本金"
              show-overflow-tooltip
              align="center"
              min-width="80px"
          >
          </el-table-column>
          <el-table-column
              prop="RESIDUE_MONEY"
              label="剩余本金"
              show-overflow-tooltip
              align="center"
              min-width="70px"
          >
          </el-table-column>
          <el-table-column
              prop="ANNUAL_INTEREST_RATE"
              label="年利率(%)"
              show-overflow-tooltip
              align="center"
              min-width="60px"
          >
          </el-table-column>
          <el-table-column
              prop="MONTHLY_INTEREST_RATE"
              label="月利率(%)"
              show-overflow-tooltip
              align="center"
              min-width="60px"
          >
          </el-table-column>
          <el-table-column
              prop="monthly_interest"
              label="月付利息金额(元)"
              show-overflow-tooltip
              align="center"
              min-width="100px"
          >
          </el-table-column>
          <el-table-column
              fixed="right"
              label="操作"
              min-width="120px"
              align="center">
            <template #default="scope">
              <el-button class="table-button"
                         size="small"
                         type="primary"
                         v-if='scope.row.ORGAN_ID.toString() === this.organ_organid.toString()'
                         @click="toEdit(scope.row)">编辑</el-button>
              <el-button class="table-button"
                         size="small"
                         type="danger"
                         v-if='scope.row.ORGAN_ID.toString() === this.organ_organid.toString()'
                         @click="toDel(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            background
            v-model:current-page="search.page"
            v-model:page-size="search.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            @current-change="loadLiabilities"
            @size-change="loadLiabilities"
            :page-sizes="[10, 50, 100, 200]"
            :total="total"
            style="margin-top: 10px;"
        >
        </el-pagination>

        <el-dialog
            width="1000px"
            :title="title"
            v-model="dailVisi"
            :close-on-click-modal="false"
            :close-on-press-escape="false">
          <div style="height: calc(100% - 100px); overflow: auto;">
            <el-form
                :inline="true"
                :model="liabilitiesData"
                :rules="rules"
                ref="liabilitiesDataForm"
                label-width="130px"
            >
              <el-form-item label="科目名称:" prop="subjectName" class="form-col">
                <el-select v-model="liabilitiesData.subjectName" placeholder="请选择科目名称" style="width: 100%;">
                  <el-option
                      v-for="item in subjectNameData"
                      :key="item.CODE_NAME"
                      :label="item.CODE_NAME"
                      :value="item.CODE_NAME">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="借款取得时间:" prop="loanTime" class="form-col" >
                <el-date-picker
                    v-model="liabilitiesData.loanTime"
                    type="date"
                    placeholder="选择记录时间"
                    style="width: 100%"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                />
              </el-form-item>
              <el-form-item label="欠款单位名称:" prop="arrears_organ" class="form-col" >
                <el-input v-model="liabilitiesData.arrears_organ" />
              </el-form-item>

              <el-form-item label="借款本金:" prop="loan_money" class="form-col">
                <el-input v-model="liabilitiesData.loan_money"
                          :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                          :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')"
                          @blur="getResidueMoney">
                  <template #append>元</template>
                </el-input>
              </el-form-item>
              <el-form-item label="归还本金:" prop="return_money" class="form-col" >
                <el-input v-model="liabilitiesData.return_money"
                          :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                          :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')"
                          @blur="getResidueMoney">
                  <template #append>元</template>
                </el-input>
              </el-form-item>
              <el-form-item label="剩余本金:" prop="residue_money" class="form-col" >
                <el-input v-model="liabilitiesData.residue_money"
                          :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                          :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')">
                  <template #append>元</template>
                </el-input>
              </el-form-item>
              <el-form-item label="年利率:" prop="annual_interest_rate" class="form-col" >
                <el-input v-model="liabilitiesData.annual_interest_rate">
                  <template #append>%</template>
                </el-input>
              </el-form-item>
            </el-form>
            <div class="div-button">
              <el-button type="primary" @click="toSave" style="margin-right: 20px">保 存</el-button>
            </div>
          </div>
        </el-dialog>

        <el-dialog
            width="500px"
            title="请选择计提年月"
            v-model="dailVisiProvision"
            :close-on-click-modal="false"
            :close-on-press-escape="false">
          <el-date-picker
              v-model="ProvisionMonth"
              type="month"
              placeholder="选择年月"
              style="width: 100%"
              value-format="YYYY-MM"
              format="YYYY年MM月"

          />
          <div class="div-button">
            <el-button type="primary" @click="toProvision" style="margin-right: 20px">确认计提</el-button>
          </div>
        </el-dialog>

      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: "LiabilitiesMge",
  data() {
    return {
      organ_organid: sessionStorage.getItem("organ-organid"),
      tableHeight: 0,
      search:{
        recordMonth:"",
        subjectName:"",
        arrearsOrgan:"",
        organOrDep:"",
        s_organid:"",
        s_depid:"",
        page: 1,
        pagesize: 10,
      },
      OrganTreeData:[],
      organprops:{
        label:'text',value:'value',children:'children',
        checkStrictly: true,
        emitPath:false,
      },
      total: 0,
      liabilitiesList:[],
      title:"",
      dailVisi: false,
      subjectNameData:[],
      liabilitiesData:{
        liabilitiesId:"",
        subjectName:"",
        loanTime:"",
        arrears_organ:"",
        loan_money:"",
        return_money:"",
        residue_money: "",
        annual_interest_rate:"",
      },
      dailVisiProvision:false,
      ProvisionMonth:"",
      rules:{
        subjectName: [{required: true, message: "请选择科目名称", trigger: "blur"}],
        loanTime: [{required: true, message: "请选择借款取得时间", trigger: "blur"}],
        arrears_organ: [{required: true, message: "请填写欠款单位名称", trigger: "blur"}],
        annual_interest_rate: [{required: true, message: "请填写年率利", trigger: "blur"}],
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.calcTableHeight()
      window.removeEventListener("resize", this.calcTableHeight)
      window.addEventListener("resize", this.calcTableHeight)
      this.search.organOrDep = "company-"+sessionStorage.getItem("organ-organid")
      this.ProvisionMonth = this.current("month")
      this.loadTree()
      this.loadLiabilities()
    })
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calcTableHeight)
  },
  methods: {
    calcTableHeight() {
      let mainHeight = window.getComputedStyle(this.$refs.mainSector.$el).height
      if (mainHeight.indexOf("px") !== -1)
        mainHeight = Number(mainHeight.substring(0, mainHeight.indexOf("px")))
      else
        mainHeight = Number(mainHeight)
      this.tableHeight = mainHeight - 120
    },
    current(d) {
      var nowDate = new Date();
      var date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        day: nowDate.getDate()
      };
      if (d === "month")
        return date.year + "-" + (date.month >= 10 ? date.month : "0" + date.month);
      else if (d === "day")
        return date.year + "-" + (date.month >= 10 ? date.month : "0" + date.month) + "-" + (date.day >= 10 ? date.day : "0" + date.day);
    },
    loadTree(){
      this.$axios.get("/api/common/getqueryorgantree")
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.OrganTreeData = data.result
            } else {
              this.$alert(response.data.result, "获取组织列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取组织列表出错")
          })
      this.$axios.post("/api/common/getcodelists", {codes: ["FZGL"]})
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.subjectNameData = data.result.FZGL
            } else {
              this.$alert(response.data.result, "获取代码列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取代码列表出错")
          })
    },
    loadLiabilities(d) {
      if(this.search.organOrDep !== "" && this.search.organOrDep !== null){
        const parts = this.search.organOrDep.toString().split('-');
        if(parts[0] === "company"){
          this.search.s_organid = parts[1]
          this.search.s_depid = ""
        }else{
          this.search.s_organid = parts[0]
          this.search.s_depid = parts[1]
        }
      }else{
        this.search.s_organid = ""
        this.search.s_depid = ""
      }
      this.$axios.post("/api/liabilities/loadliabilitiesdata", this.search)
          .then((response) => {
            if (response.data.success) {
              this.total = response.data.result.totalrecords
              this.liabilitiesList = response.data.result.datalist
              if (d === "refresh") {
                this.$message({message: "刷新成功", type: "success"})
              }
            } else {
              this.$alert(response.data.result, "获取负债信息列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取负债信息列表出错")
          })
    },
    toRefresh() {
      this.loadLiabilities("refresh")
    },
    getResidueMoney() {
      if(this.liabilitiesData.loan_money !== ""){
        if(this.liabilitiesData.return_money === ""){
          this.liabilitiesData.return_money = 0
        }
        this.liabilitiesData.residue_money = Number(this.liabilitiesData.loan_money) - Number(this.liabilitiesData.return_money)
      }
    },
    toAdd() {
      this.title = "新增"
      this.dailVisi = true
      this.liabilitiesData.liabilitiesId = ""
      this.liabilitiesData.subjectName = ""
      this.liabilitiesData.loanTime = this.current("day")
      this.liabilitiesData.arrears_organ = ""
      this.liabilitiesData.loan_money = ""
      this.liabilitiesData.return_money = ""
      this.liabilitiesData.residue_money = ""
      this.liabilitiesData.annual_interest_rate = ""
      if (this.$refs.liabilitiesDataForm)
        this.$refs.liabilitiesDataForm.clearValidate()
    },
    toSave() {
      this.$refs["liabilitiesDataForm"].validate((valid) => {
        if (valid) {
          this.$axios.post("/api/liabilities/saveliabilitiesdata", this.liabilitiesData)
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "负债及利息信息录入成功", type: "success"})
                  this.dailVisi = false
                  this.loadLiabilities()
                } else {
                  this.$alert(response.data.result, "负债及利息信息录入出错")
                }
              })
              .catch((error) => {
                this.$alert(error, "负债及利息信息录入出错")
              })
        }
        else
          return false
      })
    },
    toEdit(data) {
      this.title = "编辑"
      this.dailVisi = true
      this.liabilitiesData.liabilitiesId = data.LIABILITIES_ID
      this.liabilitiesData.subjectName = data.SUBJECT_NAME
      this.liabilitiesData.loanTime = data.LOAN_TIME
      this.liabilitiesData.arrears_organ = data.ARREARS_ORGAN
      this.liabilitiesData.loan_money = data.LOAN_MONEY
      this.liabilitiesData.return_money = data.RETURN_MONEY
      this.liabilitiesData.residue_money = data.RESIDUE_MONEY
      this.liabilitiesData.annual_interest_rate = data.ANNUAL_INTEREST_RATE
      if (this.$refs.liabilitiesDataForm)
        this.$refs.liabilitiesDataForm.clearValidate()
    },
    toDel(data){
      this.$confirm("确认删除此负债及利息信息吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$axios.post("/api/liabilities/delliabilitiesdata", {liabilitiesId: data.LIABILITIES_ID})
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "删除成功", type: "success"})
                this.loadLiabilities()
              }
              else {
                this.$alert(response.data.result, "删除出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "删除出错")
            })
      })
    },
    showProvision(){
      this.dailVisiProvision =true
    },
    toProvision(){
      if(this.ProvisionMonth !== ""){
        this.$axios.post("/api/liabilities/liabilitiesProvision", {"provisionMonth":this.ProvisionMonth})
            .then((response) => {
              if (response.data.success) {
                this.dailVisiProvision =false
                this.$message({message: "利息计提成功", type: "success"})
              }
              else {
                this.$alert(response.data.result, "利息计提出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "利息计提出错")
            })
      }
    },
  }
}
</script>

<style scoped>

</style>
