<template>
  <el-container class="base-container">
    <el-header class="base-height">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>数据管理</el-breadcrumb-item>
        <el-breadcrumb-item>业务类型管理</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <Loading ref="loading"></Loading>
    <el-container class="main-container" ref="mainSector">
      <el-main style="height: 100%;  overflow:hidden;">
        <el-row>
          <el-col :span="6">
            <el-button type="success" @click="toAddBusiness()" style="margin-right: 15px;" v-if="this.userRole ==='0'">
              <el-icon><Plus /></el-icon><span class="icon-right">新建</span>
            </el-button>
            <el-dropdown v-if="this.userRole ==='0'">
              <el-button type="primary">
                <i class="iconfont icon-daoru" style="font-size: 14px"></i><span>导入</span>  <el-icon><ArrowDown /></el-icon>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item>
                    <el-link :href="href" target="_blank" type="primary" :underline="false" >
                      <el-icon><Download /></el-icon>下载模板
                    </el-link>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-upload
                        ref="uploadRef"
                        :action="uploadAction"
                        name="uploadfile"
                        :before-upload="beforeUpload"
                        :on-progress="uploadProgress"
                        :on-success="uploadSuccess"
                        :file-list="fileList"
                        :show-file-list="false"
                        :with-credentials="true"
                        :disabled="showProgress">
                      <div>
                        <el-tooltip effect="light" content="只能上传Excel(xls/xlsx)文件，且不超过100MB" placement="right">
                          <el-link target="_blank" type="warning" :underline="false" >
                            <el-icon><Upload /></el-icon>导入数据
                          </el-link>
                        </el-tooltip>
                      </div>
                    </el-upload>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <el-button type="primary" @click="doExport" :loading="exportTip !== ''" style="margin-left: 10px;">
              <span><i class="iconfont icon-daochu" style="font-size: 15px"></i>导出</span>
            </el-button>
            <a ref="expLink" target="_blank" style="display: none;"></a>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-select v-model="search.businessType" placeholder="请选择业务类型" @change="loadBusiness()" clearable>
              <el-option
                  v-for="item in businessTypeData"
                  :key="item.CODE"
                  :label="item.CODE_NAME"
                  :value="item.CODE">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-input v-model="search.businessAbstract" placeholder="请输入业务摘要" @keyup.enter="loadBusiness" @clear="loadBusiness" clearable size="default"></el-input>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-cascader
                placeholder="请选择科目"
                style="width: 100%;"
                v-model="search.subjectSort"
                :props="props"
                clearable
                @change="loadBusiness()"
                :options="subjectTree" >
            </el-cascader>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-input v-model="search.subjectDetail" placeholder="请输入科目明细" @keyup.enter="loadBusiness" @clear="loadBusiness" clearable size="default"></el-input>
          </el-col>
          <el-col :span="2" class="input-search">
            <el-select v-model="search.businessNature" placeholder="请选择业务属性" @change="loadBusiness()" clearable>
              <el-option
                  v-for="item in B_Nature"
                  :key="item.CODE"
                  :label="item.CODE_NAME"
                  :value="item.CODE">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="3">
            <el-button
                type="primary"
                size="default"
                @click="loadBusiness">查询</el-button>
            <el-button
                size="default"
                @click="toReset">重置</el-button>
          </el-col>
          <el-col :span="1" style="text-align: right;">
            <el-tooltip effect="dark" content="刷新" placement="top">
              <el-link :underline="false" @click="toRefresh()">
                <el-icon :size="20"><Refresh /></el-icon>
              </el-link>
            </el-tooltip>
          </el-col>
        </el-row>
        <div style="line-height: 20px; margin-top: 5px">
          <div v-if="elProgress" style="display: flex;flex-direction: row; align-items: center">
            <div style="width: 80px">
              <span style="font-size: 12px;">{{ progressInfo.text }}</span>
            </div>
            <div style="width: 90%">
              <el-progress :percentage="progressPercent"/>
            </div>
          </div>
          <div v-if="exportTip !== ''">
            <span style="font-size: 12px; margin-left: 80px">{{ exportTip }}</span>
          </div>
        </div>
        <div>
          <el-table
              :height="tableHeight"
              :data="businessList"
              ref="businessRef"
              @select-all="handleSelectAll"
              @select="handleSelectionChange">
            <el-table-column type="selection" width="55" />
            <el-table-column
                prop="BUSINESS_TYPE_NAME"
                label="业务类型"
                show-overflow-tooltip
                align="center"
                min-width="70px"
            >
            </el-table-column>
            <el-table-column
                prop="BUSINESS_CLASS_NAME"
                label="业务分类"
                show-overflow-tooltip
                align="center"
                min-width="70px"
            >
            </el-table-column>
            <el-table-column
                prop="BUSINESS_ABSTRACT"
                label="业务摘要"
                show-overflow-tooltip
                align="center"
                min-width="140px"
            >
            </el-table-column>
            <el-table-column
                prop="SUBJECT_NAME"
                label="会计科目"
                show-overflow-tooltip
                align="center"
                min-width="70px"
            >
            </el-table-column>
            <el-table-column
                prop="SUBJECT_DETAIL"
                label="明细科目"
                show-overflow-tooltip
                align="center"
                min-width="90px"
            >
            </el-table-column>
            <el-table-column
                prop="REV_EXP_TYPE_NAME"
                label="收支来源"
                show-overflow-tooltip
                align="center"
                min-width="90px"
            >
            </el-table-column>
            <el-table-column
                prop="REV_EXP_DETAIL"
                label="收支明细"
                show-overflow-tooltip
                align="center"
                min-width="60px"
            >
            </el-table-column>
            <el-table-column
                label="税率"
                show-overflow-tooltip
                align="center"
                min-width="50px"
            >
              <template #default="scope">
                <span v-if="scope.row.TAX_RATE !== null && scope.row.TAX_RATE !== ''" class="disable">
                  {{ scope.row.TAX_RATE }}%
                </span>
              </template>
            </el-table-column>
            <el-table-column
                label="使用部门"
                show-overflow-tooltip
                align="center"
                width="90px"
            >
              <template #default="scope">
                <el-popover effect="light" trigger="click" placement="left" width="auto">
                  <template #default>
                    <div v-for="d in scope.row.USING_DEPARTMNET_NAME" :key="d">
                      <div>{{ d }}</div>
                    </div>
                  </template>
                  <template #reference>
                    <el-button size="small" type="success" circle ><el-icon><Notification /></el-icon></el-button>
                  </template>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column
                prop="BUSINESS_NATURE_NAME"
                label="业务属性"
                show-overflow-tooltip
                align="center"
                min-width="60px"
            >
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                min-width="120px"
                align="center">
              <template #default="scope">
                <el-button class="table-button"
                           size="small"
                           type="primary"
                           @click="toEdit(scope.row)">编辑</el-button>
                <el-button class="table-button"
                           size="small"
                           type="danger"
                           v-if="this.userRole ==='0'"
                           @click="toDel(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
              background
              v-model:current-page="search.page"
              v-model:page-size="search.pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              @current-change="loadBusiness"
              @size-change="loadBusiness"
              :page-sizes="[50, 100, 200]"
              :total="total"
              style="margin-top: 10px;"
          >
          </el-pagination>
        </div>


        <el-dialog
            width="1000px"
            :title="businessTitle"
            v-model="dailVisi"
            :close-on-click-modal="false"
            :close-on-press-escape="false">
          <div style="height: calc(100% - 100px); overflow: auto;">
            <el-form
                :inline="true"
                :model="businessData"
                :rules="rules"
                ref="businessForm"
                label-width="130px"
            >
              <el-form-item label="使用部门:" prop="useDepartment" class="form-row">
                <el-select
                    v-model="businessData.useDepartment"
                    multiple
                    collapse-tags
                    collapse-tags-tooltip
                    :max-collapse-tags="2"
                    placeholder="请选择"
                    style="width: 100%"
                >
                  <el-option
                      v-for="item in usingDepartmentData"
                      :key="item.DEPARTMENT_ID"
                      :label="item.ORGAN_DEPARTMENT"
                      :value="item.DEPARTMENT_ID"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="业务类型:" prop="businessType" class="form-col">
                <el-select v-model="businessData.businessType" placeholder="请选择" style="width: 100%;" :disabled="disabled">
                  <el-option
                      v-for="item in businessTypeData"
                      :key="item.CODE"
                      :label="item.CODE_NAME"
                      :value="item.CODE">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="业务分类:" prop="businessClass" class="form-col">
                <el-select v-model="businessData.businessClass" placeholder="请选择" style="width: 100%;" :disabled="disabled">
                  <el-option
                      v-for="item in businessClassData"
                      :key="item.CODE"
                      :label="item.CODE_NAME"
                      :value="item.CODE">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="业务摘要:" prop="businessAbstract" class="form-col">
                <el-input v-model="businessData.businessAbstract" :disabled="disabled"/>
              </el-form-item>
              <el-form-item label="会计科目" prop="subjectSort" class="form-col">
                <el-cascader
                    placeholder="请选择科目"
                    style="width: 100%;"
                    v-model="businessData.subjectSort"
                    :props="props"
                    clearable
                    :options="subjectTree"
                    :disabled="disabled">
                </el-cascader>
              </el-form-item>
              <el-form-item label="科目明细:" prop="subjectDetail" class="form-col">
                <el-input v-model="businessData.subjectDetail" :disabled="disabled"/>
              </el-form-item>
              <el-form-item label="收支类别:" prop="revExpType" class="form-col">
                <el-cascader
                    placeholder="请选择科目"
                    style="width: 100%;"
                    v-model="businessData.revExpType"
                    :props="props"
                    clearable
                    :options="subjectTree"
                    :disabled="disabled">
                </el-cascader>
              </el-form-item>
              <el-form-item label="收支明细:" prop="revExpDetail" class="form-col">
                <el-input v-model="businessData.revExpDetail" :disabled="disabled"/>
              </el-form-item>
              <el-form-item label="税率:" prop="taxRate" class="form-col" >
                <el-input v-model="businessData.taxRate" :disabled="disabled">
                  <template #append>%</template>
                </el-input>
              </el-form-item>
              <el-form-item label="收入金额:" prop="revMoney" class="form-col">
                <el-input v-model="businessData.revMoney" :disabled="disabled"/>
              </el-form-item>
              <el-form-item label="支出金额:" prop="expMoney" class="form-col">
                <el-input v-model="businessData.expMoney" :disabled="disabled"/>
              </el-form-item>
              <el-form-item label="业务属性:" prop="businessNature" class="form-col">
                <el-radio-group v-model="businessData.businessNature" v-for="item in B_Nature" :key="item.CODE" :disabled="disabled">
                  <el-radio :label="item.CODE" border> {{ item.CODE_NAME }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
            <div class="div-button">
              <el-button type="primary" @click="toSave">保 存</el-button>
            </div>
          </div>
        </el-dialog>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Loading from '@/common/LoadingPage'
import apiURL from "@/components/apiUrl/api";
export default {
  name: "BusinessMge",
  components:{ Loading },
  data () {
    return {
      href: apiURL + "/api/business/downtowntemplate",
      uploadAction: apiURL +"/api/business/importtemplate",
      userRole: sessionStorage.getItem('organ-role').toString(),
      tableHeight: 0,
      businessTypeData:[],
      businessClassData:[],
      usingDepartmentData:[],
      search: {
        useDepartment:"",
        businessAbstract:"",
        businessType:"",
        subjectSort:"",
        subjectDetail:"",
        businessNature:"",
        page: 1,
        pagesize: 50,
      },

      businessTitle: "",
      dailVisi: false,
      businessList:[],
      total: 0,
      all_UseDepartment: [],
      test_UseDepartment: [],
      businessData: {
        businessId:"",
        useDepartment: [],
        businessAbstract:"",
        businessType: "",
        businessClass: "",
        subjectSort:"",
        subjectDetail:"",
        revExpType:"",
        revExpDetail:"",
        taxRate:"",
        revMoney:"",
        expMoney:"",
        businessNature:"1",
      },
      subjectTree:[],
      props:{
        label:'label',value:'id',children:'children',
        emitPath:false,
      },
      fileList:[],
      showProgress: false,
      progressPercent: 0,
      elProgress: false,
      progressInfo: {
        state: "1",
        text: "",
      },
      exportTip: "",
      disabled:false,
      B_Nature:"",
      rules: {
        useDepartment: [{required: true, message: "请选择使用部门", trigger: "blur"}],
        businessType: [{required: true, message: "请选择业务类型", trigger: "blur"}],
        businessAbstract: [{required: true, message: "请填写业务摘要", trigger: "blur"}],
        subjectSort: [{required: true, message: "请选择会计科目", trigger: "blur"}],
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.calcTableHeight()
      window.removeEventListener("resize", this.calcTableHeight)
      window.addEventListener("resize", this.calcTableHeight)
      this.loadCode()
      this.loadBusiness()
    })
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calcTableHeight)
  },
  methods: {
    calcTableHeight() {
      let mainHeight = window.getComputedStyle(this.$refs.mainSector.$el).height
      if (mainHeight.indexOf("px") !== -1)
        mainHeight = Number(mainHeight.substring(0, mainHeight.indexOf("px")))
      else
        mainHeight = Number(mainHeight)
      this.tableHeight = mainHeight - 120
    },
    loadCode() {
      this.$axios.post("/api/common/getcodelists", {codes: ["BUSINESS_TYPE", "BUSINESS_CLASS", "BUSINESS_NATURE"]})
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.businessTypeData = data.result.BUSINESS_TYPE
              this.businessClassData = data.result.BUSINESS_CLASS
              this.B_Nature = data.result.BUSINESS_NATURE
            } else {
              this.$alert(response.data.result, "获取代码列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取代码列表出错")
          })

      this.$axios.get("/api/common/getsubjecttree")
          .then((response) => {
            if (response.data.success) {
              this.subjectTree = response.data.result
            }
            else {
              this.$alert(response.data.result, "获取会计科目列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取会计科目列表出错")
          })

      this.$axios.get("/api/business/getUsingDepartmentData")
          .then((response) => {
            if (response.data.success) {
              this.usingDepartmentData = response.data.result
            }
            else {
              this.$alert(response.data.result, "获取部门列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取部门列表出错")
          })
    },

    toRefresh() {
      this.search.businessAbstract = ""
      this.search.businessType = ""
      this.search.subjectDetail = ""
      this.loadBusiness("refresh")
    },
    loadBusiness(d) {
      this.$axios.post("/api/business/loadbusinessinfo", this.search)
          .then((response) => {
            if (response.data.success) {
              this.total = response.data.result.totalrecords
              this.businessList = response.data.result.datalist
              this.loadIsFrequent()
              if(d === "refresh"){
                this.$message({message: "刷新成功", type: "success"})
              }
              if(d === "upload"){
                this.$message({message: "上传成功", type: "success"})
              }
            }
            else {
              this.$alert(response.data.result, "获取科目列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取科目列表出错")
          })
    },

    loadIsFrequent () {
      this.$axios.get("/api/business/loadisfrequent")
          .then((response) => {
            if (response.data.success) {
              let checkedKeys = []
              if(response.data.result != null && response.data.result.BUSINESS_ID_LIST !== "null" && response.data.result.BUSINESS_ID_LIST !== ""){
                checkedKeys = JSON.parse(response.data.result.BUSINESS_ID_LIST)
                checkedKeys.forEach(bat => {
                  this.businessList.forEach(w => {
                    if (w.BUSINESS_ID === bat)
                      this.$refs.businessRef.toggleRowSelection(w)
                  })
                })
              }
            }
            else {
              this.$alert(response.data.result, "获取科目使用状态出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取科目使用状态出错")
          })
    },
    toReset() {
      this.search.businessAbstract = ""
      this.search.businessType = ""
      this.search.subjectDetail = ""
      this.loadBusiness()
    },

    toAddBusiness () {
      this.businessTitle = "业务类型新增"
      this.dailVisi = true
      this.businessData.businessId = ""
      this.businessData.useDepartment = ""
      this.businessData.businessAbstract = ""
      this.businessData.businessType = ""
      this.businessData.businessClass = ""
      this.businessData.subjectSort = ""
      this.businessData.subjectDetail = ""
      this.businessData.revExpType = ""
      this.businessData.revExpDetail = ""
      this.businessData.taxRate = ""
      this.businessData.revMoney = ""
      this.businessData.expMoney = ""
      this.businessData.businessNature = "1"
      if (this.$refs.businessForm)
        this.$refs.businessForm.clearValidate()
    },
    toEdit (data){
      this.all_UseDepartment = data.USING_DEPARTMNET_ALL
      this.test_UseDepartment = data.USING_DEPARTMNET
      this.businessData.businessId = data.BUSINESS_ID
      this.businessData.useDepartment = data.USING_DEPARTMNET
      this.businessData.businessAbstract = data.BUSINESS_ABSTRACT
      this.businessData.businessType = data.BUSINESS_TYPE
      this.businessData.businessClass = data.BUSINESS_CLASS
      this.businessData.subjectSort = data.SUBJECT_SORT.toString()
      this.businessData.subjectDetail = data.SUBJECT_DETAIL
      this.businessData.revExpType = data.REV_EXP_TYPE
      this.businessData.revExpDetail = data.REV_EXP_DETAIL
      this.businessData.taxRate = data.TAX_RATE
      this.businessData.revMoney = data.REVENUE_MONEY
      this.businessData.expMoney = data.EXPENDITURE_MONEY
      this.businessData.businessNature = data.BUSINESS_NATURE
      this.businessTitle = "业务类型编辑"
      this.dailVisi = true
      if(this.userRole !=='0'){
        this.disabled = true
      }
      if (this.$refs.businessForm)
        this.$refs.businessForm.clearValidate()
    },

    toSave() {
      const all = this.all_UseDepartment;
      const test = this.test_UseDepartment;
      all.forEach(b => {
        if(!test.includes(b)){
          this.businessData.useDepartment.push(b)
        }
      })
      this.$refs["businessForm"].validate((valid) => {
        if (valid) {
          this.$axios.post("/api/business/savebusinessdata", this.businessData)
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "业务类型保存成功", type: "success"})
                  this.dailVisi = false
                  this.loadBusiness()
                } else {
                  this.$alert(response.data.result, "业务类型保存出错")
                }
              })
              .catch((error) => {
                this.$alert(error, "业务类型保存出错")
              })
        }
        else
          return false
      })
    },
    //删除
    toDel (data) {
      this.$confirm("确认删除此业务类型?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$axios.post("/api/business/delbusiness", {businessId: data.BUSINESS_ID})
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "业务类型删除成功", type: "success"})
                this.loadBusiness()
              }
              else {
                this.$alert(response.data.result, "业务类型删除出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "业务类型删除出错")
            })
      })
    },
    //上传前判断是否超过大小限制
    beforeUpload (file) {
      const isOut = file.size / 1024 / 1024 < 100
      if (!isOut) {
        this.$message.error("所选文件大小超过了100MB")
        return false
      }
      const suffix = file.name.indexOf(".") > -1 ? file.name.substring(file.name.lastIndexOf(".")).toLowerCase() : ""
      if (suffix !== ".xls" && suffix !== ".xlsx") {
        this.$message.error("只能上传Excel文件")
        return false
      }
      this.showProgress = true
      this.progressPercent = 0
      this.elProgress = true
      this.timer = setInterval(this.getProgress, 1500)
    },
    //显示上传进度
    uploadProgress (event) {
      console.log(event)
    },

    //上传成功
    uploadSuccess (res) {
      if (res.success) {
        this.progressPercent = 100
        this.fileList = []
        this.loadBusiness("upload")
      }else {
        if (typeof res.result === "string")
          this.$alert(res.result, "上传数据出错")
        else {
          var _this = this
          res.result.forEach( item =>{
            _this.$message({message: item, type: "error", showClose: true, duration:8000, position: "bottom-right"})
          })
        }
      }
      clearInterval(this.timer)
      this.elProgress = false
      this.showProgress = false
    },

    //定时获取进度
    getProgress () {
      this.$axios.get("/api/business/getimportprogress")
          .then((response) => {
            const data = response.data
            if (data.success) {
              if (data.result.state != null) {
                this.progressPercent = data.result.cur
                this.progressInfo.state = data.result.state
                this.progressInfo.text = data.result.state === "2" ? "导入完成" : data.result.msg
                if (data.result.state === "2") {
                  if (this.timer != null)
                    clearInterval(this.timer)
                  this.showProgress = false
                  this.progressPercent = 0
                  this.progressInfo.text = ""
                }
              }
            }
            else {
              this.$alert(data.result, "获取导入进度出错")
              if (this.timer != null)
                clearInterval(this.timer)
              this.showProgress = false
              this.progressInfo.state = ""
              this.progressInfo.text = ""
            }
          })
          .catch((error) => {
            this.$alert(error, "获取导入进度出错")
            this.showProgress = false
            this.progressInfo.state = ""
            this.progressInfo.text = ""
          })
    },

    //处理表格勾选
    handleSelectionChange (multi, row) {
      this.$refs.loading.show()
      multi.forEach(bat => {
        if(bat.BUSINESS_ID === row.BUSINESS_ID){
          row["use"] = true
        }else {
          row["use"] = false
        }
      })
      this.$axios.post("/api/business/switchwish", row)
          .then((response) => {
            if (response.data.success) {
              this.$refs.loading.hide()
              if(row["use"])
                this.$message({message: "设置常用成功", type: "success"})
              else
                this.$message({message: "设置不常用成功", type: "warning"})
            }else{
              this.$refs.loading.hide()
              this.$alert(response.data.result, "勾选常用出错")
              this.isLoading = false
            }
          })
          .catch((error) => {
            this.$refs.loading.hide()
            this.$alert(error, "勾选常用出错")
          })
    },
    //处理表格勾选
    handleSelectAll () {
      this.$refs.loading.show()
      this.$axios.post("/api/business/switchSelectAll", this.search)
          .then((response) => {
            if (response.data.success) {
              this.$refs.loading.hide()
              this.$message({message: "全部设置常用成功", type: "success"})
            }else{
              this.$refs.loading.hide()
              this.$alert(response.data.result, "勾选常用出错")
              this.isLoading = false
            }
          })
          .catch((error) => {
            this.$refs.loading.hide()
            this.$alert(error, "勾选常用出错")
          })
    },
    //导出
    doExport () {
      this.exportTip = "业务类型导出Excel中..."
      this.$axios.post("/api/business/exportbusinessdata", this.search, {responseType: "blob"})
          .then((response) => {
            this.exportTip = ""
            const err = response.headers["error-content"]
            if (typeof err === "undefined") {
              const blobUrl = window.URL.createObjectURL(new Blob([response.data]))
              let fname = response.headers["content-disposition"].split("filename=")[1]
              fname = decodeURIComponent(fname)
              const a = this.$refs.expLink
              a.href = blobUrl
              a.setAttribute("download", fname)
              a.click()
            }
            else
              this.$alert(decodeURIComponent(err), "期初数据导出出错")
          })
          .catch((error) => {
            this.exportTip = ""
            this.$alert(error, "期初数据导出出错")
          })
    },
  }
}
</script>

<style scoped>
.el-radio-group{
   margin-left: 10px;
}
</style>
